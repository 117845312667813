import React, { useCallback, useEffect, useState } from "react";
import * as poseDetection from "@tensorflow-models/pose-detection";

type UseDetectorResult = { waitForLoad: Promise<void> } & (
  | { detector: null; status: "loading"; error: null }
  | { detector: null; status: "error"; error: any }
  | { detector: poseDetection.PoseDetector; status: "loaded"; error: null }
)
type DetectorContextValue = { isDefaultValue: boolean; load: () => Promise<void> } & Omit<UseDetectorResult, "waitForLoad">;

const DetectorContext = React.createContext<DetectorContextValue>({
  detector: null,
  status: "error",
  error: null,
  isDefaultValue: true,
  load: async () => {
    throw new Error("useDetector() must be used within a <DetectorProvider>.");
  }
});

export const DetectorProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const [detector, setDetector] = useState<poseDetection.PoseDetector | null>(null);
  const [status, setStatus] = useState<"loading" | "error" | "loaded">("loading");
  const [error, setError] = useState<Error | null>(null);

  const load = useCallback(async () => {
    if (!detector) {
      try {
        setDetector(
          await poseDetection.createDetector(poseDetection.SupportedModels.MoveNet, {
            modelType: poseDetection.movenet.modelType.SINGLEPOSE_THUNDER
          })
        );
        setStatus("loaded");
      } catch (err: any) {
        setStatus("error");
        setError(err);
      }
    }
  }, [detector]);
  
  return (
    <DetectorContext.Provider value={{
      detector,
      status,
      error,
      load,
      isDefaultValue: false
    } as DetectorContextValue}>
      {props.children}
    </DetectorContext.Provider>
  );
};

export default function useDetector(): UseDetectorResult {
  const { isDefaultValue, detector, status, error, load } = React.useContext(DetectorContext);

  useEffect(() => {
    if (isDefaultValue) {
      throw new Error("useDetector() must be used within a <DetectorProvider>.");
    }
  }, [isDefaultValue]);

  useEffect(() => {
    if (status !== "loaded") {
      load();
    }
  }, [load, status]);

  return { detector, status, error } as UseDetectorResult;
}