import React, { useEffect, useImperativeHandle, useRef } from "react";

export interface IndeterminateCheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  indeterminate?: boolean;
}

export const IndeterminateCheckbox = React.forwardRef<HTMLInputElement | null, IndeterminateCheckboxProps>(
  (props, ref) => {
    const { indeterminate, ...rest } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate]);

    return <input {...rest} ref={inputRef} type="checkbox"/>;
  }
);

export default IndeterminateCheckbox;