import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import type { ObservableStatus } from "reactfire";
import type { WithID } from "../firebase";
import type { CourseData } from "../types/LessonData";
import type { RootState } from "./store";

export type CoursesState = {
  status: ObservableStatus<any>["status"];
  data: WithID<CourseData>[];
};

const initialState: CoursesState = {
  status: "loading",
  data: []
};

export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setCoursesStatus: (state, action: PayloadAction<CoursesState["status"]>) => {
      state.status = action.payload;
    },
    /** Replaces state.data entirely. */
    setCourses: (state, { payload }: PayloadAction<CoursesState["data"]>) => {
      state.data = payload;
    },
    /** Only replaces data from store where its key is in payload. */
    updateCourses: (state, { payload }: PayloadAction<CoursesState["data"]>) => {
      _.merge(state.data, payload);
    },
    reset: () => initialState
  }
});

export const { setCoursesStatus, setCourses, updateCourses } = coursesSlice.actions;

export const selectCourse = _.memoize((courseId: string) =>
  createSelector(
    [(state: RootState) => state.courses.data, () => courseId],
    (courses, courseId) => courses.find(course => course.id === courseId)
  ));

export const coursesReducer = coursesSlice.reducer;