import { useState } from "react";
import { useAppSelector } from "../../redux/hooks";

import type { PoseDetector } from "@tensorflow-models/pose-detection";

import VideoPoseDetection from "../../components/PoseDetection/VideoPoseDetection";

import { LessonData, LessonType } from "../../types/LessonData";
import styles from "./ReferenceView.module.scss";

interface ReferenceViewProps
  extends Pick<VideoPoseDetection["props"], "videoRef" | "onComponentReady" | "onVideoStaggered" | "onVideoEnded"> {
  detector: PoseDetector;
  lessonData: LessonData;
  lessonMedia: string;
  prevTimestampIdx: number;
}

export default function ReferenceView(props: ReferenceViewProps) {
  const userSettings = useAppSelector(state => state.user.settings);
  const [expanded, setExpanded] = useState(false);

  return props.lessonData.type === LessonType.IMAGE ? (
    <div>Not implemented</div>
  ) : (
    <div id={styles.main}>
      <div onClick={() => setExpanded(!expanded)} aria-expanded={expanded}>
        <div>
          <VideoPoseDetection
            detector={props.detector}
            data={{ ...props.lessonData, mediaSrc: props.lessonMedia }}
            fillType="width"
            videoRef={props.videoRef}
            flip={userSettings.handedness === "left"}
            onComponentReady={props.onComponentReady}
            onVideoStaggered={props.onVideoStaggered}
            onVideoEnded={props.onVideoEnded}
          />
        </div>
        {props.lessonData.type === LessonType.VIDEO_STAGGERED && (
          <div id={styles["indicator-container"]}>
            {props.lessonData.timestamps.map((_, idx) => (
              <div key={idx} className={styles.indicator} aria-checked={props.prevTimestampIdx >= idx}>
                {idx + 1}
              </div>
            ))}
            <div id={styles.line}/>
          </div>
        )}
      </div>
    </div>
  );
}