import { PickByType } from "./utils/utils";

declare global {
  interface Math {
    mapRange: (value: number, fromLow: number, fromHigh: number, toLow?: number, toHigh?: number, clamp?: boolean) => number;
    clamp: (value: number, min?: number, max?: number) => number;
    roundToInterval: (value: number, interval: number) => number;
  }

  interface Object {
    keysWithType: <T extends Record<string, unknown>>(obj: T) => Array<keyof T>
    entriesWithType: <T extends Record<string, unknown>>(obj: T) => Array<{ [K in keyof T]-?: [keyof PickByType<Required<T>, T[K]>, T[K]] }[keyof T]>
  }
}

Math.mapRange = (value, fromLow, fromHigh, toLow = 0, toHigh = 1, clamp = false): number => {
  const result = ((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow) + toLow;
  return clamp ? Math.clamp(result, toLow, toHigh) : result;
};

Math.clamp = (value, min = 0, max = 1) => {
  return Math.min(Math.max(value, min), max);
};

Math.roundToInterval = (value, interval) => {
  return Math.round(value / interval) * interval;
};

Object.keysWithType = Object.keys;
Object.entriesWithType = Object.entries;

export {};