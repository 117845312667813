import React, { useEffect, useRef, useState } from "react";
import styles from "../scss/CircularGuage.module.scss";
import Vector2 from "../utils/vector2";
import _ from "lodash";
import { lerpColor } from "../utils/utils";

interface CircularGuageProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "color"> {
  value: number;
  width: number | string;
  min?: number;
  max?: number;
  trackWidth?: number | string;
  /**
   * For array type, the color must be in hex format.
   */
  color?: string | { stop: number; color: string; }[]
}

export const CircularGuage: React.FC<CircularGuageProps> = props => {
  const { value, width, min, max, trackWidth, color, children, ...rest } = props;
  const [containerDimens, setContainerDimens] = useState(Vector2.zero);
  const [currentColor, setCurrentColor] = useState(Array.isArray(color) ? undefined : color);
  const containerRef = useRef<HTMLDivElement>(null);
  const resizeObserver = useRef(new ResizeObserver(entries => {
    for (const entry of entries) {
      if (entry.target === containerRef.current) {
        setContainerDimens(new Vector2(entry.contentRect.width, entry.contentRect.width / 2));
      }
    }
  }));

  useEffect(() => {
    const localRO = resizeObserver.current;
    localRO.observe(containerRef.current!);
    return () => localRO.disconnect();
  }, []);

  useEffect(() => {
    if (Array.isArray(color)) {
      switch (color.length) {
        case 0:
          setCurrentColor(undefined);
          return;

        case 1:
          setCurrentColor(color[0].color);
          return;

        default:
          const sortedColors = _.sortBy(color, c => c.stop);
          const fromColorIdx = sortedColors.filter(c => c.stop <= value).length - 1;

          if (fromColorIdx < 0) {
            setCurrentColor(color[0].color);
          } else if (fromColorIdx === sortedColors.length - 1) {
            setCurrentColor(color[fromColorIdx].color);
          } else {
            setCurrentColor(lerpColor(
              color[fromColorIdx].color, color[fromColorIdx + 1].color,
              Math.mapRange(value, min!, max!, 0, 1, true)
            ));
          }
      }
    }
  }, [color, value, min, max]);

  return (
    <div
      {...rest}
      ref={containerRef}
      className={[styles.main, rest.className].join(" ").trim()}
      style={{ ...rest.style, width: width, height: containerDimens.y }}
    >
      <div
        className={styles.info}
        style={
          typeof trackWidth === "number"
            ? { width: containerDimens.x - trackWidth * 2, height: containerDimens.y - trackWidth }
            : {
              width: `calc(${containerDimens.x}px - ${trackWidth} * 2)`,
              height: `calc(${containerDimens.y}px - ${trackWidth})`
            }
        }
      >
        {children ?? value}
      </div>
      <div
        className={styles.fill}
        style={{
          transform: `rotate(calc(180deg * ${Math.mapRange(value, min!, max!, 0, 1, true)}))`,
          background: currentColor
        }}
      />
    </div>
  );
};

CircularGuage.defaultProps = {
  min: 0,
  max: 1,
  width: 360,
  trackWidth: 60
};