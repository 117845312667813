import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useFirestore, useStorage } from "reactfire";
import { useMutation } from "react-query";
import { useImmer } from "use-immer";

import { collection, doc, getDoc } from "firebase/firestore";
import { getGenericConverter, WithID } from "../../firebase";
import { useAppSelector } from "../../redux/hooks";

import useDetector from "../../hooks/UseDetector";
import {
  EditableCourseData,
  EditableCourseDataWarning,
  EditableLessonData,
  EditableLessonDataWarning
} from "./EditableData";
import FileProcessing from "./FileProcessing";
import Hierarchy from "./Hierarchy";
import Editor from "./Editor";
import VideoPoseDetection from "../../components/PoseDetection/VideoPoseDetection";
import SelectionGrid from "../../components/SelectionGrid";
import Lesson from "../Lesson";
import ExistingLessonSelector from "./ExistingLessonSelector";
import Prompt from "../../hooks/UseFullscreenPrompt";

import _ from "lodash";
import pluralize from "pluralize";
import { LessonData, LessonMetadata, LessonType, VideoLessonData } from "../../types/LessonData";
import { uploadCourses } from "../../types/ApiMethods";
import styles from "../../scss/CreateLesson.module.scss";

enum PromptType {
  ADD_LESSON = 1,
  ADD_EXISTING_LESSON,
  DELETE_LESSON,
  ADD_TO_COURSE,
  DELETE_COURSE,
  UPLOAD
}

export enum EditorType {
  CREATE,
  EDIT
}

export interface LessonEditorProps {
  type: EditorType;
}

export default function LessonEditor(props: LessonEditorProps) {
  const db = useFirestore();
  const storage = useStorage();
  const detector = useDetector();
  const [searchParams, setSearchParams] = useSearchParams();
  const lessonMetadata = useAppSelector(state => state.lessons.data);

  const fixedDupedLessonInCourse = useRef(false);
  const [courses, setCourses] = useImmer<EditableCourseData[]>(() => {
    const c = new EditableCourseData(props.type === EditorType.CREATE ? "All lessons" : "Selected lessons");
    c.warnings = () => null;
    return [c];
  });
  const [lessons, setLessons] = useImmer(new Map<string, EditableLessonData>());
  const [selection, setSelection] = useImmer({ course: -1, lesson: Array<string>() });
  const baseSelectedLesson = useMemo(() => lessons.get(selection.lesson[0]), [lessons, selection.lesson]);
  const [warnings, setWarnings] = useState<{
    course: (EditableCourseDataWarning[] | null)[];
    lesson: { [key: string]: (EditableLessonDataWarning[] | null) };
  }>({ course: [null], lesson: {} });

  const previewVideoRef = React.createRef<HTMLVideoElement>();
  const [lessonTestingMode, setLessonTestingMode] = useState(false);
  const [testingLessonIdx, setTestingLessonIdx] = useState(0);

  const [promptType, setPromptType] = useState<PromptType | null>(null);
  const [lessonToBeDeleted, setLessonToBeDeleted] = useState(0);
  const [coursesToBeAddedTo, setCoursesToBeAddedTo] = useImmer(new Set<number>());
  const disabledCoursesToBeAddedTo = useMemo(() => {
    const set = new Set<number>();

    for (let i = 0; i < courses.length; i++) {
      if (i && selection.lesson.every(id => courses[i].lessonIds.includes(id))) {
        set.add(i - 1);
      }
    }

    return set;
  }, [courses, selection.lesson]);
  const [uploadProgress, setUploadProgress] = useState({ progress: 0, status: "" });
  const uploadAll = useMutation(
    ["upload", courses],
    () => uploadCourses(db, storage, _.tail(courses), [...lessons.values()], (progress, status) =>
      setUploadProgress({ progress, status })
    )
  );

  // Load lessons specified in search params on mount if editing
  useEffect(() => {
    if (props.type === EditorType.EDIT && !lessons.size) {
      const lessonIds = searchParams.getAll("ids");

      if (lessonIds.length) {
        addExistingLessonsHandler(lessonIds.map(id => lessonMetadata[id]));
        setSearchParams({}, { replace: true });
      } else {
        console.warn("No lesson ids found in search params");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Remove duplicated lessons once if editing
  useEffect(() => {
    if (props.type === EditorType.EDIT && !fixedDupedLessonInCourse.current) {
      const deduped = _.uniqBy(courses[0].lessons, "id");

      if (deduped.length !== courses[0].lessons.length) {
        setCourses(draft => {
          draft[0].lessons = deduped;
        });
        fixedDupedLessonInCourse.current = true;
      }
    }
  }, [props.type, setCourses, courses]);
  
  // Update warnings
  useEffect(() => {
    setWarnings({
      course: courses.map(c => c.warnings()),
      lesson: _.fromPairs([...lessons.entries()].map(([key, l]) => [key, l.warnings(_.tail(courses))]))
    });
  }, [courses, lessons]);

  // Reset states used by prompts
  useEffect(() => {
    if (promptType === PromptType.ADD_TO_COURSE) {
      const set = new Set<number>();

      if (courses.length === 1) {
        set.add(-1);
      }

      setCoursesToBeAddedTo(set);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptType]);

  const filesProcessedHandler = useCallback(async (lessons: EditableLessonData[]) => {
    await Promise.all(lessons.map(lesson => lesson.waitForSrc));
    setLessons(draft => {
      for (const lesson of lessons) {
        draft.set(lesson.id, lesson);
      }
    });
    setCourses(draft => {
      draft[0].lessonIds = draft[0].lessonIds.concat(lessons.map(l => l.id));
    });
    setPromptType(null);
  }, [setCourses, setLessons]);

  const addExistingLessonsHandler = useCallback(async (lessonMetadata: WithID<LessonMetadata>[]) => {
    const dataRef = collection(db, "lesson-data").withConverter(getGenericConverter<LessonData>());
    await filesProcessedHandler(
      (await Promise.all(lessonMetadata.map(metadata => Promise.all([getDoc(doc(dataRef, metadata.id)), metadata]))))
        .map(([dataDoc, metadata]) => {
          const l = new EditableLessonData(metadata, dataDoc.data()!, metadata.id, storage);

          if (props.type === EditorType.EDIT) {
            l.editEnabled = true;
          }

          return l;
        })
    );
  }, [db, filesProcessedHandler, props.type, storage]);

  const lessonReorderHandler = useCallback((course: number, fromIdx: number, toIdx: number) => 
    setCourses(draft => {
      const copy = _.clone(draft[course].lessonIds);
      copy.splice(toIdx, 0, copy.splice(fromIdx, 1)[0]);
      draft[course].lessonIds = copy;
    }), [setCourses]
  );

  const lessonDeleteHandler = useCallback((courseIdx: number, lessonIdx: number, prompted = false) => {
    if (!courseIdx) {
      if (!prompted) {
        setLessonToBeDeleted(lessonIdx);
        setPromptType(PromptType.DELETE_LESSON);
      } else {
        setSelection({ course: 0, lesson: [] });
        setCourses(draft => {
          const uid = draft[0].lessonIds[lessonIdx];

          for (let i = 0; i < draft.length; i++) {
            const idx = draft[i].lessonIds.indexOf(uid);

            if (idx !== -1) {
              const copy = _.clone(draft[i].lessonIds);
              copy.splice(idx, 1);
              draft[i].lessonIds = copy;
            }
          }

          setLessons(draft => {
            draft.delete(uid);
          });
        });
        setPromptType(null);
      }
    } else {
      setSelection(draft => {
        draft.course = 0;
      });
      setCourses(draft => {
        const copy = _.clone(draft[courseIdx].lessonIds);
        copy.splice(lessonIdx, 1);
        draft[courseIdx].lessonIds = copy;
      });
      setPromptType(null);
    }
  }, [setCourses, setLessons, setSelection]);

  const courseDeleteHandler = useCallback(() => {
    setCourses(draft => {
      draft.splice(selection.course, 1);
    });
    setSelection({ course: -1, lesson: [] });
    setPromptType(null);
  }, [selection.course, setCourses, setSelection]);

  const addLessonToCourseHandler = useCallback(() => {
    setCourses(draft => {
      for (const course of coursesToBeAddedTo) {
        if (course === -1) {
          const newCourse = new EditableCourseData("New course");
          newCourse.lessonIds = selection.lesson;
          draft.push(newCourse);
        } else {
          draft[course].lessonIds = _.uniq(draft[course].lessonIds.concat(selection.lesson));
        }
      }
    });
    setPromptType(null);
  }, [coursesToBeAddedTo, selection.lesson, setCourses]);

  return (
    <div id={styles.main}>
      {promptType && (
        <Prompt id={styles.prompt} onClickOutside={() => setPromptType(null)}>
          {promptType === PromptType.ADD_LESSON ? (
            <>
              <FileProcessing
                onFilesProcessed={filesProcessedHandler}
                onUseExistingLessons={() => setPromptType(PromptType.ADD_EXISTING_LESSON)}
              />
              <div>
                <button onClick={() => setPromptType(null)}>Cancel</button>
              </div>
            </>
          ) : promptType === PromptType.ADD_EXISTING_LESSON ? (
            <ExistingLessonSelector
              lessonsEditing={lessons}
              onSelected={addExistingLessonsHandler}
              onCancelButtonClick={() => setPromptType(null)}
            />
          ) : promptType === PromptType.DELETE_LESSON ? (
            <>
              <h2>Are you sure you want to delete this lesson?</h2>
              <p>This will delete all instances of this lesson from all courses that are being edited.</p>
              <div>
                <button onClick={() => lessonDeleteHandler(0, lessonToBeDeleted, true)}>Yes</button>
                <button onClick={() => setPromptType(null)}>No</button>
              </div>
            </>
          ) : promptType === PromptType.ADD_TO_COURSE ? (
            <>
              <h2>Add {pluralize("lesson", selection.lesson.length)} to:</h2>
              {courses.length > 1 && (
                <SelectionGrid
                  data={_.tail(courses)}
                  textField={"title"}
                  selection={coursesToBeAddedTo}
                  setSelection={setCoursesToBeAddedTo}
                  disabledSelection={disabledCoursesToBeAddedTo}
                />
              )}
              <div>
                <input
                  type="checkbox"
                  checked={coursesToBeAddedTo.has(-1)}
                  onChange={e =>
                    setCoursesToBeAddedTo(draft => {
                      e.target.checked ? draft.add(-1) : draft.delete(-1);
                    })
                  }
                />
                <label>Add to new course</label>
              </div>
              <div>
                <button onClick={addLessonToCourseHandler} disabled={!coursesToBeAddedTo.size}>
                  Confirm
                </button>
                <button onClick={() => setPromptType(null)}>Cancel</button>
              </div>
            </>
          ) : promptType === PromptType.DELETE_COURSE ? (
            <>
              <h2>Are you sure you want to delete this course?</h2>
              <div>
                <button onClick={courseDeleteHandler}>Yes</button>
                <button onClick={() => setPromptType(null)}>No</button>
              </div>
            </>
          ) : promptType === PromptType.UPLOAD ? (
            uploadAll.isIdle ? (
              <>
                <h2>
                  Are you sure you want to {props.type === EditorType.CREATE ? "upload" : "update"}{" "}
                  {pluralize("this", lessons.size)} {pluralize("lesson", lessons.size)}?
                </h2>
                <div className={styles.warning}>
                  {warnings.course.map(
                    (warning, idx) =>
                      warning && (
                        <div key={idx}>
                          <h3>{courses[idx].title.length ? courses[idx].title : `Untitled course ${idx + 1}`}:</h3>
                          <ul>
                            {warning.map((w, cwIdx) => (
                              <li key={`${idx}.${cwIdx}`}>{w}</li>
                            ))}
                          </ul>
                        </div>
                      )
                  )}
                  {Object.entries(warnings.lesson).map(([uid, warning], idx) => {
                    const lesson = lessons.get(uid);
                    return (
                      warning && (
                        <div key={uid}>
                          <h3>{lesson?.title.length ? lesson.title : `Untitled lesson ${idx + 1}`}:</h3>
                          <ul>
                            {warning.map((w, lwIdx) => (
                              <li key={`${uid}.${lwIdx}`}>{w}</li>
                            ))}
                          </ul>
                        </div>
                      )
                    );
                  })}
                </div>
                <div>
                  <button onClick={() => uploadAll.mutate()}>Yes</button>
                  <button onClick={() => setPromptType(null)}>No</button>
                </div>
              </>
            ) : uploadAll.isLoading ? (
              <p>{uploadProgress.status}</p>
            ) : (
              <>
                <p>{props.type === EditorType.CREATE ? "Upload" : "Update"} complete</p>
                <Link to="/home">
                  <button>Return</button>
                </Link>
              </>
            )
          ) : null}
        </Prompt>
      )}
      <Link to="/home">&lt; Back to home</Link>
      {!_.isEmpty(lessons) ? (
        lessonTestingMode ? (
          <>
            <button onClick={() => setLessonTestingMode(false)}>Stop testing</button>
            <Lesson
              className={styles.testing}
              courseData={courses[selection.course]}
              lessonData={EditableLessonData.dataConverter.toFirestore(baseSelectedLesson!)}
              lessonMetadata={EditableLessonData.metadataConverter.toFirestore(baseSelectedLesson!)}
              lessonMedia={baseSelectedLesson!.src!}
              lessonIdx={testingLessonIdx}
              onLessonIdxChange={idx => {
                setSelection({ ...selection, lesson: [courses[selection.course].lessons[idx].id] });
                setTestingLessonIdx(idx);
              }}
              testingMode
            />
          </>
        ) : (
          <>
            <Hierarchy
              type={props.type}
              courses={courses}
              lessons={lessons}
              selection={selection}
              onSelectionChange={selection => setSelection(selection)}
              onLessonAdd={() =>
                setPromptType(props.type === EditorType.CREATE ? PromptType.ADD_LESSON : PromptType.ADD_EXISTING_LESSON)
              }
              onLessonReorder={lessonReorderHandler}
              onLessonDelete={lessonDeleteHandler}
              onUpload={() => setPromptType(PromptType.UPLOAD)}
            />
            <Editor
              type={props.type}
              course={selection.course ? courses[selection.course] : undefined}
              lesson={selection.lesson.length > 1 ? selection.lesson.length : baseSelectedLesson}
              previewVideoRef={previewVideoRef}
              onCoursePropertyChange={(property, value) =>
                setCourses(draft => {
                  draft[selection.course][property] = value;
                })
              }
              onLessonPropertyChange={(property, value) =>
                setLessons(draft => {
                  if (typeof value === "object" && !Array.isArray(value)) {
                    Object.assign(draft.get(selection.lesson[0])![property]!, value);
                  } else {
                    // @ts-expect-error
                    draft.get(selection.lesson[0])![property]! = value;
                  }
                })
              }
              onTestButtonClick={() => {
                setLessonTestingMode(true);
                setTestingLessonIdx(courses[selection.course].lessonIds.indexOf(selection.lesson[0]));
              }}
              onAddToCourseButtonClick={() => setPromptType(PromptType.ADD_TO_COURSE)}
              onCourseDeleteButtonClick={() => setPromptType(PromptType.DELETE_COURSE)}
            />
            <section id={styles.preview}>
              {selection.lesson.length > 1 ? (
                <p>Cannot preview multiple lessons</p>
              ) : baseSelectedLesson ? (
                <VideoPoseDetection
                  detector={detector.detector!}
                  data={{
                    ...(EditableLessonData.dataConverter.toFirestore(baseSelectedLesson) as VideoLessonData),
                    type: LessonType.VIDEO,
                    mediaSrc: baseSelectedLesson.src!
                  }}
                  videoRef={previewVideoRef}
                  playOnStart={false}
                  videoControlsEnabled
                />
              ) : (
                <p>No lesson selected</p>
              )}
            </section>
          </>
        )
      ) : (
        <FileProcessing
          onFilesProcessed={filesProcessedHandler}
          onUseExistingLessons={() => setPromptType(PromptType.ADD_EXISTING_LESSON)}
        />
      )}
    </div>
  );
}
