import { useImmer } from "use-immer";
import { useMutation } from "react-query";

import { useAppSelector } from "../../redux/hooks";
import { selectLessonMetadataAsArray } from "../../redux/lessonsSlice";
import { WithID } from "../../firebase";

import type { LessonMetadata } from "../../types/LessonData";
import type { EditableLessonData } from "./EditableData";
import styles from "./ExistingLessonSelector.module.scss";

interface ExistingLessonSelectorProps {
  lessonsEditing: Map<string, EditableLessonData>;
  onSelected: (lessonMetadata: WithID<LessonMetadata>[]) => Promise<void>;
  onCancelButtonClick: () => void;
}

export default function ExistingLessonSelector(props: ExistingLessonSelectorProps) {
  const lessonsLookup = useAppSelector(state => state.lessons.data);
  const lessons = useAppSelector(selectLessonMetadataAsArray);
  const onSelected = useMutation(["on-selected", props.onSelected], props.onSelected);
  const [selectedLessons, setSelectedLessons] = useImmer(new Map<string, boolean>());

  return onSelected.status === "loading" ? (
    <>Loading...</>
  ) : (
    <>
      <div id={styles.main}>
        {lessons.length
          ? lessons.map(lesson => (
              <button
                key={lesson.id}
                className="no-style"
                onClick={() =>
                  setSelectedLessons(draft => {
                    draft.set(lesson.id, !draft.get(lesson.id));
                  })
                }
                disabled={props.lessonsEditing.has(lesson.id)}
              >
                <span>{lesson.title}</span>
                <input type="checkbox" checked={selectedLessons.get(lesson.id) ?? false} readOnly />
              </button>
            ))
          : "No lessons available."}
      </div>
      <div>
        <button
          onClick={() =>
            onSelected.mutate(Array.from(selectedLessons.keys()).map(id => ({ ...lessonsLookup[id], id })))
          }
        >
          Done
        </button>
        <button onClick={props.onCancelButtonClick}>Cancel</button>
      </div>
    </>
  );
}