import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "@react-forked/dnd";
import { useImmer } from "use-immer";
import { lessonTypeToString } from "../../types/LessonData";
import { EditableCourseData, EditableLessonData } from "./EditableData";
import styles from "./Hierarchy.module.scss";
import { EditorType } from ".";

interface HierarchyProps {
  type: EditorType;
  courses: EditableCourseData[];
  lessons: Map<string, EditableLessonData>;
  selection: { course: number; lesson: string[] };
  onSelectionChange: (selection: { course: number; lesson: string[] }) => void;
  onLessonAdd: () => void;
  onLessonReorder: (course: number, fromIdx: number, toIdx: number) => void;
  onLessonDelete: (courseIdx: number, lessonIdx: number) => void;
  onUpload: () => void;
}

export const Hierarchy: React.FC<HierarchyProps> = props => {
  const [expanded, setExpanded] = useImmer(Array<boolean>(props.courses.length).fill(false));
  const [hovered, setHovered] = useState<{ courseIdx: number; lessonIdx: number } | null>(null);

  useEffect(
    () =>
      setExpanded(draft => {
        draft[0] = true;
      }),
    [setExpanded]
  );

  useEffect(
    () =>
      setExpanded(draft => {
        const diff = props.courses.length - draft.length;

        if (diff > 0) {
          draft.push(...Array<boolean>(diff).fill(false));
        } else if (diff < 0) {
          draft.splice(draft.length - diff, diff);
        }
      }),
    [props.courses.length, setExpanded]
  );

  const onDragEndHandler = useCallback(
    (course: number, result: DropResult) => {
      if (result.destination) {
        props.onLessonReorder(course, result.source.index, result.destination.index);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onLessonReorder]
  );

  return (
    <section id={styles.main}>
      <div id={styles.hierarchy}>
        {props.courses.map((course, courseIdx) => (
          <>
            <button
              className="no-style"
              key={`${courseIdx}_btn`}
              onClick={() => {
                setExpanded(draft => {
                  draft[courseIdx] = !draft[courseIdx];
                });
                props.onSelectionChange({ course: courseIdx, lesson: [] });
              }}
              aria-expanded={expanded[courseIdx]}
            >
              {course.title.length ? course.title : `Untitled Course ${courseIdx + 1}`}
            </button>
            {expanded[courseIdx] && (
              <table key={`${courseIdx}_table`}>
                <DragDropContext onDragEnd={result => onDragEndHandler(courseIdx, result)}>
                  <Droppable droppableId={`${courseIdx}_table`}>
                    {provided => (
                      <tbody {...provided.droppableProps} ref={provided.innerRef}>
                        {course.lessonIds.map((id, lessonIdx, ids) => (
                          <Draggable key={`${courseIdx}_${id}`} draggableId={`${courseIdx}_${id}`} index={lessonIdx}>
                            {provided => {
                              const lesson = props.lessons.get(id);
                              return !!lesson && (
                                <tr
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  onClick={e => {
                                    if (props.selection.course === courseIdx) {
                                      if (e.ctrlKey) {
                                        props.onSelectionChange({
                                          course: courseIdx,
                                          lesson: [...props.selection.lesson, id]
                                        });
                                        return;
                                      } else if (e.shiftKey) {
                                        const pair = [ids.indexOf(_.last(props.selection.lesson)!), lessonIdx];
                                        props.onSelectionChange({
                                          course: courseIdx,
                                          lesson: _.uniq([
                                            ...props.selection.lesson,
                                            ...ids.slice(Math.min(...pair), Math.max(...pair) + 1)
                                          ])
                                        });
                                        return;
                                      }
                                    }

                                    props.onSelectionChange({ course: courseIdx, lesson: [id] });
                                  }}
                                  onMouseOver={() => setHovered({ courseIdx, lessonIdx })}
                                  onMouseOut={() => setHovered(null)}
                                  aria-current={props.selection.lesson.includes(id)}
                                >
                                  <td>{lesson.title}</td>
                                  <td>{lessonTypeToString(lesson.type)}</td>
                                  <td>
                                    <button
                                      className="no-style"
                                      style={{
                                        visibility:
                                          (hovered?.courseIdx === courseIdx && hovered?.lessonIdx === lessonIdx) ||
                                          props.selection.lesson.includes(id)
                                            ? "visible"
                                            : "hidden"
                                      }}
                                      onClick={e => {
                                        e.stopPropagation();
                                        props.onLessonDelete(courseIdx, lessonIdx);
                                      }}
                                    >
                                      ✖
                                    </button>
                                  </td>
                                </tr>
                              );
                            }}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
            )}
          </>
        ))}
      </div>
      <div>
        <button onClick={() => props.onLessonAdd()}>Add lessons</button>
        <button onClick={() => props.onUpload()}>{props.type === EditorType.CREATE ? "Upload all" : "Update all"}</button>
      </div>
    </section>
  );
};

export default Hierarchy;
