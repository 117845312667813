import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "../scss/UseFullscreenPrompt.module.scss";

interface FullscreenPrompt {
  enabled: boolean;
  enable: () => void;
  disable: () => void;
  setChildren: (children: React.ReactNode, config?: SetChildrenConfig) => void;
  setOnClickOutside: (fn: (() => void) | null) => void;
  isDefaultValue: boolean;
}

interface SetChildrenConfig {
  /** Props to be passed to the parent element that contains the children. */
  parentElementProps?: React.HTMLAttributes<HTMLDivElement>
}

/* eslint-disable @typescript-eslint/no-empty-function */
const FullscreenPromptContext = React.createContext<FullscreenPrompt>({
  enabled: false,
  enable: async () => {},
  disable: async () => {},
  setChildren: (
    children: React.ReactNode,
    config?: SetChildrenConfig
  ) => {},
  setOnClickOutside: () => {},
  isDefaultValue: true
});
/* eslint-enable @typescript-eslint/no-empty-function */

export const FullscreenPromptProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const [enabled, setEnabled] = useState(false);
  const [children, setChildren] = useState<React.ReactNode>(null);
  const [parentElementProps, setParentElementProps] = useState<React.HTMLAttributes<HTMLDivElement>>({});
  const [onClickOutside, setOnClickOutside] = useState<(() => void) | null>(null);

  const enable = useCallback(() => setEnabled(true), []);
  const disable = useCallback(() => setEnabled(false), []);
  const updateChildren = useCallback((children: React.ReactNode, config?: SetChildrenConfig) => {
    setChildren(children);
    setParentElementProps(config?.parentElementProps ?? {});
  }, []);
  // Because the setState hook also accepts a function, we cannot do a simple setOnClickOutside(fn)
  const updateOnClickOutside = useCallback((fn: (() => void) | null) => setOnClickOutside(() => fn), []);

  return (
    <FullscreenPromptContext.Provider
      value={{
        enabled,
        enable,
        disable,
        setChildren: updateChildren,
        setOnClickOutside: updateOnClickOutside,
        isDefaultValue: false
      }}
    >
      {props.children}
      {enabled ? (
        <div id={styles.main} onClick={() => onClickOutside?.()}>
          <div {...parentElementProps} onClick={e => e.stopPropagation()}>{children}</div>
        </div>
      ) : null}
    </FullscreenPromptContext.Provider>
  );
};

export function useFullscreenPrompt() {
  const context = useContext(FullscreenPromptContext);
  
  useEffect(() => {
    if (context.isDefaultValue) {
      throw new Error("useFullscreenPrompt must be used within a <FullscreenPromptProvider/>.");
    }
  }, [context.isDefaultValue]);

  return context;
}

export const Prompt: React.FC<{ onClickOutside?: () => void } & React.HTMLAttributes<HTMLDivElement>> = props => {
  const prompt = useFullscreenPrompt();
  const { children, onClickOutside, ...rest } = props;

  useEffect(() => {
    if (prompt.enabled) {
      throw new Error(
        "Cannot have multiple instances of <Prompt/>. Ensure that you only have one <Prompt/> in your application."
      );
    }

    prompt.enable();
    prompt.setChildren(children);

    if (onClickOutside) {
      prompt.setOnClickOutside?.(onClickOutside);
    }

    return () => {
      prompt.disable();
      prompt.setChildren(null);
      prompt.setOnClickOutside(null);
    };
  }, []);
  
  useEffect(() => {
    prompt.setChildren(children, { parentElementProps: rest });
  }, [props]);

  return null;
};

export default Prompt;