import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { enableMapSet } from "immer";

import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, FunctionsProvider, StorageProvider } from "reactfire";
import { FIREBASE_CONFIG } from "./firebase";

import "@tensorflow/tfjs-backend-webgl";

import App from "./App";
import { DetectorProvider } from "./hooks/UseDetector";
import { FullscreenPromptProvider } from "./hooks/UseFullscreenPrompt";
import { assertType } from "./utils/utils";
import "./ext.ts";
import "./index.scss";

const app = initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "asia-east2");

if (process.env.NODE_ENV === "development" && process.env.REACT_APP_USE_CLOUD !== "true") {
  console.log("Using emulators...");
  connectAuthEmulator(auth, `http://localhost:${process.env.REACT_APP_FIREBASE_EMULATOR_AUTH_PORT ?? 9099}`);
  connectFirestoreEmulator(db, "localhost", Number(process.env.REACT_APP_FIREBASE_EMULATOR_FIRESTORE_PORT ?? 8081));
  connectStorageEmulator(storage, "localhost", Number(process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_PORT ?? 9199));
  connectFunctionsEmulator(functions, "localhost", Number(process.env.REACT_APP_FIREBASE_EMULATOR_FUNCTIONS_PORT ?? 5001));
}

const queryClient = new QueryClient();

const mutationObserver = new MutationObserver(mutations => {
  for (const mutation of mutations) {
    if (mutation.type === "childList") {
      const iterateThroughNodes = (nodes: NodeList) => {
        for (const node of nodes) {
          if (assertType<HTMLElement>(node) && node.isContentEditable && !node.getAttribute("data-allow-newline")) {
            node.addEventListener("keydown", e => {
              if (e.key === "Enter") {
                e.preventDefault();
                (e.target! as HTMLElement).blur();
              }
            });
          }

          if (node.hasChildNodes()) {
            iterateThroughNodes(node.childNodes);
          }
        }
      };

      iterateThroughNodes(mutation.addedNodes);
    }
  }
});

mutationObserver.observe(document.getElementById("root")!, { childList: true, subtree: true });

enableMapSet();

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <FirebaseAppProvider firebaseApp={app}>
        <AuthProvider sdk={auth}>
          <FirestoreProvider sdk={db}>
            <StorageProvider sdk={storage}>
              <FunctionsProvider sdk={functions}>
                <BrowserRouter>
                  <QueryClientProvider client={queryClient}>
                    <DetectorProvider>
                      <FullscreenPromptProvider>
                        <App />
                      </FullscreenPromptProvider>
                    </DetectorProvider>
                  </QueryClientProvider>
                </BrowserRouter>
              </FunctionsProvider>
            </StorageProvider>
          </FirestoreProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </Provider>
  </React.StrictMode>
);
