import { doc, Firestore, getDoc, writeBatch } from "firebase/firestore";
import { deleteObject, FirebaseStorage, getBlob, ref, uploadBytes } from "firebase/storage";
import { getGenericConverter, WithID } from "../firebase";

import { store } from "../redux/store";

import axios from "axios";
import JSZip from "jszip";
import { v4 as uuid } from "uuid";
import _ from "lodash";

import { EditableCourseData, EditableLessonData } from "../pages/LessonEditor/EditableData";
import { CourseData, isCourseData, isLessonData, isLessonMetadata, LessonData, LessonMetadata } from "./LessonData";
import { Tuple } from "../utils/utils";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development" && process.env.REACT_APP_USE_CLOUD !== "true"
    ? `http://localhost:${
        process.env.REACT_APP_FIREBASE_EMULATOR_FUNCTIONS_PORT ?? 5001
      }/co-fencing-space/asia-east2/api`
    : "https://asia-east2-co-fencing-space.cloudfunctions.net/api";
axios.defaults.headers.get["Content-Type"] = axios.defaults.headers.post["Content-Type"]
  = "application/json; charset=utf-8";
axios.defaults.withCredentials = true;

export type ProgressHandler = (progress: number, status: string, done: boolean) => void;

export type WithToken<T> = T & { requesterIdToken: string };

export type CustomClaims = { admin: boolean }

export interface RegisterRequest {
  username: string;
  email: string;
  password: string;
}

export type GetUserRequest = WithToken<{
  targetUserEmail: string;
}>;

export type GetUserResponse = {
  displayName: string;
  customClaims?: CustomClaims;
};

export function getUser(data: GetUserRequest) {
  return axios.post<GetUserResponse>("/get-user", data);
}
export type GetAllUsersRequest = WithToken<{
  maxResults?: number;
  nextPageToken?: string;
}>;

export type GetAllUsersResponse = {
  users: (GetUserResponse & { uid: string; email: string; })[],
  nextPageToken?: string;
}

/**
 * Get all users. Maximum of 1000 users are returned.
 */
export function getAllUsers(data: GetAllUsersRequest) {
  return axios.post<GetAllUsersResponse>("/get-all-users", data);
}

export type SetUserClaimsRequest = WithToken<{
  targetUserUid: string;
  customClaims: Partial<CustomClaims>;
} | {
  targetUsers: {
    uid: string;
    customClaims: Partial<CustomClaims>;
  }[]
}>;

export async function setClaims(data: SetUserClaimsRequest) {
  return axios.post("/set-claims", data);
}

export async function uploadCourses(
  db: Firestore,
  storage: FirebaseStorage,
  courses: EditableCourseData[],
  lessons: EditableLessonData[],
  onProgress?: ProgressHandler
): Promise<void>
export async function uploadCourses(
  db: Firestore,
  storage: FirebaseStorage,
  courses: WithID<CourseData>[],
  lessons: Record<string, { metadata: LessonMetadata, data: LessonData, media: Blob }>,
  onProgress?: ProgressHandler
): Promise<void>
export async function uploadCourses(
  db: Firestore,
  storage: FirebaseStorage,
  courses: EditableCourseData[] | WithID<CourseData>[],
  lessons: EditableLessonData[] | Record<string, { metadata: LessonMetadata, data: LessonData, media: Blob }>,
  onProgress?: ProgressHandler
) {
  onProgress?.(0, "Writing to database", false);
  const batch = writeBatch(db);

  if (courses[0] instanceof EditableCourseData && Array.isArray(lessons)) {
    courses = courses as EditableCourseData[];
    lessons = lessons as EditableLessonData[];
    const newLessons = lessons.filter(lesson => lesson.editEnabled);
  
    for (const lesson of newLessons) {
      batch.set(doc(db, `lesson-metadata/${lesson.id}`).withConverter(EditableLessonData.metadataConverter), lesson);
      batch.set(doc(db, `lesson-data/${lesson.id}`).withConverter(EditableLessonData.dataConverter), lesson);
    }
  
    for (const course of courses) {
      batch.set(doc(db, `courses/${course.id}`).withConverter(EditableCourseData.converter), course);
    }
  
    await batch.commit();
  
    for (let i = 0; i < newLessons.length; i++) {
      const lesson = newLessons[i];
  
      if (lesson.existingLesson) {
        continue;
      }
  
      onProgress?.(i / newLessons.length, `Uploading lesson media for ${lesson.title}`, false);
      await uploadBytes(
        ref(storage, `lesson-media/${lesson.id}`),
        lesson.media
      );
    }
  } else {
    courses = courses as WithID<CourseData>[];
    lessons = lessons as Record<string, { metadata: LessonMetadata, data: LessonData, media: Blob }>;
    const lessonEntries = Object.entries(lessons);

    for (let i = 0; i < lessonEntries.length; i++) {
      const [lessonId, lesson] = lessonEntries[i];
      const newId = uuid();

      for (const course of courses) {
        const idxs = course.lessons.reduce(
          (acc, lesson, idx) => (lesson.id === lessonId ? acc.concat(idx) : acc),
          Array<number>()
        );

        if (idxs.length) {
          for (const idx of idxs) {
            course.lessons[idx].id = newId;
          }
        }
      }

      batch.set(doc(db, `lesson-metadata/${newId}`), { ...lesson.metadata, id: newId });
      batch.set(doc(db, `lesson-data/${newId}`), lesson.data);
      onProgress?.(i / lessonEntries.length, `Uploading lesson media for ${lesson.metadata.title}`, false);
      await uploadBytes(
        ref(storage, `lesson-media/${newId}`),
        lesson.media
      );
    }

    for (const course of courses) {
      const newId = uuid();
      batch.set(doc(db, `courses/${newId}`), Object.assign(course, { id: newId }));
    }

    onProgress?.(0.99, "Writing to database", false);
    await batch.commit();
  }
  
  onProgress?.(1, "Done", true);
}

export type DeleteCourseMediaRequest = WithToken<{
  courseId: string | string[];
}>;

export async function deleteLessons(
  db: Firestore,
  storage: FirebaseStorage,
  deletionList: { [courseIdx: number]: Array<number> | true },
  availableCourses: WithID<CourseData>[],
  deleteUnassigned = false
) {
  const availableCoursesCopy = _.cloneDeep(availableCourses);
  let deletedLessonUids = Array<string>();
  const batch = writeBatch(db);
  const deleteObjects = Array<Promise<void>>();

  for (const [courseIdx, lessonIdxs] of Object.entries(deletionList)
    .map(([k, v]) => [Number(k), v] as const)
    .sort((a, b) => b[0] - a[0])) {
    if (courseIdx === -1) {
      // Delete unassigned lessons
      for (const uid of (lessonIdxs === true
        ? availableCourses[courseIdx].lessons
        : availableCourses[courseIdx].lessons.filter((_, idx) => lessonIdxs.includes(idx))
      ).map(lesson => lesson.id)) {
        batch.delete(doc(db, `lesson-metadata/${uid}`));
        batch.delete(doc(db, `lesson-data/${uid}`));
        deleteObjects.push(deleteObject(ref(storage, `lesson-media/${uid}`)));
      }
    } else {
      const course = availableCourses[courseIdx];
  
      if (lessonIdxs === true) {
        availableCoursesCopy.splice(courseIdx, 1);
        deletedLessonUids = deletedLessonUids.concat(course.lessons.map(l => l.id));
        batch.delete(doc(db, `courses/${course.id}`));
      } else {
        availableCoursesCopy[courseIdx].lessons = course.lessons.filter((_, idx) => !lessonIdxs.includes(idx));
        deletedLessonUids = deletedLessonUids.concat(lessonIdxs.map(idx => course.lessons[idx].id));
        batch.update(doc(db, `courses/${course.id}`), { lessons: availableCoursesCopy[courseIdx].lessons });
      }
    }
  }

  if (deleteUnassigned) {
    deletedLessonUids = _.uniq(deletedLessonUids);

    for (const uid of deletedLessonUids) {
      if (
        !availableCoursesCopy.length ||
        !availableCoursesCopy.some(course => !!course.lessons.find(lesson => lesson.id === uid))
      ) {
        batch.delete(doc(db, `lesson-metadata/${uid}`));
        batch.delete(doc(db, `lesson-data/${uid}`));
        deleteObjects.push(deleteObject(ref(storage, `lesson-media/${uid}`)));
      }
    }
  }

  await batch.commit();
  await Promise.all(deleteObjects);
}

function isLessonArchive (
  obj: any
): obj is { [lessonId: string]: { metadata: LessonMetadata; data: LessonData } } {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const values = Object.values(obj);

  return (
    !!values.length &&
    values.every(
      (lesson: any) =>
        typeof lesson === "object" && lesson && isLessonMetadata(lesson.metadata) && isLessonData(lesson.data)
    )
  );
}

export async function exportCourse(db: Firestore, storage: FirebaseStorage, courseId: string) {
  const storeState = store.getState();
  const course = storeState.courses.data.find(course => course.id === courseId);

  if (!course) {
    throw new Error("Course not found");
  }

  const lessons = Object.fromEntries(await Promise.all(
    course.lessons.map(async ({ id }) => [
      id,
      {
        metadata: storeState.lessons.data[id],
        data: await getDoc(doc(db, `lesson-data/${id}`).withConverter(getGenericConverter<LessonData>())).then(
          doc => doc.data()!
        )
      }
    ] as const)
  ));

  const lessonVideos = await Promise.all(
    course.lessons.map(async ({ id }) => [id, await getBlob(ref(storage, `lesson-media/${id}`))] as const)
  );

  const zip = new JSZip()
    .file("course.json", JSON.stringify(course))
    .file("lessons.json", JSON.stringify(lessons));
  const mediaZip = zip.folder("media");
  lessonVideos.map(([id, video]) => mediaZip!.file(id, video));
  
  const link = document.createElement("a");
  link.href = URL.createObjectURL(await zip.generateAsync({ type: "blob" }));
  link.download = `${course.title}.zip`;
  link.click();
}

export async function importCourse(
  db: Firestore,
  storage: FirebaseStorage,
  zipFiles: FileList,
  onProgress?: ProgressHandler
) {
  const files = Array.from(zipFiles);
  onProgress?.(0, "Extracting files", false);

  for (const zipFile of files) {
    const zip = await JSZip.loadAsync(zipFile);
    const [course, lessons] = await Promise.all(
      ["course.json", "lessons.json"].map(async file => JSON.parse(await zip.file(file)!.async("string")))
    );
    const media = _.fromPairs(
      await Promise.all(
        Object.entries(zip.files)
          .filter(([name, file]) => !name.endsWith(".json") && !file.dir)
          .map(async ([name, file]) => Tuple([name.slice("media/".length), await file.async("blob")] as const))
      )
    );

    if (isCourseData(course) && isLessonArchive(lessons)) {
      await uploadCourses(
        db,
        storage,
        [course],
        Object.keys(lessons).reduce(
          (acc, id) => Object.assign(acc, { [id]: { ...lessons[id], media: media[id] } }),
          {} as { [lessonId: string]: { metadata: LessonMetadata; data: LessonData; media: Blob } }
        ),
        (progress, status) => onProgress?.(progress, status, false)
      );
    }
  }

  onProgress?.(1, "Done", true);
}
