import { useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet, Link } from "react-router-dom";
import { useImmer } from "use-immer";

import { useAuth, useFirestore, useSigninCheck } from "reactfire";
import { signOut } from "firebase/auth";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { getGenericConverter } from "./firebase";

import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { setUserData, setUserDataError, setUserSettings } from "./redux/userSlice";
import { setCourses, setCoursesStatus, updateCourses } from "./redux/coursesSlice";
import { setLessonsStatus, updateLessons } from "./redux/lessonsSlice";
import { resetStore } from "./redux/store";

import Prompt from "./hooks/UseFullscreenPrompt";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import LessonEditor, { EditorType } from "./pages/LessonEditor";
import Lesson from "./pages/Lesson";
import BouncyText from "./components/BouncyText";
import ButtonOptions from "./components/ButtonOptions";

import _ from "lodash";
import { Tuple } from "./utils/utils";
import type { CourseData, LessonMetadata } from "./types/LessonData";
import type { GroupData, UserData } from "./types/UserData";
import { AiOutlineSetting, AiOutlineLogout } from "react-icons/ai";
import styles from "./App.module.scss";

const HANDEDNESS_OPTIONS = Tuple([["Left", "left"], ["Right", "right"]] as const);

export default function App() {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const db = useFirestore();
  // @ts-expect-error
  const { status: signinCheckStatus, data: signinCheckResult } = useSigninCheck({ requiredClaims: { admin: true } });
  const userSettings = useAppSelector((state) => state.user.settings);
  const [groupData, setGroupData] = useImmer(new Map<string, GroupData>());
  const [groupDataStatus, setGroupDataStatus] = useState<"loading" | "success" | "error">("loading");
  const courses = useAppSelector(state => state.courses.data);
  const { status: userDataStatus, data: userData } = useAppSelector(state => state.user);

  const hideHeader = useAppSelector(state => state.app.hideHeader);
  const [settingsPromptEnabled, setSettingsPromptEnabled] = useState(false);
  const [availableVideoDevices, setAvailableVideoDevices] = useState(Array<MediaDeviceInfo>());

  // Gets user settings from local storage & gets available video devices
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      setAvailableVideoDevices(devices.filter(device => device.kind === "videoinput"));
    });

    const userSettings = localStorage.getItem("userSettings");

    if (userSettings) {
      dispatch(setUserSettings(JSON.parse(userSettings)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (signinCheckResult) {
      signinCheckResult.signedIn ? signinCheckResult.user.getIdToken(true) : resetStore();
    }
  }, [signinCheckResult]);

  // Sets userData
  useEffect(() => {
    if (signinCheckResult?.signedIn) {
      // reactfire only supports error boundaries and does not support disabling of queries, so this is a workaround
      const unsub = onSnapshot(
        doc(db, `users/${signinCheckResult.user.uid}`).withConverter(getGenericConverter<UserData>()),
        snapshot => {
          if (snapshot.exists()) {
            dispatch(setUserData(snapshot.data()));
          } else {
            dispatch(setUserDataError(new Error("User data not found")));
            console.error("User data not found");
          }
        },
        err => {
          dispatch(setUserDataError(err));
          console.error(err);
        }
      );
  
      return unsub;
    }
  }, [dispatch, db, signinCheckResult]);

  // Sets groupData
  useEffect(() => {
    if (userDataStatus === "success") {
      const promises = Array<Promise<void>>();

      const unsubs = _(userData.assignedGroups)
        .chunk(10)
        .map(ids => {
          let resolve!: () => void, reject!: () => void;
          const p = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
          });
          promises.push(p);

          return onSnapshot(
            query(collection(db, "groups").withConverter(getGenericConverter<GroupData>()), where("id", "in", ids)),
            snapshot => {
              resolve();
              setGroupData(draft => snapshot.forEach(doc => {
                draft.set(doc.id, doc.data());
              }));
            },
            err => {
              reject();
              console.error(err);
            }
          );
        })
        .value();

      Promise.all(promises).then(() => setGroupDataStatus("success")).catch(() => setGroupDataStatus("error"));

      return () => {
        for (const unsub of unsubs) {
          unsub();
        }
      };
    }
  }, [db, setGroupData, userData?.assignedGroups, userDataStatus]);

  // Sets courses (admin)
  useEffect(() => {
    if (signinCheckResult?.hasRequiredClaims) {
      const unsub = onSnapshot(
        collection(db, "courses").withConverter(getGenericConverter<CourseData>()),
        snapshot => {
          dispatch(setCourses(snapshot.docs.map(doc => doc.data())));
          dispatch(setCoursesStatus("success"));
        },
        err => {
          dispatch(setCoursesStatus("error"));
          console.error(err);
        }
      );

      return unsub;
    }
  }, [db, dispatch, signinCheckResult?.hasRequiredClaims]);

  // Sets courses (user)
  useEffect(() => {
    if (!signinCheckResult?.hasRequiredClaims && userDataStatus === "success" && groupDataStatus === "success") {
      const promises = Array<Promise<void>>();

      const unsubs = _([...groupData!.values()])
        .map(group => group.assignedCourses)
        .flatten()
        .uniq()
        .chunk(10)
        .map(ids => {
          let resolve!: () => void, reject!: (reason?: any) => void;
          const p = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
          });
          promises.push(p);

          return onSnapshot(
            query(collection(db, "courses").withConverter(getGenericConverter<CourseData>()), where("id", "in", ids)),
            snapshot => {
              resolve();
              dispatch(updateCourses(snapshot.docs.map(doc => doc.data())));
            },
            err => reject(err)
          );
        })
        .value();

      Promise.all(promises)
        .then(() => dispatch(setCoursesStatus("success")))
        .catch(err => {
          dispatch(setCoursesStatus("error"));
          console.log(err);
        });

      return () => {
        for (const unsub of unsubs) {
          unsub();
        }
      };
    }
  }, [db, dispatch, groupData, groupDataStatus, signinCheckResult?.hasRequiredClaims, userData, userDataStatus]);

  // Sets lessons (admin)
  useEffect(() => {
    if (signinCheckResult?.hasRequiredClaims) {
      const unsub = onSnapshot(
        collection(db, "lesson-metadata").withConverter(getGenericConverter<LessonMetadata>()),
        snapshot => {
          dispatch(
            updateLessons(
              snapshot.docs.map(doc => doc.data()).reduce((acc, lesson) => ({ ...acc, [lesson.id]: lesson }), {})
            )
          );
          dispatch(setLessonsStatus("success"));
        },
        err => {
          dispatch(setLessonsStatus("error"));
          console.error(err);
        }
      );

      return unsub;
    }
  }, [db, dispatch, signinCheckResult?.hasRequiredClaims]);

  // Sets lessons (user)
  useEffect(() => {
    if (!signinCheckResult?.hasRequiredClaims && courses.length) {
      const promises = Array<Promise<void>>();

      const unsubs = _(courses.map(course => course.lessons.map(lesson => lesson.id)).flat())
        .uniq()
        .chunk(10)
        .map(ids => {
          let resolve!: () => void, reject!: (reason?: any) => void;
          const p = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
          });
          promises.push(p);
          return onSnapshot(
            query(
              collection(db, "lesson-metadata").withConverter(getGenericConverter<LessonMetadata>()),
              where("id", "in", ids)
            ),
            snapshot => {
              resolve();
              dispatch(
                updateLessons(
                  snapshot.docs.map(doc => doc.data()).reduce((acc, lesson) => ({ ...acc, [lesson.id]: lesson }), {})
                )
              );
            },
            err => reject(err)
          );
        })
        .value();

      Promise.all(promises)
        .then(() => dispatch(setLessonsStatus("success")))
        .catch(err => {
          dispatch(setLessonsStatus("error"));
          console.error(err);
        });

      return () => {
        for (const unsub of unsubs) {
          unsub();
        }
      };
    }
  }, [courses, db, dispatch, signinCheckResult?.hasRequiredClaims]);

  return (
    <>
      <div id={styles.app} className={hideHeader ? styles["hide-header"] : undefined}>
        {settingsPromptEnabled && (
          <Prompt id={styles["settings-prompt"]} onClickOutside={() => setSettingsPromptEnabled(false)}>
            <div>
              <label>Video device:</label>
              <select onChange={e => dispatch(setUserSettings({ cameraDeviceId: e.target.value }))}>
                {availableVideoDevices.map(device => (
                  <option
                    key={device.deviceId}
                    value={device.deviceId}
                    selected={userSettings.cameraDeviceId === device.deviceId}
                  >
                    {device.label}
                  </option>
                ))}
              </select>
              <div className="subtext">A wide-angle camera is recommended.</div>
              <label>Handedness:</label>
              <ButtonOptions
                options={HANDEDNESS_OPTIONS}
                value={userSettings.handedness}
                onChange={value => dispatch(setUserSettings({ handedness: value }))}
                style={{ display: "inline-block" }}
              />
            </div>
            <button onClick={() => setSettingsPromptEnabled(false)}>Done</button>
          </Prompt>
        )}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <header>
                  <div id={styles.logo}>
                    <Link to="/home">
                      <img
                        src={`${window.location.protocol}//${window.location.host}/CoFencingSpaceLogo.png`}
                        alt="logo"
                      />
                    </Link>
                    <span>powered by MuseLabs</span>
                    <img
                      id={styles.muselab}
                      src={`${window.location.protocol}//${window.location.host}/MuseLabLogo.jpg`}
                      alt="logo"
                    />
                  </div>
                  {signinCheckStatus === "success" && signinCheckResult.signedIn && (
                    <div id={styles.options}>
                      Logged in as {signinCheckResult.user.displayName ?? signinCheckResult.user.email}
                      {signinCheckResult.hasRequiredClaims && (
                        <Link to="dashboard">
                          <button>Dashboard</button>
                        </Link>
                      )}
                      <button className="no-style" onClick={() => setSettingsPromptEnabled(true)}>
                        <AiOutlineSetting />
                      </button>
                      <button className="no-style" onClick={() => signOut(auth)}>
                        <AiOutlineLogout />
                      </button>
                    </div>
                  )}
                </header>
                <Outlet />
              </>
            }
          >
            {userDataStatus === "error" ? (
              <div className="error">Failed to get user data. Please contact an administrator.</div>
            ) : signinCheckStatus === "loading" ? (
              <Route path="*" element={<BouncyText>Loading...</BouncyText>} />
            ) : (
              <>
                <Route index element={<Navigate to="/login" />} />
                {signinCheckResult.signedIn ? (
                  <>
                    {["login", "register"].map(p => (
                      <Route key={p} path={p} element={<Navigate to="/home" />} />
                    ))}
                    <Route path="home" element={<Home />} />
                    <Route path="lesson/:courseId">
                      <Route index element={<Navigate to="0" />} />
                      <Route path=":lessonIdx" element={<Lesson />} />
                    </Route>
                    {signinCheckResult.hasRequiredClaims && (
                      <>
                        <Route path="editor">
                          <Route index element={<Navigate to="create" />} />
                          <Route path="create" element={<LessonEditor type={EditorType.CREATE} />} />
                          <Route path="edit" element={<LessonEditor type={EditorType.EDIT} />} />
                        </Route>
                        <Route path="dashboard" element={<Dashboard />} />
                      </>
                    )}
                    <Route path="*" element={<div>404</div>} />
                  </>
                ) : (
                  <>
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="*" element={<Navigate to="login" />} />
                  </>
                )}
              </>
            )}
          </Route>
        </Routes>
      </div>
    </>
  );
}
