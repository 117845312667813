import React from "react";
import { SupportedModels, Pose } from "@tensorflow-models/pose-detection";
import BasePoseDetection, { BasePoseDetectionProps } from "./BasePoseDetection";
import { LessonType, VideoLessonData, VideoStaggeredLessonData } from "../../types/LessonData";
import { flipPose, getPosesFromVideo } from "../../utils/PoseUtils";
import drawPose from "../../utils/CanvasDrawing";
import Vector2 from "../../utils/vector2";

interface AutoLoopVideoPoseDetectionProps {
  autoLoop: true;
  onVideoEnded?: () => Promise<void>;
}

interface NonAutoLoopVideoPoseDetectionProps {
  autoLoop: false;
  onVideoEnded?: (restart: () => void) => void;
}

interface BaseVideoPoseDetectionProps {
  data: (VideoLessonData | VideoStaggeredLessonData) & { mediaSrc: string };
  playOnStart: boolean;
  videoRef?: React.RefObject<HTMLVideoElement>;
  /**
   * When the promise is resolved, the video will continue automatically.
   * @param pose The pose that the video has stopped on.
   * @param idx The index of the pose among all of the stopping points that the video has stopped on.
   * */
  onVideoStaggered?: (pose: Pose, idx: number) => Promise<void>;
}

type VideoPoseDetectionProps =
  (AutoLoopVideoPoseDetectionProps | NonAutoLoopVideoPoseDetectionProps) & BaseVideoPoseDetectionProps;

interface VideoPoseDetectionState {
  // Mirror prop to state to control when this can be changed without causing problems.
  data: BaseVideoPoseDetectionProps["data"];
}

export default class VideoPoseDetection extends BasePoseDetection<
  VideoPoseDetectionProps, VideoPoseDetectionState, Pose[]
> {
  static defaultProps = {
    ...super.defaultProps,
    playOnStart: false,
    autoLoop: false,
  };

  currentTimestampIdx = 0;

  componentReady = false;

  constructor(props: BasePoseDetectionProps<Pose[]> & VideoPoseDetectionProps) {
    super(props);
    this.state = {
      ...this.state,
      data: props.data,
      videoRef: props.videoRef ?? React.createRef()
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.init();
  }

  componentDidUpdate(prevProps: VideoPoseDetectionProps) {
    if (prevProps.data.mediaSrc !== this.props.data.mediaSrc) {
      this.setState({ data: this.props.data }, () => this.init());
    }

    if (prevProps.videoRef !== this.props.videoRef) {
      this.setState({ videoRef: this.props.videoRef ?? React.createRef() });
    }
  }

  onDimensionChange() {
    if (this.state.data.poseData) {
      drawPose(
        this.ctx!,
        SupportedModels.MoveNet,
        this.getCurrentPose(false),
        { scale: new Vector2(this.state.canvasRef.current!.width, this.state.canvasRef.current!.height) }
      );
    }
  }

  init() {
    this.state.videoRef.current!.src = this.state.data.mediaSrc;

    const onReady = async () => {
      await this.props.onComponentReady?.();
      this.componentReady = true;

      this.state.videoRef.current!.onplay = () => {
        if (this.state.data.type === LessonType.VIDEO_STAGGERED) {
          this.timeUpdateUntilNextTimestamp(this.state.data.timestamps[this.currentTimestampIdx])
            .then(() => {
              this.state.videoRef.current!.pause();
              console.log(
                `Video has stopped on timestamp ${this.currentTimestampIdx} (${this.state.videoRef.current?.currentTime})`
              );
              this.props
                .onVideoStaggered?.(this.getCurrentPose(), this.currentTimestampIdx)
                .then(() => this.state.videoRef.current!.play());
              this.currentTimestampIdx++;
            });
        } else {
          this.timeUpdateUntilNextTimestamp();
        }
      };

      this.state.videoRef.current!.onseeked = () => {
        drawPose(
          this.ctx!,
          SupportedModels.MoveNet,
          this.getCurrentPose(false),
          { scale: new Vector2(this.state.canvasRef.current!.width, this.state.canvasRef.current!.height) }
        );
      };

      this.state.videoRef.current!.onended = async () => {
        console.log("Video ended");

        const restart = () => {
          this.state.videoRef.current!.currentTime = 0;
          this.state.videoRef.current!.play().catch(err => console.warn(err));
          this.currentTimestampIdx = 0;
        };

        if (this.props.autoLoop) {
          await this.props.onVideoEnded?.();
          restart();
        } else {
          this.props.onVideoEnded?.(restart);
        }
      };

      if (this.props.playOnStart) {
        this.state.videoRef.current!.play().catch(err => console.warn(err));
      }
    };

    if (!this.state.data.poseData) {
      this.state.videoRef.current!.onloadeddata = async () => {
        try {
          const poses = await getPosesFromVideo(this.state.videoRef.current!, this.props.detector);
          this.props.onPoseCallback?.(poses);
          this.setState({
            data: {
              ...this.state.data,
              poseData: {
                poses,
                frameRate: 10
              }
            }
          }, () => onReady());
        } catch (e) {
          console.error(e);
          this.props.onInitFailed?.(e);
        }
      };
    } else {
      onReady();
    }
  }

  getCurrentPose(flip?: boolean) {
    const pose = this.state.data.poseData!.poses[
      Math.floor(this.state.videoRef.current!.currentTime / (1 / this.state.data.poseData!.frameRate))
    ];

    return flip ?? this.props.flip ? flipPose(pose, 1) : pose;
  }

  timeUpdateUntilNextTimestamp = (timestamp?: number) => {
    const data = this.state.data;

    return new Promise<void>((res, rej) => {
      const loop = () => {
        if (data.mediaSrc !== this.state.data.mediaSrc) {
          rej("Video changed");
        } else if (timestamp !== undefined && this.state.videoRef.current!.currentTime >= timestamp) {
          drawPose(
            this.ctx!,
            SupportedModels.MoveNet,
            this.getCurrentPose(false),
            { scale: new Vector2(this.state.canvasRef.current!.width, this.state.canvasRef.current!.height) }
          );
          res();
        } else if (!this.state.videoRef.current!.paused) {
          drawPose(
            this.ctx!,
            SupportedModels.MoveNet,
            this.getCurrentPose(false),
            { scale: new Vector2(this.state.canvasRef.current!.width, this.state.canvasRef.current!.height) }
          );
          requestAnimationFrame(loop);
        }
      };

      loop();
    });
  };

  render() {
    const {
      detector, videoControlsEnabled, onComponentReady, onPoseCallback, onInitFailed, fillType,
      data: dataP, playOnStart, autoLoop, flip, onVideoEnded, onVideoStaggered, ...restP
    } = this.props;
    const { data: dataS, ...restS } = this.state;
    return <this.renderTarget {...restS} {...restP}/>;
  }
}
