import _ from "lodash";

export default class Vector2 {
  static zero = new Vector2(0, 0);
  static one = new Vector2(1, 1);
  x: number;
  y: number;

  private computeMagnitude = _.memoize(() => Math.sqrt(Vector2.dot(this, this)), () => this.toString());

  get magnitude() {
    return this.computeMagnitude();
  }

  get min() {
    return Math.min(this.x, this.y);
  }

  get max() {
    return Math.max(this.x, this.y);
  }

  static fromObject(obj: { x: number; y: number } | { width: number; height: number }) {
    if ("x" in obj) {
      return new Vector2(obj.x, obj.y);
    } else {
      return new Vector2(obj.width, obj.height);
    }
  }

  static fromObjectArray(arr: { x: number; y: number }[] | { width: number; height: number }[]) {
    return arr.map(val => Vector2.fromObject(val));
  }

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  static angle(v1: Vector2, v2: Vector2) {
    return Math.acos(Vector2.dot(v1, v2) / (v1.magnitude * v2.magnitude)) / Math.PI * 180;
  }

  static dot(v1: Vector2, v2: Vector2) {
    return v1.x * v2.x + v1.y * v2.y;
  }

  static add(...v: Vector2[]) {
    return v.reduce((acc, cur) => acc.add(cur), Vector2.zero);
  }

  static mul(...v: Vector2[]) {
    return v.reduce((acc, cur) => acc.mul(cur), Vector2.one);
  }

  toString() {
    return `(${this.x}, ${this.y})`;
  }

  asDimensions() {
    return { width: this.x, height: this.y };
  }

  asArray() {
    return [this.x, this.y];
  }

  add(v: Vector2) {
    return new Vector2(this.x + v.x, this.y + v.y);
  }

  sub(v: Vector2) {
    return new Vector2(this.x - v.x, this.y - v.y);
  }

  mul(v: Vector2 | number) {
    if (typeof v === "number") {
      return new Vector2(this.x * v, this.y * v);
    } else {
      return new Vector2(this.x * v.x, this.y * v.y);
    }
  }

  div(v: Vector2 | number) {
    if (typeof v === "number") {
      return new Vector2(this.x / v, this.y / v);
    } else {
      return new Vector2(this.x / v.x, this.y / v.y);
    }
  }
}

(window as any).Vector2 = Vector2;