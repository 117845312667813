import { useImmer } from "use-immer";
import SelectableTable from "../../components/SelectableTable";
import styles from "./Manage.module.scss";

export default function ManageLessons() {
    const tableData = [
        { id: "1", user: "Admin", lessons: "上步弓步", records_time: "1min 5s", scores: 90, create_time: "2023-09-22 10:02:01" },
        { id: "2", user: "Admin", lessons: "弓步接下蹲刺", records_time: "1min 10s", scores: 99, create_time: "2023-09-22 10:05:03" },
        { id: "3", user: "Admin", lessons: "後退兩步下蹲刺", records_time: "2min", scores: 95, create_time: "2023-09-22 10:08:30" },
        { id: "4", user: "Admin", lessons: "弓步", records_time: "30s", scores: 90, create_time: "2023-09-22 10:10:09" },
        { id: "5", user: "Admin", lessons: "出手刺", records_time: "40s", scores: 94, create_time: "2023-09-22 10:11:40" },
        { id: "6", user: "Admin", lessons: "假上前接後跳步", records_time: "1min 30s", scores: 91, create_time: "2023-09-22 10:13:36" },
        { id: "7", user: "Admin", lessons: "實戰姿勢", records_time: "1min 50s", scores: 90, create_time: "2023-09-22 10:16:22" },
        { id: "8", user: "Admin", lessons: "上前兩步後退兩步", records_time: "1min", scores: 98, create_time: "2023-09-22 10:18:55" }
    ];
    const [selection, setSelection] = useImmer(new Set<number>());

    return (
        <div id={styles.main}>
            <div>
                <SelectableTable
                    data={tableData}
                    columns={[
                        ["User", row => row.user],
                        ["Lessons", row => row.lessons],
                        ["Duration", row => row.records_time],
                        ["Scores", row => row.scores],
                        ["Date", row => row.create_time]
                    ]}
                    dataKeyField="id"
                    selection={selection}
                    setSelection={setSelection}
                />
            </div>
        </div>
    );
}
