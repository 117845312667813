import { MoveNetEstimationConfig, Pose, PoseDetector } from "@tensorflow-models/pose-detection";
import { QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { immerable } from "immer";
import { v4 as uuid } from "uuid";

import { getPosesFromVideo, PoseComparisonParams } from "../../utils/PoseUtils";
import { CourseData, LessonData, LessonMetadata, LessonType } from "../../types/LessonData";
import { FirebaseStorage, getDownloadURL, ref } from "firebase/storage";

export enum EditableLessonDataWarning {
  TITLE_UNCHANGED = "Title of this lesson has not been updated.",
  TITLE_EMPTY = "This lesson does not have a title.",
  DESCRIPTION_EMPTY = "This lesson does not have a description.",
  MEDIA_LARGE_SIZE = "The file size of this lesson is very large.",
  UNASSIGNED = "This lesson is not assigned to a course."
}

export enum EditableCourseDataWarning {
  TITLE_EMPTY = "This course does not have a title.",
  TITLE_UNCHANGED = "Title of this course has not been updated.",
  DESCRIPTION_EMPTY = "This course does not have a description."
}

export class EditableLessonData implements LessonMetadata {
  [immerable] = true;
  existingLesson = false;
  title: string;
  description = "";
  private _editEnabled = false;
  get editEnabled() {
    return this._editEnabled || !this.existingLesson;
  }
  set editEnabled(value: boolean) {
    this._editEnabled = value;
  }
  private _id: string = uuid();
  get id() {
    return this._id;
  }
  private _type: LessonType;
  get type() {
    return this._type;
  }
  set type(value: LessonType) {
    if (this.existingLesson) {
      throw new Error("Cannot edit existing lesson.");
    }

    if (value === LessonType.IMAGE && this.media.type.startsWith("video")) {
      throw new Error("Cannot set type to image when media is video.");
    }

    if ((value === LessonType.VIDEO || value === LessonType.VIDEO_STAGGERED) && this.media.type.startsWith("image")) {
      throw new Error("Cannot set type to video when media is image.");
    }

    this._type = value;
  }
  readonly media: File;
  private _scoreThreshold = 0.95;
  get scoreThreshold() {
    return this._scoreThreshold;
  }
  set scoreThreshold(value: number) {
    this._scoreThreshold = Math.clamp(value);
  }
  range = { min: 0, max: 1 };
  comparisonParams: PoseComparisonParams = {};
  private _poseData?: { poses: Pose[]; frameRate: number } | Pose;
  get poseData() {
    return this._poseData;
  }
  timestamps = Array<number>();
  /**
   * If this is not created from an existing lesson, this is the src derived from FileReader.readAsDataURL, and not a
   * URL.
   * */
  src: string | null = null;
  waitForSrc: Promise<void>;

  constructor(media: File, type?: LessonType);
  constructor(metadata: LessonMetadata, data: LessonData, id: string, storage: FirebaseStorage);
  constructor(arg0: File | LessonMetadata, arg1?: LessonType | LessonData, arg2?: string, arg3?: FirebaseStorage) {
    if (arg0 instanceof File && !(arg1 instanceof Object)) {
      const media = arg0;
      const type = arg1;
      if (type !== undefined) {
        if (
          (media.type.startsWith("video") && type === LessonType.IMAGE) ||
          (media.type.startsWith("image") && type !== LessonType.IMAGE)
        ) {
          throw new Error(`Media type ${media.type} does not match lesson type ${type}`);
        } else {
          this._type = type;
        }
      } else {
        this._type = media.type.startsWith("video") ? LessonType.VIDEO : LessonType.IMAGE;
      }

      this.waitForSrc = new Promise(res => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
          this.src = fileReader.result as string;
          res();
        };

        fileReader.readAsDataURL(media);
      });

      this.media = media;
      this.title = media.name;
    } else if (!(arg0 instanceof File) && arg1 instanceof Object && arg2 && arg3) {
      const metadata = arg0;
      const data = arg1;
      const id = arg2;
      const storage = arg3;
      this.existingLesson = true;
      this.title = metadata.title;
      this.description = metadata.description;
      this._id = id;
      this._type = data.type;
      this.scoreThreshold = data.scoreThreshold;
      this._poseData = data.type === LessonType.IMAGE ? data.pose : data.poseData;
      this.comparisonParams = data.comparisonParams ?? {};
      this.range = data.range ?? this.range;
      this.timestamps = data.type === LessonType.VIDEO_STAGGERED ? data.timestamps : this.timestamps;
      this.media = new File([], "null", { type: data.type === LessonType.IMAGE ? "image/png" : "video/mp4" });
      this.waitForSrc = new Promise(async res => {
        this.src = await getDownloadURL(ref(storage, `lesson-media/${id}`));
        res();
      });
    } else {
      throw new Error("Invalid arguments");
    }
  }

  static metadataConverter = {
    toFirestore: (lesson: EditableLessonData): LessonMetadata => ({
      id: lesson.id,
      title: lesson.title,
      description: lesson.description
    }),
    fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
      throw new Error("Not implemented.");
    }
  };

  static dataConverter = {
    toFirestore: (lesson: EditableLessonData): LessonData => {
      if (!lesson.poseData) {
        throw new Error("Pose data has not been generated.");
      }

      const baseData = {
        scoreThreshold: lesson.scoreThreshold,
        comparisonParams: lesson.comparisonParams,
        range: lesson.range
      };

      if (lesson.type === LessonType.IMAGE && "keypoints" in lesson.poseData) {
        return {
          ...baseData,
          type: lesson.type,
          pose: lesson.poseData
        };
      }

      if ("poses" in lesson.poseData) {
        if (lesson.type === LessonType.VIDEO) {
          return {
            ...baseData,
            type: lesson.type,
            poseData: lesson.poseData
          };
        }

        if (lesson.type === LessonType.VIDEO_STAGGERED) {
          if (!lesson.timestamps.length) {
            console.warn("Timestamps have not been generated.");
          }

          return {
            ...baseData,
            type: lesson.type,
            poseData: lesson.poseData,
            timestamps: lesson.timestamps
          };
        }
      }

      throw new Error("Mismatched lesson type and pose data.");
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
      throw new Error("Not implemented.");
    }
  };

  warnings(courses?: EditableCourseData[]) {
    const warnings = Array<EditableLessonDataWarning>();

    if (courses && courses.every(course => !course.lessonIds.includes(this.id))) {
      warnings.push(EditableLessonDataWarning.UNASSIGNED);
    }

    if (this.title === this.media.name) {
      warnings.push(EditableLessonDataWarning.TITLE_UNCHANGED);
    }

    if (this.title.length === 0) {
      warnings.push(EditableLessonDataWarning.TITLE_EMPTY);
    }

    if (this.description.length === 0) {
      warnings.push(EditableLessonDataWarning.DESCRIPTION_EMPTY);
    }

    if (this.media.size > 8000000) {
      warnings.push(EditableLessonDataWarning.MEDIA_LARGE_SIZE);
    }

    return warnings.length ? warnings : null;
  }

  /** Cannot be used when type is `LessonType.Image`. */
  async generatePoseData(
    video: HTMLVideoElement,
    detector: PoseDetector,
    frameRate = 10,
    detectionConfig?: MoveNetEstimationConfig,
    onProgress?: (progress: number, done: boolean) => void
  ) {
    await this.waitForSrc;

    if (!this.src) {
      throw new Error("src has not been set.");
    } else {
      let resVideoLoaded: ((value: void) => void) | null = null;
      let rejVideoLoaded: ((reason?: any) => void) | null = null;
      video.onloadeddata = () => resVideoLoaded!();
      video.onerror = () => rejVideoLoaded!(new Error("Error loading video."));
      video.src = this.src;
      await new Promise((res, rej) => {
        resVideoLoaded = res;
        rejVideoLoaded = rej;
      });

      this._poseData = {
        poses: await getPosesFromVideo(video, detector, frameRate, detectionConfig, onProgress),
        frameRate
      };

      video.onloadeddata = null;
      video.onerror = null;
    }
  }
}

export class EditableCourseData implements Omit<CourseData, "lessons"> {
  [immerable] = true;
  title: string;
  description: string;
  private _id = uuid();
  get id() {
    return this._id;
  }
  lessons = Array<{ id: string, quantity: number }>();
  get lessonIds() {
    return this.lessons.map(lesson => lesson.id);
  }
  set lessonIds(ids: string[]) {
    this.lessons = ids.map(id => ({ id, quantity: this.lessons.find(lesson => lesson.id === id)?.quantity ?? 1 }));
  }

  constructor(title?: string, description?: string, id?: string) {
    this.title = title || "";
    this.description = description || "";
  }

  static converter = {
    toFirestore(data: EditableCourseData): CourseData {
      return {
        id: data.id,
        title: data.title,
        description: data.description,
        lessons: data.lessons
      };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): EditableCourseData {
      const data = snapshot.data(options);

      if (typeof data.description === "string" && typeof data.title === "string") {
        return new EditableCourseData(data.title, data.description, snapshot.id);
      } else {
        throw new Error("Invalid data:", data);
      }
    }
  };

  warnings() {
    const warnings = Array<EditableCourseDataWarning>();

    if (this.title === "") {
      warnings.push(EditableCourseDataWarning.TITLE_EMPTY);
    }

    if (this.title === "New course") {
      warnings.push(EditableCourseDataWarning.TITLE_UNCHANGED);
    }

    if (this.description === "") {
      warnings.push(EditableCourseDataWarning.DESCRIPTION_EMPTY);
    }

    return warnings.length ? warnings : null;
  }
}