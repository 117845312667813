import { useCallback } from "react";

interface EditableNumericSpanProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, "onChange"> {
  value: number;
  onManualChange: (value: number) => void;
  toFixed?: number;
  disabled?: boolean;
}
export default function EditableNumericSpan(props: EditableNumericSpanProps) {
  const { value: pValue, onManualChange: onChange, toFixed, ...rest } = props;
  const getDisplayValue = useCallback(
    () => (props.toFixed ? props.value.toFixed(props.toFixed) : props.value.toString()),
    [props.toFixed, props.value]
  );

  return (
    <span
      {...rest}
      onFocus={e => {
        if (props.disabled) {
          e.target.blur();
        } else {
          props.onFocus?.(e);
        }
      }}
      onBlur={e => {
        const newValue = Number(e.target.innerHTML);
        e.target.innerHTML = getDisplayValue();

        if (!isNaN(newValue)) {
          props.onManualChange(newValue);
        }
      }}
      contentEditable
      suppressContentEditableWarning
    >
      {getDisplayValue()}
    </span>
  );
}