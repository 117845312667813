import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useImmer } from "use-immer";

import { arrayRemove, arrayUnion, collection, doc, writeBatch } from "firebase/firestore";
import { useFirestore, useUser } from "reactfire";
import { CustomClaims, GetAllUsersResponse, setClaims } from "../../types/ApiMethods";
import type { UserData } from "../../types/UserData";

import SearchWithFilter, { SearchFilters } from "../../components/SearchWithFilter";
import SelectableTable from "../../components/SelectableTable";
import AssignmentGrid from "../../components/AssignmentGrid";
import Prompt from "../../hooks/UseFullscreenPrompt";
import type { DataProps } from ".";

import _ from "lodash";
import { Booleanish, removeEmptyObjects } from "../../utils/utils";
import styles from "./Manage.module.scss";

export default function ManageUsers(props: DataProps) {
  const { data: currentUser } = useUser();
  const db = useFirestore();
  const queryClient = useQueryClient();
  const userDataCollectionRef = useRef(collection(db, "users"));

  const searchFilters = useMemo(
    () =>
      new SearchFilters<typeof tableData>()
        .add(["email", "email address"], (user, query) => user.email.includes(query))
        .add(
          ["in", "group"],
          (user, option) => !!user.assignedGroups?.includes(option),
          [...props.groupData.values()].map(group => group.name)
        )
        .add(["is", "role"], (user, option) => user.customClaims?.[option] === true, ["admin"] as const),
    [props.groupData]
  );
  const tableData = useMemo(
    () =>
      [...props.userRecords.values()].map(user => ({
        ...user,
        assignedGroups: props.userData.get(user.uid)?.assignedGroups.map(group => props.groupData.get(group)!.name)
      })),
    [props.groupData, props.userData, props.userRecords]
  );
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [selection, setSelection] = useImmer(new Set<number>());
  const [pendingChanges, setPendingChanges] = useImmer<{
    [uid: string]: {
      claims?: Partial<CustomClaims>,
      data?: Partial<UserData>
    };
  }>({});

  const [groupAssignmentPromptEnabled, setGroupAssignmentPromptEnabled] = useState(false);
  const [changedGroupAssignments, setChangedGroupAssignments] = useImmer(new Map<string, boolean>());
  

  const applyGroupAssignment = useMutation(
    ["apply-group-assignment"],
    async () => {
      const batch = writeBatch(db);
      const dict = _.groupBy([...changedGroupAssignments.entries()], ([, value]) => value);
      const groupsToAssign = dict["true"]?.map(([id]) => id);
      const groupsToUnassign = dict["false"]?.map(([id]) => id);

      selection.forEach(idx => {
        if (groupsToAssign) {
          batch.update(doc(userDataCollectionRef.current, filteredTableData[idx].uid), {
            assignedGroups: arrayUnion(...groupsToAssign)
          });
        }

        if (groupsToUnassign) {
          batch.update(doc(userDataCollectionRef.current, filteredTableData[idx].uid), {
            assignedGroups: arrayRemove(...groupsToUnassign)
          });
        }
      });

      await batch.commit();
    },
    {
      onSuccess() {
        setGroupAssignmentPromptEnabled(false);
      }
    }
  );

  const setUserClaims = useMutation(
    ["set-claims", pendingChanges, setPendingChanges],
    async () =>
      setClaims({
        requesterIdToken: await currentUser!.getIdToken(),
        targetUsers: Object.entries(pendingChanges).reduce(
          (acc, [uid, change]) => (change.claims ? acc.concat({ uid, customClaims: change.claims }) : acc),
          Array<{ uid: string; customClaims: Partial<CustomClaims> }>()
        )
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries("user-records");
        setPendingChanges({});
      }
    }
  );

  const selectedUsers = useMemo(
    () => [...selection.values()].map(idx => props.userData.get(filteredTableData[idx].uid)!),
    [filteredTableData, props.userData, selection]
  );

  const groupDataArr = useMemo(
    () =>
      [...props.groupData.entries()]
        .map(([id, group]) => ({ ...group, id }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [props.groupData]
  );

  const originalGroupAssignments = useMemo(() => {
    const map = new Map<string, Booleanish>();
    props.groupData.forEach((_, id) => {
      const usersInGroup = selectedUsers.filter(user => user.assignedGroups.includes(id));
      map.set(id, !usersInGroup.length ? false : usersInGroup.length === selectedUsers.length ? true : "?");
    });
    return map;
  }, [props.groupData, selectedUsers]);

  const onAdminToggleHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, user: GetAllUsersResponse["users"][number]) => {
      setUserClaims.reset();

      setPendingChanges(draft => {
        if (e.target.checked === !!user.customClaims?.admin) {
          delete draft[user.uid].claims?.admin;

          if (draft[user.uid].claims) {
            draft[user.uid].claims = removeEmptyObjects(draft[user.uid].claims!);

            if (_.isEmpty(draft[user.uid].claims)) {
              delete draft[user.uid].claims;
            }

            if (_.isEmpty(draft[user.uid])) {
              delete draft[user.uid];
            }
          }
        } else {
          _.set(draft, `${user.uid}.claims.admin`, e.target.checked);
        }
      });
    },
    [setPendingChanges, setUserClaims]
  );

  useEffect(() => {
    if (!groupAssignmentPromptEnabled) {
      setChangedGroupAssignments(new Map());
    }
  }, [setChangedGroupAssignments, groupAssignmentPromptEnabled]);

  return (
    <div id={styles.main}>
      {groupAssignmentPromptEnabled && (
        <Prompt onClickOutside={() => setGroupAssignmentPromptEnabled(false)}>
          <AssignmentGrid
            className={styles.assignment}
            data={groupDataArr}
            keyField="id"
            textField="name"
            originalAssignment={originalGroupAssignments}
            newAssignment={changedGroupAssignments}
            setNewAssignment={setChangedGroupAssignments}
          />
          {applyGroupAssignment.isError && (
            <div className="error">
              {applyGroupAssignment.error instanceof Error ? applyGroupAssignment.error.message : "An error occurred."}
            </div>
          )}
          <div>
            <button onClick={() => setGroupAssignmentPromptEnabled(false)}>Cancel</button>
            <button onClick={() => applyGroupAssignment.mutate()}>Done</button>
          </div>
        </Prompt>
      )}
      <div id={styles.search}>
        <SearchWithFilter
          data={tableData}
          textSearchKey="displayName"
          filters={searchFilters}
          onDataChange={data => setFilteredTableData(data)}
        />
        {!!selection.size && (
          <button onClick={() => setGroupAssignmentPromptEnabled(true)}>Edit group assignment</button>
        )}
        {!_.isEmpty(pendingChanges) && <button onClick={() => setUserClaims.mutate()}>Apply changes</button>}
        {setUserClaims.status === "success" && <span className="success">Changes applied!</span>}
        {setUserClaims.status === "loading" && <span>Updating records...</span>}
        {setUserClaims.status === "error" && <span className="error">And error occurred</span>}
      </div>
      <div>
        <SelectableTable
          data={filteredTableData}
          columns={[
            ["Display name", row => row.displayName],
            ["Email", row => row.email],
            [
              "Group",
              row =>
                row.assignedGroups
                  ? row.assignedGroups.length
                    ? row.assignedGroups.join(", ")
                    : "Unassigned"
                  : "Error"
            ],
            [
              "Admin",
              row => (
                <input
                  type="checkbox"
                  title={row.uid === currentUser?.uid ? "You cannot change your own admin status." : undefined}
                  onClick={e => e.stopPropagation()}
                  checked={pendingChanges[row.uid]?.claims?.admin ?? !!row.customClaims?.admin}
                  onChange={e => onAdminToggleHandler(e, row)}
                  disabled={row.uid === currentUser?.uid}
                />
              ),
              row => ({
                style: {
                  textAlign: "center",
                  background: pendingChanges[row.uid]?.claims?.admin !== undefined ? "#fff6a9" : undefined
                }
              })
            ]
          ]}
          dataKeyField="uid"
          selection={selection}
          setSelection={setSelection}
        />
      </div>
    </div>
  );
}