import React from "react";
import Draggable, { DraggableData } from "react-draggable";
import styles from "../scss/MinMaxRangeInput.module.scss";

type Range = { min: number; max: number; };

interface MinMaxRangeInputProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
  range: Range;
  step: number;
  minSeparation: number;
  values?: Range;
  defaultValues?: Range;
  onChange?: (values: Range) => void;
}

interface MinMaxRangeInputState {
  trackRef: React.RefObject<HTMLDivElement>;
  trackRect: DOMRect;
  thumbRef: React.RefObject<HTMLDivElement>;
  thumbWidth: number;
  values: Range;
}

export default class MinMaxRangeInput extends React.Component<MinMaxRangeInputProps, MinMaxRangeInputState> {
  static defaultProps = {
    range: {
      min: 0,
      max: 1,
    },
    step: 0.01,
    minSeparation: 0.01
  };

  resizeObserver: ResizeObserver;

  trackRect!: DOMRect;

  constructor(props: MinMaxRangeInputProps) {
    super(props);
    this.state = {
      trackRef: React.createRef(),
      trackRect: new DOMRect(),
      thumbRef: React.createRef(),
      thumbWidth: 0,
      values: props.defaultValues ?? props.values ?? props.range,
    };

    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === this.state.trackRef.current) {
          this.setState({ trackRect: entry.target.getBoundingClientRect() });
        }

        if (entry.target === this.state.thumbRef.current) {
          this.setState({ thumbWidth: entry.contentRect.width });
        }
      }
    });
  }

  componentDidMount() {
    this.resizeObserver.observe(this.state.trackRef.current!);
    this.resizeObserver.observe(this.state.thumbRef.current!);
  }

  componentDidUpdate(_: MinMaxRangeInputProps, prevState: MinMaxRangeInputState) {
    if (prevState.values !== this.state.values) {
      this.props.onChange?.(this.state.values);
    }
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  valueToPixel(value: number) {
    return Math.mapRange(
      value,
      this.props.range.min, this.props.range.max,
      -this.state.thumbWidth, this.state.trackRect.width - this.state.thumbWidth
    );
  }

  onClickHandler: React.MouseEventHandler = e => {
    const clickPosPercentage = Math.mapRange(e.clientX, this.state.trackRect.left, this.state.trackRect.right);
    const boundToBeChanged =
      Math.abs(Math.mapRange(this.state.values.min, this.props.range.min, this.props.range.max) - clickPosPercentage) <
      Math.abs(Math.mapRange(this.state.values.max, this.props.range.min, this.props.range.max) - clickPosPercentage)
        ? "min" : "max";

    this.setState({
      values: {
        ...this.state.values,
        [boundToBeChanged]: Math.roundToInterval(Math.mapRange(
          clickPosPercentage, 0, 1, this.props.range.min, this.props.range.max, true
        ), this.props.step)
      }
    });
  };

  onDragHandler(controlTarget: keyof Range, data: DraggableData) {
    this.setState({
      values: {
        ...this.state.values,
        [controlTarget]: Math.roundToInterval(Math.mapRange(
          data.x,
          -this.state.thumbWidth, this.state.trackRect.width - this.state.thumbWidth,
          this.props.range.min, this.props.range.max
        ), this.props.step)
      }
    });
  }

  render() {
    const { values, range, step, minSeparation, onChange, ...rest } = this.props;
    return (
      <div
        {...rest}
        className={rest.className ? [styles.main, rest.className].join(" ") : styles.main}
        onClick={this.onClickHandler.bind(this)}
      >
        {["min", "max"].map(c => {
          const controlTarget = c as keyof Range;
          return (
            <Draggable
              key={controlTarget}
              axis="x"
              bounds={controlTarget === "min" ? {
                left: -this.state.thumbWidth,
                right: this.valueToPixel((values ?? this.state.values).max - minSeparation)
              } : {
                left: this.valueToPixel((values ?? this.state.values).min + minSeparation),
                right: this.state.trackRect.width - this.state.thumbWidth
              }}
              positionOffset={{ x: "100%", y: 0 }}
              onDrag={(e, data) => {
                e.stopPropagation();
                e.preventDefault();
                this.onDragHandler(controlTarget, data);
              }}
              position={{
                x: this.valueToPixel((values ?? this.state.values)[controlTarget]),
                y: 0
              }}
            >
              <div ref={controlTarget === "min" ? this.state.thumbRef : undefined} className={styles.thumb}/>
            </Draggable>
          );
        })}
        <div className={styles.track} ref={this.state.trackRef}>
          <div
            className={styles.empty}
            style={{ width: `${this.valueToPixel((values ?? this.state.values).min) + this.state.thumbWidth}px` }}
          />
          <div className={styles.filled}/>
          <div
            className={styles.empty}
            style={{
              width: `${this.valueToPixel(range.max - (values ?? this.state.values).max) + this.state.thumbWidth}px`
            }}
          />
        </div>
      </div>
    );
  }
}