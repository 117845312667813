import React from "react";
import { delay } from "../utils/utils";
import styles from "../scss/BouncyText.module.scss";

interface BouncyTextProps extends React.HTMLAttributes<HTMLDivElement> {
  children: string;
  amount: string;
  duration: number;
  delay: number;
}

export default class BouncyText extends React.Component<BouncyTextProps> {
  static defaultProps = {
    amount: "0.5em",
    duration: 500,
    delay: 250
  };

  spanRefs: React.RefObject<HTMLSpanElement>[];

  willUnmount = false;

  constructor(props: BouncyTextProps) {
    super(props);
    this.spanRefs = Array.from({ length: props.children.length }, () => React.createRef<HTMLSpanElement>());
  }

  componentDidMount() {
    this.animationLoop();
  }

  componentDidUpdate(prevProps: BouncyTextProps) {
    if (prevProps.children.length !== this.props.children.length) {
      if (this.spanRefs.length < this.props.children.length) {
        this.spanRefs.concat(Array.from(
          { length: this.props.children.length - this.spanRefs.length },
          () => React.createRef<HTMLSpanElement>()
        ));
      } else {
        this.spanRefs.splice(this.props.children.length);
      }
    }
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  async animationLoop() {
    try {
      for (const spanRef of this.spanRefs) {
        spanRef.current!.animate([
          { transform: "translateY(0)", easing: "ease-out" },
          { transform: `translateY(-${this.props.amount})`, offset: 0.2 },
          { transform: "translateY(0)" }
        ], { duration: this.props.duration });
        await delay(this.props.delay);
      }
    } catch {}

    if (!this.willUnmount) {
      await delay(1000);
      requestAnimationFrame(() => this.animationLoop());
    }
  }

  render() {
    const { children, amount, duration, delay, ...rest } = this.props;
    return (
      <div className={rest.className ? `${styles["bouncy-text"]} ${rest.className}` : styles["bouncy-text"]} {...rest}>
        {[...children].map((letter, idx) => <span key={idx} ref={this.spanRefs[idx]}>{letter}</span>)}
      </div>
    );
  }
}