import { Pose } from "@tensorflow-models/pose-detection";
import type { WithID } from "../firebase";
import type { PoseComparisonParams } from "../utils/PoseUtils";

export enum LessonType {
  IMAGE,
  VIDEO,
  VIDEO_STAGGERED
}

export function lessonTypeToString(lessonType: LessonType.IMAGE): "Image";
export function lessonTypeToString(lessonType: LessonType.VIDEO): "Video";
export function lessonTypeToString(lessonType: LessonType.VIDEO_STAGGERED): "Video Staggered";
export function lessonTypeToString(lessonType: LessonType): "Image" | "Video" | "Video Staggered";
export function lessonTypeToString(lessonType: LessonType) {
  switch (lessonType) {
    case LessonType.IMAGE:
      return "Image";
    case LessonType.VIDEO:
      return "Video";
    case LessonType.VIDEO_STAGGERED:
      return "Video Staggered";
  }
}

export interface BaseMetadata {
  title: string;
  description: string;
}

export type LessonMetadata = WithID<BaseMetadata>

export interface BaseLessonData {
  scoreThreshold: number;
  comparisonParams?: PoseComparisonParams;
  range?: { min: number; max: number };
}

export interface ImageLessonData extends BaseLessonData {
  type: LessonType.IMAGE;
  pose?: Pose;
}

export interface VideoLessonData extends BaseLessonData {
  type: LessonType.VIDEO;
  poseData: {
    poses: Pose[];
    frameRate: number;
  };
}

export interface VideoStaggeredLessonData extends BaseLessonData {
  type: LessonType.VIDEO_STAGGERED;
  poseData: {
    poses: Pose[];
    frameRate: number;
  };
  timestamps: number[];
}

export type LessonData = ImageLessonData | VideoLessonData | VideoStaggeredLessonData;

export interface CourseData extends WithID<BaseMetadata> { lessons: { id: string, quantity: number }[] }

export function isCourseData(data: any): data is CourseData {
  return (
    typeof data === "object" &&
    data !== null &&
    [data.title, data.description, data.id].every(value => typeof value === "string") &&
    Array.isArray(data.lessons) &&
    !!data.lessons.length &&
    (data.lessons.every(
      (lesson: any) => typeof lesson.id === "string" && typeof lesson.quantity === "number"
    ) as boolean)
  );
}

export function isLessonMetadata(data: any): data is LessonMetadata {
  return (
    typeof data === "object" &&
    data !== null &&
    [data.id, data.title, data.description].every((value: any) => typeof value === "string")
  );
}

export function isLessonData(data: any): data is LessonData {
  return (
    typeof data === "object" &&
    data !== null &&
    typeof data.scoreThreshold === "number" &&
    (data.comparisonParams === undefined || typeof data.comparisonParams === "object") &&
    (data.range === undefined ||
      (typeof data.range === "object" && typeof data.range.min === "number" && typeof data.range.max === "number")) &&
    (data.type === LessonType.IMAGE
      ? data.pose === undefined || typeof data.pose === "object"
      : data.type === LessonType.VIDEO || data.type === LessonType.VIDEO_STAGGERED
      ? typeof data.poseData === "object" &&
        typeof data.poseData.frameRate === "number" &&
        Array.isArray(data.poseData.poses) &&
        data.poseData.poses.every((pose: any) => typeof pose === "object") &&
        (data.type === LessonType.VIDEO_STAGGERED
          ? Array.isArray(data.timestamps) && data.timestamps.every((timestamp: any) => typeof timestamp === "number")
          : true)
      : false)
  );
}
