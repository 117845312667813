import { useCallback, useEffect, useState } from "react";
import type { Updater } from "use-immer";

import IndeterminateCheckbox from "./IndeterminateCheckbox";

import _ from "lodash";
import classNames from "classnames";
import type { Selector } from "@reduxjs/toolkit";
import type { KeyofType } from "../utils/utils";
import styles from "../scss/SelectableTable.module.scss";

export interface SelectableTableProps<T extends Record<string, any>> {
  data: T[];
  columns: (readonly [
    string,
    Selector<T, React.ReactNode, never>,
    (React.TdHTMLAttributes<HTMLTableCellElement> | Selector<T, React.TdHTMLAttributes<HTMLTableCellElement>, never>)?
  ])[];
  dataKeyField: KeyofType<T, string>;
  selection: Set<number>;
  setSelection?: Updater<Set<number>>;
  hideSelect?: boolean;
}

export default function SelectableTable<T extends Record<string, any>>(props: SelectableTableProps<T>) {
  const { selection, setSelection } = props;
  const [lastClickedIdx, setLastClickedIdx] = useState(Number.NaN);

  useEffect(() => setSelection?.(draft => draft.clear()), [props.data, setSelection]);

  const onSelectAllToggleHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) =>
    setSelection?.(draft => {
      for (const i of _.range(0, props.data.length)) {
        e.target.checked ? draft.add(i) : draft.delete(i);
      }
    }), [props.data.length, setSelection]
  );

  const onRowSelectHandler = useCallback((e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, idx: number) =>
    setSelection?.(draft => {
      if (e.shiftKey && !isNaN(lastClickedIdx) && idx !== lastClickedIdx) {
        e.preventDefault();
        const [fromIdx, toIdx] = [lastClickedIdx, idx].sort();

        for (const i of _.range(fromIdx, toIdx + 1)) {
          selection.has(lastClickedIdx) ? draft.add(i) : draft.delete(i);
        }
      } else {
        selection.has(idx) ? draft.delete(idx) : draft.add(idx);
      }

      setLastClickedIdx(idx);
    }), [lastClickedIdx, selection, setSelection]
  );

  return (
    <table className={classNames(styles.main, props.hideSelect && styles["select-disabled"])}>
      <thead>
        <tr>
          {!props.hideSelect && (
            <th>
              <IndeterminateCheckbox
                onChange={onSelectAllToggleHandler}
                checked={!!selection.size && props.data.length === selection.size}
                indeterminate={!!selection.size && props.data.length !== selection.size}
              />
            </th>
          )}
          {props.columns.map(([key]) => (
            <th key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.data.map((row, idx) => (
          <tr key={row[props.dataKeyField]} onClick={e => onRowSelectHandler(e, idx)}>
            {!props.hideSelect && (
              <td>
                <input type="checkbox" checked={selection.has(idx)} readOnly />
              </td>
            )}
            {props.columns.map(([key, selector, cellProps]) => {
              cellProps = typeof cellProps === "function" ? cellProps(row) : cellProps;
              const node = selector(row);
              return (
                <td
                  {...cellProps}
                  key={`${row[props.dataKeyField]}-${key}`}
                  style={{
                    ...cellProps?.style,
                    textAlign: cellProps?.style?.textAlign ?? (!isNaN(Number(node)) ? "center" : undefined)
                  }}
                >
                  {node}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}