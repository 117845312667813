import { connect } from "react-redux";
import { setUserSettings } from "../../redux/userSlice";
import type { RootState } from "../../redux/store";
import type { useAppDispatch } from "../../redux/hooks";

import BasePoseDetection from "./BasePoseDetection";
import { flipPose } from "../../utils/PoseUtils";
import type { Pose } from "@tensorflow-models/pose-detection";

interface WebcamPoseDetectionProps {
  dispatch: ReturnType<typeof useAppDispatch>;
  enabled: boolean;
  deviceId?: string;
}

export class WebcamPoseDetection extends BasePoseDetection<WebcamPoseDetectionProps, {}, Pose> {
  static defaultProps = {
    ...super.defaultProps,
    flip: true,
    enabled: true
  };

  componentDidMount() {
    super.componentDidMount();
    navigator.mediaDevices
      .getUserMedia({ video: this.props.deviceId ? { deviceId: this.props.deviceId } : { facingMode: "user" } })
      .then(stream => {
        try {
          if (!this.props.deviceId) {
            this.props.dispatch(setUserSettings({ cameraDeviceId: stream.getVideoTracks()[0].getSettings().deviceId }));
          }
        } catch (e) {
          console.error(e);
        }

        this.state.videoRef.current!.srcObject = stream;
        this.state.videoRef.current!.play().then(() => {
          this.props.onComponentReady?.();
          // Warm up the detector in case of cold start
          this.detectAndDrawPose().then(() =>
            this.ctx?.clearRect(0, 0, this.state.canvasRef.current!.width, this.state.canvasRef.current!.height)
          );
          this.detectPoseLoop();
        });
      })
      .catch(e => this.props.onInitFailed?.(e));
  }

  componentDidUpdate(prevProps: WebcamPoseDetectionProps) {
    if (prevProps.enabled !== this.props.enabled) {
      if (this.props.enabled && this.state.videoRef.current!.readyState === 4) {
        this.detectPoseLoop();
      } else {
        this.ctx!.clearRect(0, 0, this.state.canvasRef.current!.width, this.state.canvasRef.current!.height);
      }
    }
  }

  componentWillUnmount() {
    if (this.state.videoRef.current?.srcObject instanceof MediaStream) {
      this.state.videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
  }

  async detectPoseLoop() {
    if (this.props.enabled) {
      const pose = await this.detectAndDrawPose();
  
      if (pose) {
        this.props.onPoseCallback?.(this.props.flip ? flipPose(pose, 1) : pose);
      }

      if (!this.willUnmount) {
        requestAnimationFrame(() => this.detectPoseLoop());
      }
    }
  }

  render() {
    const {
      detector, videoControlsEnabled, onComponentReady, onPoseCallback, onInitFailed,
      dispatch, flip, enabled, deviceId, ...rest
    } = this.props;
    return (
      <this.renderTarget
        {...this.state}
        {...rest}
      />
    );
  }
}

function mapStateToProps(state: RootState) {
  return { deviceId: state.user.settings.cameraDeviceId };
}

export default connect(mapStateToProps)(WebcamPoseDetection);