import React, { useCallback, useState } from "react";
import { immerable } from "immer";

import MinMaxRangeInput from "../../components/MinMaxRangeInput";
import EditableNumericSpan from "../../components/EditableNumericSpan";
import ButtonOptions from "../../components/ButtonOptions";
import Prompt from "../../hooks/UseFullscreenPrompt";
import { EditableCourseData, EditableLessonData } from "./EditableData";

import _ from "lodash";
import { Tuple } from "../../utils/utils";
import { MOVENET_NAMED_KP_PAIRS } from "../../utils/PoseUtils";
import { EditorType } from ".";
import { LessonType, lessonTypeToString } from "../../types/LessonData";
import styles from "./Editor.module.scss";

type EditableCourseDataKeys = Exclude<keyof EditableCourseData, typeof immerable>

type EditableLessonDataKeys = Exclude<keyof EditableLessonData, typeof immerable>;

const VIDEO_LESSON_OPTIONS = Tuple([
  [lessonTypeToString(LessonType.VIDEO), LessonType.VIDEO],
  [lessonTypeToString(LessonType.VIDEO_STAGGERED), LessonType.VIDEO_STAGGERED]
] as const);

interface EditorProps {
  type: EditorType;
  course?: EditableCourseData;
  lesson?: EditableLessonData | number;
  previewVideoRef: React.RefObject<HTMLVideoElement>;
  onCoursePropertyChange: <T extends EditableCourseDataKeys>(property: T, value: EditableCourseData[T]) => void;
  onLessonPropertyChange: <T extends EditableLessonDataKeys>(
    property: T,
    value: Partial<EditableLessonData[T]>
  ) => void;
  onTestButtonClick: () => void;
  onAddToCourseButtonClick: () => void;
  onCourseDeleteButtonClick: () => void;
}

export const Editor: React.FC<EditorProps> = props => {
  const [editExistingLessonPromptEnabled, setEditExistingLessonPromptEnabled] = useState(false);
  const lessonPropertyChangeHandler: EditorProps["onLessonPropertyChange"] = useCallback((property, value) => {
    if (props.lesson && typeof props.lesson !== "number" && props.lesson.editEnabled) {
      props.onLessonPropertyChange(property, value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lesson, props.onLessonPropertyChange]);

  return (
    <>
      {editExistingLessonPromptEnabled && (
        <Prompt onClickOutside={() => setEditExistingLessonPromptEnabled(false)}>
          <h2>Are you sure you want to enable edit for this lesson?</h2>
          <div>Editing here will update all instances of this lesson, if they exist in other courses.</div>
          <div>
            <button onClick={() => setEditExistingLessonPromptEnabled(false)}>No</button>
            <button
              onClick={() => {
                props.onLessonPropertyChange("editEnabled", true);
                setEditExistingLessonPromptEnabled(false);
              }}
            >
              Yes
            </button>
          </div>
        </Prompt>
      )}
      <section id={styles.main}>
        {props.course ? (
          <div>
            <h2
              onBlur={e => props.onCoursePropertyChange("title", e.target.innerText)}
              data-placeholder="Insert course title here"
              contentEditable
              suppressContentEditableWarning
            >
              {props.course.title}
            </h2>
            <p
              onBlur={e => props.onCoursePropertyChange("description", e.target.innerText)}
              data-placeholder="Insert course description here"
              data-allow-newline
              contentEditable
              suppressContentEditableWarning
            >
              {props.course.description}
            </p>
            <button onClick={props.onCourseDeleteButtonClick}>Delete course</button>
          </div>
        ) : (
          <h2>All lessons</h2>
        )}
        {props.lesson ? (
          typeof props.lesson === "number" ? (
            <div>
              <p>{props.lesson} lessons selected</p>
              <button onClick={props.onAddToCourseButtonClick}>Add to course</button>
            </div>
          ) : (
            <>
              {!props.lesson.editEnabled && (
                <div id={styles["enable-edit"]} onClick={() => setEditExistingLessonPromptEnabled(true)}>
                  Click here to enable edit.
                </div>
              )}
              <div aria-disabled={!props.lesson.editEnabled}>
                <h3
                  onBlur={e => lessonPropertyChangeHandler("title", e.target.innerText)}
                  data-placeholder="Insert lesson title here"
                  contentEditable
                  suppressContentEditableWarning
                >
                  {props.lesson.title}
                </h3>
                <p
                  onBlur={e => lessonPropertyChangeHandler("description", e.target.innerText)}
                  data-placeholder="Insert lesson description here"
                  data-allow-newline
                  contentEditable
                  suppressContentEditableWarning
                >
                  {props.lesson.description}
                </p>
                {props.course && (
                  <div aria-disabled={false}>
                    <label>Quantity:</label>
                    <EditableNumericSpan
                      value={
                        props.course.lessons.find(lesson => lesson.id === (props.lesson as EditableLessonData).id)
                          ?.quantity ?? Number.NaN
                      }
                      onManualChange={value =>
                        props.onCoursePropertyChange(
                          "lessons",
                          props.course!.lessons.map(lesson =>
                            lesson.id === (props.lesson as EditableLessonData).id
                              ? { id: lesson.id, quantity: _.clamp(Math.round(value), 1, 99) }
                              : lesson
                          )
                        )
                      }
                    />
                  </div>
                )}
                {props.lesson.type === LessonType.IMAGE ? (
                  <p>Image lessons not implemented yet.</p>
                ) : (
                  <>
                    <div>
                      <label>Type:</label>
                      <ButtonOptions
                        options={VIDEO_LESSON_OPTIONS}
                        value={props.lesson.type}
                        onChange={value => lessonPropertyChangeHandler("type", value)}
                      />
                    </div>
                    <div>
                      <label>Passing score:</label>
                      <input
                        type="range"
                        min={0}
                        max={1}
                        step={0.01}
                        value={props.lesson.scoreThreshold}
                        onChange={e => lessonPropertyChangeHandler("scoreThreshold", e.target.valueAsNumber)}
                      />
                      <EditableNumericSpan
                        value={props.lesson.scoreThreshold}
                        onManualChange={value =>
                          lessonPropertyChangeHandler("scoreThreshold", Math.roundToInterval(value, 0.01))
                        }
                        toFixed={2}
                        disabled={props.lesson.existingLesson}
                      />
                    </div>
                    <div id={styles.range}>
                      <label>Base scoring range:</label>
                      <EditableNumericSpan
                        value={props.lesson.range.min}
                        onManualChange={value =>
                          lessonPropertyChangeHandler("range", {
                            min: _.clamp(value, 0, (props.lesson! as EditableLessonData).range.max - 0.01)
                          })
                        }
                        toFixed={2}
                        disabled={props.lesson.existingLesson}
                      />
                      <MinMaxRangeInput
                        values={props.lesson.range}
                        onChange={values => lessonPropertyChangeHandler("range", values)}
                      />
                      <EditableNumericSpan
                        value={props.lesson.range.max}
                        onManualChange={value =>
                          lessonPropertyChangeHandler("range", {
                            max: _.clamp(value, (props.lesson as EditableLessonData).range.min + 0.01, 1)
                          })
                        }
                        toFixed={2}
                        disabled={props.lesson.existingLesson}
                      />
                    </div>
                    <div>
                      <label>Use whitelist:</label>
                      <input
                        type="checkbox"
                        checked={!!props.lesson.comparisonParams.whitelist}
                        onChange={e =>
                          lessonPropertyChangeHandler("comparisonParams", {
                            whitelist: e.target.checked ? [] : undefined
                          })
                        }
                      />
                    </div>
                    {props.lesson.comparisonParams.whitelist && (
                      <div id={styles.whitelist}>
                        {Object.keysWithType(MOVENET_NAMED_KP_PAIRS).map(key => (
                          <button
                            key={key}
                            onClick={e =>
                              lessonPropertyChangeHandler("comparisonParams", {
                                whitelist:
                                  e.currentTarget.ariaSelected === "true"
                                    ? (props.lesson! as EditableLessonData).comparisonParams.whitelist!.filter(
                                        v => v !== key
                                      )
                                    : [...(props.lesson! as EditableLessonData).comparisonParams.whitelist!, key]
                              })
                            }
                            role="option"
                            aria-selected={(props.lesson! as EditableLessonData).comparisonParams.whitelist!.includes(
                              key
                            )}
                          >
                            {_.startCase(key)}
                          </button>
                        ))}
                      </div>
                    )}
                    {props.lesson.type === LessonType.VIDEO_STAGGERED && (
                      <div id={styles.timestamps}>
                        <label>Timestamps:</label>
                        {props.lesson.timestamps.map((timestamp, idx) => (
                          <button
                            key={idx}
                            title="Click to remove this timestamp."
                            onClick={() =>
                              lessonPropertyChangeHandler(
                                "timestamps",
                                (props.lesson! as EditableLessonData).timestamps.filter(v => v !== timestamp)
                              )
                            }
                          >
                            {timestamp.toFixed(2)}
                          </button>
                        ))}
                        <button
                          className={styles.highlighted}
                          title="Click to add a timestamp."
                          onClick={() => {
                            if (
                              !(props.lesson! as EditableLessonData).timestamps.includes(
                                props.previewVideoRef.current!.currentTime
                              )
                            ) {
                              lessonPropertyChangeHandler(
                                "timestamps",
                                [
                                  ...(props.lesson! as EditableLessonData).timestamps,
                                  props.previewVideoRef.current!.currentTime
                                ].sort()
                              );
                            }
                          }}
                        >
                          +
                        </button>
                      </div>
                    )}
                    <button onClick={props.onTestButtonClick}>Test lesson</button>
                    {props.type === EditorType.CREATE && (
                      <button onClick={props.onAddToCourseButtonClick}>Add to course</button>
                    )}
                  </>
                )}
              </div>
            </>
          )
        ) : (
          <p>Select a lesson on the left.</p>
        )}
      </section>
    </>
  );
};

export default Editor;