import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

import { ObservableStatus } from "reactfire";
import { WithID } from "../firebase";

import { LessonMetadata } from "../types/LessonData";
import _ from "lodash";

export type LessonsState = {
  status: ObservableStatus<any>["status"];
  data: { [key: string]: LessonMetadata };
}

const initialState: LessonsState = {
  status: "loading",
  data: {}
};

export const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {
    setLessonsStatus: (state, action: PayloadAction<LessonsState["status"]>) => {
      state.status = action.payload;
    },
    /** Replaces state.data entirely. */
    setLessons: (state, { payload }: PayloadAction<LessonsState["data"]>) => {
      state.data = payload;
    },
    /** Only replaces data from store where its key is in payload. */
    updateLessons: (state, { payload }: PayloadAction<LessonsState["data"]>) => {
      _.merge(state.data, payload);
    },
    reset: () => initialState
  }
});

export const { setLessonsStatus, setLessons, updateLessons } = lessonsSlice.actions;

export const selectLessonMetadata = _.memoize((lessonId: string) => (state: RootState) => state.lessons.data[lessonId]);
export const selectLessonMetadataAsArray = (state: RootState): WithID<LessonMetadata>[] =>
  Object.entries(state.lessons.data).map(([id, data]) => ({ ...data, id }));

export const lessonsReducer = lessonsSlice.reducer;