import { useMemo, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useImmer } from "use-immer";

import { arrayRemove, arrayUnion, collection, doc, setDoc, writeBatch } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { getGenericConverter } from "../../firebase";
import { useAppSelector } from "../../redux/hooks";

import SearchWithFilter, { SearchFilters } from "../../components/SearchWithFilter";
import SelectableTable from "../../components/SelectableTable";
import SelectionGrid from "../../components/AssignmentGrid";
import Prompt from "../../hooks/UseFullscreenPrompt";
import type { DataProps } from ".";

import type { GroupData, UserData } from "../../types/UserData";
import type { Booleanish } from "../../utils/utils";

import _ from "lodash";
import { v4 as uuid } from "uuid";
import baseStyles from "./Manage.module.scss";
import styles from "./ManageGroups.module.scss";

enum PromptType {
  ADD = 1,
  ASSIGN,
  DELETE
}

export default function ManageGroups(props: DataProps) {
  const queryClient = useQueryClient();
  const db = useFirestore();
  const groupCollectionRef = useRef(collection(db, "groups").withConverter(getGenericConverter<GroupData>()));
  const userDataCollectionRef = useRef(collection(db, "users").withConverter(getGenericConverter<UserData>()));

  const courses = useAppSelector(state => state.courses.data);
  const lessons = useAppSelector(state => state.lessons.data);
  const [selection, setSelection] = useImmer(new Set<number>());
  const searchFilters = useMemo(
    () =>
      new SearchFilters<typeof tableData>().add(
        ["have", "course"],
        (group, option) => group.assignedCourses.includes(option),
        courses.map(course => [course.id, { label: course.title }] as const)
      ),
    [courses]
  );
  const tableData = useMemo(
    () =>
      [...props.groupData.entries()]
        .map(([key, value]) => ({ ...value, id: key }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [props.groupData]
  );
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const userAmountByGroup = useMemo(() => {
    const map = new Map<string, number>();
    const userDataEntries = [...props.userData.entries()];

    for (const group of tableData) {
      map.set(group.id, userDataEntries.filter(([, userData]) => userData.assignedGroups.includes(group.id)).length);
    }

    return map;
  }, [tableData, props.userData]);

  const selectedGroupsProgress = useMemo(
    () =>
      filteredTableData
        .filter((_, idx) => selection.has(idx))
        .map(group => ({
          ...group,
          users: [...props.userData.entries()]
            .filter(([, user]) => user.assignedGroups.includes(group.id))
            .map(([userId, user]) => ({ userId, name: props.userRecords.get(userId)?.displayName, progress: user.progress }))
        })),
    [filteredTableData, props.userData, props.userRecords, selection]
  );
  
  const [promptType, setPromptType] = useState<PromptType | null>(null);
  const newGroupNameInputRef = useRef<HTMLInputElement>(null);
  const originalCourseAssignments = useMemo(() => {
    const map = new Map<string, Booleanish>();

    for (const course of courses) {
      const groupsAssigned = [...selection.values()].filter(idx =>
        filteredTableData[idx]?.assignedCourses.includes(course.id)
      ).length;
      map.set(course.id, !groupsAssigned ? false : groupsAssigned === selection.size ? true : "?");
    }

    return map;
  }, [courses, filteredTableData, selection]);
  const [changedCourseAssignments, setChangedCourseAssignments] = useImmer(new Map<string, boolean>());

  const addGroup = useMutation(["add-group"], async () => {
    if (!newGroupNameInputRef.current) {
      throw new Error("newGroupNameInputRef is null");
    }

    if (!newGroupNameInputRef.current.value.length) {
      throw new Error("Group name cannot be empty");
    }

    const id = uuid();
    await setDoc(doc(groupCollectionRef.current, id), { id, name: newGroupNameInputRef.current.value, assignedCourses: [] });
  }, {
    onSuccess() {
      setPromptType(null);
      queryClient.invalidateQueries("group-data");
    }
  });

  const applyCourseAssignment = useMutation(
    ["apply-course-assignment"],
    async () => {
      const batch = writeBatch(db);
      const [coursesToAssign, coursesToUnassign] = _([...changedCourseAssignments.entries()])
        .groupBy(([, value]) => value)
        .thru(dict => [dict["true"], dict["false"]] as const)
        .map(arr => arr?.map(([key]) => key))
        .value();

      selection.forEach(idx => {
        if (coursesToAssign) {
          batch.update(doc(groupCollectionRef.current, filteredTableData[idx].id), {
            assignedCourses: arrayUnion(...coursesToAssign)
          });
        }

        if (coursesToUnassign) {
          batch.update(doc(groupCollectionRef.current, filteredTableData[idx].id), {
            assignedCourses: arrayRemove(...coursesToUnassign)
          });
        }
      });

      await batch.commit();
    },
    {
      onSuccess() {
        setPromptType(null);
        queryClient.invalidateQueries("group-data");
      }
    }
  );

  const deleteGroup = useMutation(["delete-group"], async () => {
    const batch = writeBatch(db);

    selection.forEach(idx => {
      batch.delete(doc(groupCollectionRef.current, filteredTableData[idx].id));

      props.userData.forEach((user, id) => {
        if (user.assignedGroups.includes(filteredTableData[idx].id)) {
          batch.update(doc(userDataCollectionRef.current, id), {
            assignedGroups: arrayRemove(filteredTableData[idx].id)
          });
        }
      });
    });

    setSelection(draft => draft.clear());
    await batch.commit();
  }, {
    onSuccess() {
      setPromptType(null);
    }
  });

  return (
    <div id={baseStyles.main}>
      {promptType && (
        <Prompt onClickOutside={() => setPromptType(null)}>
          {promptType === PromptType.ADD ? (
            <>
              <div>
                <label>Group name:</label>
                <input ref={newGroupNameInputRef} />
              </div>
              {addGroup.status === "error" && (
                <div className="error">
                  {addGroup.error instanceof Error ? `Error: ${addGroup.error.message}` : "An error occurred."}
                </div>
              )}
              <div>
                <button onClick={() => setPromptType(null)}>Cancel</button>
                <button
                  onClick={() => {
                    addGroup.reset();
                    addGroup.mutate();
                  }}
                >
                  Done
                </button>
              </div>
            </>
          ) : promptType === PromptType.ASSIGN ? (
            <>
              <SelectionGrid
                data={courses}
                keyField="id"
                textField="title"
                originalAssignment={originalCourseAssignments}
                newAssignment={changedCourseAssignments}
                setNewAssignment={setChangedCourseAssignments}
              />
              {applyCourseAssignment.isError && (
                <div className="error">
                  {applyCourseAssignment.error instanceof Error
                    ? applyCourseAssignment.error.message
                    : "An error occurred."}
                </div>
              )}
              <div>
                <button onClick={() => setPromptType(null)}>Cancel</button>
                <button
                  onClick={() => {
                    applyCourseAssignment.reset();
                    applyCourseAssignment.mutate();
                  }}
                >
                  Done
                </button>
              </div>
            </>
          ) : (
            <>
              <h2>Are you sure you want to delete the selected groups?</h2>
              {deleteGroup.status === "error" && (
                <div className="error">
                  {deleteGroup.error instanceof Error ? `Error: ${deleteGroup.error.message}` : "An error occurred."}
                </div>
              )}
              <div>
                <button
                  onClick={() => {
                    deleteGroup.reset();
                    deleteGroup.mutate();
                  }}
                >
                  Yes
                </button>
                <button onClick={() => setPromptType(null)}>No</button>
              </div>
            </>
          )}
        </Prompt>
      )}
      <div id={baseStyles.search}>
        <SearchWithFilter<typeof tableData[number]>
          data={tableData}
          textSearchKey="name"
          filters={searchFilters}
          onDataChange={data => setFilteredTableData(data)}
        />
        <button onClick={() => setPromptType(PromptType.ADD)}>Add group</button>
        {!!selection.size && (
          <>
            <button onClick={() => setPromptType(PromptType.ASSIGN)}>Update course assignment</button>
            <button onClick={() => setPromptType(PromptType.DELETE)}>Delete selected</button>
          </>
        )}
      </div>
      <div id={styles.tables}>
        <div>
          <SelectableTable
            data={filteredTableData}
            columns={[
              ["Name", row => row.name],
              [
                "Users assigned",
                row => userAmountByGroup.get(row.id)
              ],
              ["Courses assigned", row => row.assignedCourses.length]
            ]}
            dataKeyField="id"
            selection={selection}
            setSelection={setSelection}
          />
        </div>
        <div>
          {selectedGroupsProgress.map(group => {
            const assignedCourses = courses.filter(course => group.assignedCourses.includes(course.id));
            return (
              <>
                <h2 key={`${group.id}_title`}>{`${group.name} Progress`}</h2>
                {assignedCourses.length ? (
                  assignedCourses.map(course => (
                    <>
                      <h3 key={`${group.id}_${course.id}_title`}>{course.title}</h3>
                      {userAmountByGroup.get(group.id) === 0 ? (
                        <div key={`${group.id}_${course.id}_no_users`}>No users assigned to this group.</div>
                      ) : (
                        <SelectableTable
                          key={`${group.id}_${course.id}_table`}
                          data={group.users}
                          columns={[
                            ["Name", row => row.name],
                            ...course.lessons.map(
                              lesson =>
                                [
                                  lessons[lesson.id]?.title ?? "Title missing",
                                  (row: typeof group.users[number]) =>
                                    `${Math.round(
                                      ((row.progress?.[course.id]?.[lesson.id] ?? 0) / lesson.quantity) * 100
                                    )}%`,
                                  { style: { textAlign: "center" } }
                                ] as const
                            )
                          ]}
                          dataKeyField="userId"
                          selection={selection}
                          hideSelect
                        />
                      )}
                    </>
                  ))
                ) : (
                  <div key={group.id}>No courses assigned to this group.</div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}