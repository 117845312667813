import React from "react";
import { Pose, PoseDetector, SupportedModels, calculators } from "@tensorflow-models/pose-detection";
import classNames from "classnames";
import drawPose from "../../utils/CanvasDrawing";
import Vector2 from "../../utils/vector2";
import styles from "../../scss/BasePoseDetection.module.scss";

export interface BasePoseDetectionProps<T extends Pose | Pose[]> extends React.HTMLAttributes<HTMLDivElement> {
  detector: PoseDetector;
  videoControlsEnabled: boolean;
  flip: boolean;
  fillType?: "width" | "height";
  onComponentReady?: () => Promise<void>;
  onPoseCallback?: (pose: T) => void;
  onInitFailed?: (error: any) => void;
}

export interface BasePoseDetectionState {
  videoRef: React.RefObject<HTMLVideoElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  // fillStyle?: { width: number; height: number };
}

// TODO: Improve props and state typings to have BasePoseDetectionProps and BasePoseDetectionState override any
//       external props and state instead of just removing all external props and state types.
export default abstract class BasePoseDetection<
  ExternP = {}, ExternS = {}, PoseCallbackReturnType extends Pose | Pose[] = Pose | Pose[]
> extends React.Component<
  (ExternP extends keyof BasePoseDetectionProps<PoseCallbackReturnType>
    ? BasePoseDetectionProps<PoseCallbackReturnType>
    : BasePoseDetectionProps<PoseCallbackReturnType> & ExternP
  ),
  (ExternS extends keyof BasePoseDetectionState
    ? BasePoseDetectionState
    : BasePoseDetectionState & ExternS
  )
> {
  static defaultProps = {
    videoControlsEnabled: false,
    flip: false
  };

  resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      if (entry.target === this.state.videoRef.current) {
        this.state.canvasRef.current!.width = entry.contentRect.width;
        this.state.canvasRef.current!.height = entry.contentRect.height;
        this.onDimensionChange(new Vector2(entry.contentRect.width, entry.contentRect.height));
      }
    }
  });

  ctx?: CanvasRenderingContext2D;

  willUnmount = false;

  constructor(
    props: ExternP extends keyof BasePoseDetectionProps<PoseCallbackReturnType>
      ? BasePoseDetectionProps<PoseCallbackReturnType>
      : BasePoseDetectionProps<PoseCallbackReturnType> & ExternP
    ) {
    super(props);
    this.state = {
      ...this.state,
      videoRef: React.createRef(),
      canvasRef: React.createRef()
    };
  }

  componentDidMount() {
    this.resizeObserver.observe(this.state.videoRef.current!);
    this.ctx = this.state.canvasRef.current!.getContext("2d")!;
  }

  componentWillUnmount() {
    this.willUnmount = true;
    this.resizeObserver.disconnect();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDimensionChange(dimensions: Vector2) {}

  async detectAndDrawPose() {
    const pose = (await this.props.detector.estimatePoses(this.state.videoRef.current!))[0];
    
    if (pose) {
      drawPose(
        this.ctx!,
        SupportedModels.MoveNet,
        pose,
        { scale: this.state.canvasRef.current!.width / this.state.videoRef.current!.videoWidth }
      );
      return {
        ...pose,
        keypoints: calculators.keypointsToNormalizedKeypoints(pose.keypoints, {
          width: this.state.videoRef.current!.videoWidth,
          height: this.state.videoRef.current!.videoHeight
        })
      };
    } else {
      return undefined;
    }
  }

  renderTarget = (props: BasePoseDetectionState & React.HTMLAttributes<HTMLDivElement>) => {
    const { canvasRef, videoRef, ...rest } = props;
    return (
      <div
        {...rest}
        className={classNames(styles.main, props.className)}
        style={{ ...rest.style, ...(this.props.flip ? { transform: "scaleX(-1)" } : undefined) }}
      >
        <canvas ref={canvasRef}/>
        <video
          ref={videoRef}
          style={this.props.fillType ? { [this.props.fillType as string]: "100%" } : undefined}
          controls={this.props.videoControlsEnabled}
          controlsList="nofullscreen nodownload"
          crossOrigin="anonymous"
          muted disablePictureInPicture playsInline
        />
      </div>
    );
  };
}
