import { useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";

import { collection } from "firebase/firestore";
import { useFirestore, useFirestoreCollection, useUser } from "reactfire";
import { getGenericConverter } from "../../firebase";

import ManageUsers from "./ManageUsers";
import ManageGroups from "./ManageGroups";

import { getAllUsers } from "../../types/ApiMethods";
import { GroupData, UserData } from "../../types/UserData";

import classNames from "classnames";
import { constructMapFromDocs } from "../../utils/utils";
import styles from "../../scss/Dashboard.module.scss";
import ManageLessons from "./ManageLessons";
import ManageRecords from "./ManageRecords";

enum Tab {
  USERS = "Users",
  GROUPS = "Groups",
  LESSONS = "Lessons",
  RECORDS = "Records"
}

const Tabs = [Tab.USERS, Tab.GROUPS, Tab.LESSONS, Tab.RECORDS];

export interface DataProps {
  userRecords: Map<string, Awaited<ReturnType<typeof getAllUsers>>["data"]["users"][number]>;
  userData: Map<string, UserData>;
  groupData: Map<string, GroupData>;
}

export default function Dashboard() {
  const { data: currentUser } = useUser();
  const db = useFirestore();
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.USERS);

  const userRecords = useInfiniteQuery(["user-records"], async ({ pageParam }) =>
    (await getAllUsers({ requesterIdToken: await currentUser!.getIdToken(), nextPageToken: pageParam })).data
  , {
    getNextPageParam: res => res.nextPageToken,
    keepPreviousData: true,
    staleTime: Infinity
  });

  const userDataCollectionRef = useRef(collection(db, "users").withConverter(getGenericConverter<UserData>()));
  const userData = useFirestoreCollection(userDataCollectionRef.current, { idField: "id" });
  const groupDataCollectionRef = useRef(collection(db, "groups").withConverter(getGenericConverter<GroupData>()));
  const groupData = useFirestoreCollection(groupDataCollectionRef.current, { idField: "id" });

  const userRecordsMap = useMemo(
    () =>
      new Map(
        userRecords.data?.pages
          .map(page => page.users)
          .flat()
          .map(record => [record.uid, record])
      ),
    [userRecords.data]
  );
  const userDataMap = useMemo(
    () => (userData.data ? constructMapFromDocs(userData.data.docs) : new Map()),
    [userData.data]
  );
  const groupDataMap = useMemo(
    () => (groupData.data ? constructMapFromDocs(groupData.data.docs) : new Map()),
    [groupData.data]
  );

  return (
    <div id={styles.main}>
      <div role="navigation">
        {Tabs.map(tab => (
          <button
            key={tab}
            className={classNames("no-style")}
            onClick={() => setCurrentTab(tab)}
            aria-current={currentTab === tab}
          >
            {tab}
          </button>
        ))}
      </div>
      {userRecords.status === "success" && userData.status === "success" && groupData.status === "success" ? (
        currentTab === Tab.USERS ? (
          <ManageUsers
            userRecords={userRecordsMap}
            userData={userDataMap}
            groupData={groupDataMap}
          />
        ) : currentTab === Tab.GROUPS ? (
          <ManageGroups
            userRecords={userRecordsMap}
            userData={userDataMap}
            groupData={groupDataMap}
          />
        ) : currentTab === Tab.LESSONS ? (
            <ManageLessons/>
        ) : (
           <ManageRecords/>
        )

      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}