import { useEffect, useCallback, useState, useRef } from "react";

export default function useStateWithCallback<T>(initialState: T | (() => T)) {
  const [stateValue, setStateValue] = useState(initialState);
  const setStateCallback = useRef<(currentState: T) => void>();

  useEffect(() => {
    setStateCallback.current?.(stateValue);
    setStateCallback.current = undefined;
  }, [stateValue]);

  const setStateValueWrapper = useCallback((newStateValue: React.SetStateAction<T>, callback?: (currentState: T) => void) => {
    if (newStateValue instanceof Function) {
      newStateValue = newStateValue(stateValue);
    }

    if (stateValue === newStateValue) {
      callback?.(stateValue);
    } else {
      setStateCallback.current = callback;
      setStateValue(newStateValue);
    }
  }, [stateValue]);

  return [stateValue, setStateValueWrapper] as [T, (newState: React.SetStateAction<T>, callback?: () => void) => void];
}