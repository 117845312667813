import classNames from "classnames";
import type { Updater } from "use-immer";
import type { KeyofType } from "../utils/utils";
import styles from "../scss/SelectionGrid.module.scss";

interface SelectionGridProps<T> {
  data: T[];
  textField: KeyofType<T, string>;
  selection: Set<number>;
  setSelection: Updater<Set<number>>;
  disabledSelection?: Set<number>;
  column?: number;
  className?: string;
}

export default function SelectionGrid<T extends Record<string, any>>(props: SelectionGridProps<T>) {
  return (
    <div
      className={classNames(styles.main, props.className)}
      style={{ gridTemplateColumns: `repeat(${props.column ?? Math.min(3, props.data.length)}, minmax(0, 1fr))` }}
    >
      {props.data.map((value, idx) => (
        <button
          key={idx}
          className="no-style"
          onClick={() => props.setSelection(draft => draft.has(idx) ? draft.delete(idx) : draft.add(idx))}
          disabled={props.disabledSelection?.has(idx)}
        >
          <span>{value[props.textField]}</span>
          <input type="checkbox" checked={props.selection.has(idx)} readOnly/>
        </button>
      ))}
    </div>
  );
}