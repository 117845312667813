import { createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import type { UserData, UserSettings } from "../types/UserData";
import type { RootState } from "./store";
import _ from "lodash";

export type UserState = ({
  status: "success";
  data: UserData;
} | {
  status: "loading";
  data: null;
} | {
  status: "error";
  data: null;
  error?: Error;
}) & { settings: UserSettings };

const initialState: UserState = {
  status: "loading",
  data: null,
  settings: { handedness: "right" }
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState as UserState,
  reducers: {
    setUserData: (state, { payload }: PayloadAction<UserData>) => ({
      ...state,
      status: "success",
      data: payload
    }),
    setUserDataError: (state, { payload }: PayloadAction<Error | undefined>) => ({
      ...state,
      status: "error",
      data: null,
      error: payload
    }),
    setUserSettings: (state, { payload }: PayloadAction<Partial<UserSettings>>) => {
      _.merge(state.settings, payload);
      localStorage.setItem("userSettings", JSON.stringify(state.settings));
    },
    reset: () => initialState
  }
});

export const { setUserData, setUserDataError, setUserSettings } = userSlice.actions;

export function selectUserProgress(courseId: string): Selector<RootState, Required<UserData>["progress"][string]>;
export function selectUserProgress(courseId: string, lessonId: string): Selector<RootState, number>;
export function selectUserProgress(
  courseId: string,
  lessonId?: string
): Selector<RootState, Required<UserData>["progress"][string] | number> {
  return (state: RootState) => {
    const intermediate = state.user.data?.progress?.[courseId];
    return lessonId ? intermediate?.[lessonId] ?? 0 : intermediate ?? {};
  };
}

export const userReducer = userSlice.reducer;