import React, {useCallback, useState} from "react";
import {Link} from "react-router-dom";
import {browserLocalPersistence, setPersistence, signInWithEmailAndPassword} from "firebase/auth";
import {useAuth} from "reactfire";
import styles from "../scss/Login.module.scss";

export const Login: React.FC = () => {
    const auth = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);
        await setPersistence(auth, browserLocalPersistence);

        try {
            if (process.env.NODE_ENV === "development" && !email.length && !password.length) {
                if (process.env.REACT_APP_QUICK_LOGIN_EMAIL && process.env.REACT_APP_QUICK_LOGIN_PASSWORD) {
                    await signInWithEmailAndPassword(
                        auth,
                        process.env.REACT_APP_QUICK_LOGIN_EMAIL,
                        process.env.REACT_APP_QUICK_LOGIN_PASSWORD
                    );
                } else {
                    setError("No quick login credentials set");
                    return;
                }
            } else {
                await signInWithEmailAndPassword(auth, email, password);
            }
        } catch (e) {
            console.log({e});
            setError((e as any).message);
        }
    }, [auth, email, password]);

    const freeLogin = async () => {
        await setEmail('619341326@qq.com');
        await setPassword('123456');
        await setPersistence(auth, browserLocalPersistence);
        await signInWithEmailAndPassword(auth, '619341326@qq.com', '123456');
    };

    return (
        <div id={styles.main}>
            <main>
                <h1>Welcome back!</h1>
                <form onSubmit={handleSubmit}>
                    <div
                        style={{display: "flex", flexDirection: 'row', justifyContent: 'center', marginBottom: '20px'}}>
                        <label style={{width: '80px'}} htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            autoComplete="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>

                    <div
                        style={{display: "flex", flexDirection: 'row', justifyContent: 'center', marginBottom: '40px'}}>
                        <label style={{width: '80px'}} htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>

                    {error && <p style={{marginBottom: '10px'}} className="error">{error}</p>}

                    <div style={{display: "flex", flexDirection: "row", justifyContent: 'center', width: '100%'}}>
                        <button
                            type="button"
                            style={{
                                background: 'green'
                            }}
                            onClick={freeLogin}
                        >Free login
                        </button>
                        <button type="submit">
                            Login
                            {process.env.NODE_ENV === "development" && !email.length && !password.length ?
                                " (dev)" : ""
                            }
                        </button>
                        <button style={{
                            height: '50px',
                            width: '270px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                                 style={{width: '30px', height: '30px'}}>
                                <path fill="#EA4335"
                                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                <path fill="#4285F4"
                                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                <path fill="#FBBC05"
                                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                <path fill="#34A853"
                                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                            </svg>
                            <span style={{color: '#fff', marginLeft: '10px'}}>Sign in with Google</span>
                        </button>
                    </div>
                    <span>Not registered? <Link to="/register">Create your account here.</Link></span>
                </form>
            </main>
        </div>
    );
};

export default Login;
