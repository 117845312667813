import { configureStore } from "@reduxjs/toolkit";
import { appReducer, appSlice } from "./appSlice";
import { coursesReducer, coursesSlice } from "./coursesSlice";
import { lessonsReducer, lessonsSlice } from "./lessonsSlice";
import { userReducer, userSlice } from "./userSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    courses: coursesReducer,
    lessons: lessonsReducer,
    user: userReducer,
  },
  devTools: process.env.NODE_ENV !== "production"
});

export function resetStore() {
  for (const slice of [appSlice, coursesSlice, lessonsSlice, userSlice]) {
    console.log("Resetting store");
    store.dispatch(slice.actions.reset);
  }
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;