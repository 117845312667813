import { useCallback, useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { CSSTransition } from "react-transition-group";
import type { Pose } from "@tensorflow-models/pose-detection";

import { doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { getBlob, ref } from "firebase/storage";
import { useFirestore, useSigninCheck, useStorage } from "reactfire";
import { getGenericConverter } from "../../firebase";

import { useDispatch } from "react-redux";
import { disableHeader, enableHeader } from "../../redux/appSlice";
import { selectUserProgress } from "../../redux/userSlice";
import { selectCourse } from "../../redux/coursesSlice";
import { selectLessonMetadata } from "../../redux/lessonsSlice";
import { useAppSelector } from "../../redux/hooks";

import ReferenceView from "./ReferenceView";
import StatusView, { LessonStatus } from "./StatusView";

import WebcamPoseDetection from "../../components/PoseDetection/WebcamPoseDetection";
import BouncyText from "../../components/BouncyText";
import { Arrow, Home } from "../../components/icons";
import useDetector from "../../hooks/UseDetector";
import { computeDynamicPoseDiff, computeStaticPoseDiff, getTorsoCenter } from "../../utils/PoseUtils";
import { delay, getQuantityText } from "../../utils/utils";
import { CourseData, LessonData, LessonMetadata, LessonType } from "../../types/LessonData";

import _ from "lodash";
import styles from "../../scss/Lesson.module.scss";

interface NonTestingLessonProps {
  testingMode?: false;
}

interface TestingLessonProps {
  testingMode: true;
  courseData: CourseData;
  lessonIdx: number;
  lessonMetadata: LessonMetadata;
  lessonData: LessonData;
  lessonMedia: string;
  onLessonIdxChange: (lessonIdx: number) => void;
}

type LessonProps = (NonTestingLessonProps | TestingLessonProps) & Pick<React.HTMLAttributes<HTMLDivElement>, "className">;

export default function Lesson(props: LessonProps) {
  const { courseId, lessonIdx } = useParams();
  const [parsedLessonIdx, setParsedLessonIdx] = useState(Number(lessonIdx));

  useEffect(() => setParsedLessonIdx(Number(lessonIdx)), [lessonIdx]);

  return props.testingMode ? (
    <LessonValidated {...props} courseId="" lessonIdx={props.lessonIdx} />
  ) : courseId && !isNaN(parsedLessonIdx) && _.isInteger(parsedLessonIdx) && parsedLessonIdx >= 0 ? (
    <LessonValidated {...props} courseId={courseId} lessonIdx={parsedLessonIdx} />
  ) : (
    <Navigate to="/home" />
  );
}

export function LessonValidated(props: LessonProps & { courseId: string, lessonIdx: number }) {
  const navigate = useNavigate();
  const db = useFirestore();
  const storage = useStorage();
  const dispatch = useDispatch();
  const detector = useDetector();
  // @ts-expect-error
  const { data: signinCheckResult } = useSigninCheck({ requiredClaims: { admin: true } });
  
  const courseStatus = useAppSelector(state => props.testingMode ? "success" : state.courses.status);
  const course: CourseData | undefined = useAppSelector(props.testingMode ? () => props.courseData : selectCourse(props.courseId));
  const lessonProgress = useAppSelector(selectUserProgress(props.courseId, course?.lessons[props.lessonIdx].id ?? ""));
  const lessonMetadataStatus = useAppSelector(state => props.testingMode ? "success" : state.lessons.status);
  const lessonMetadata = useAppSelector(
    props.testingMode
      ? () => props.lessonMetadata
      : course && course.lessons[props.lessonIdx]
      ? selectLessonMetadata(course.lessons[props.lessonIdx].id)
      : () => undefined
  );
  const { data: lessonData, status: lessonDataStatus } = useQuery(
    ["lesson-data", course?.lessons[props.lessonIdx]?.id],
    async () => {
      if (props.testingMode) {
        return props.lessonData;
      }

      const lessonDataDoc = await getDoc(
        doc(db, `lesson-data/${course!.lessons[props.lessonIdx].id}`).withConverter(getGenericConverter<LessonData>())
      );

      if (!lessonDataDoc.exists()) {
        throw new Error("Lesson data not found");
      } else {
        return lessonDataDoc.data();
      }
    },
    { enabled: !!course, staleTime: Infinity }
  );
  const { data: lessonMedia, status: lessonMediaStatus } = useQuery(
    ["lesson-media", course?.lessons[props.lessonIdx]?.id],
    async () =>
      props.testingMode ? props.lessonMedia : URL.createObjectURL(await getBlob(ref(storage, `lesson-media/${course!.lessons[props.lessonIdx].id}`))),
    { enabled: !!course, staleTime: Infinity }
  );

  const stepCompleteAudioRef = useRef(new Audio(`${window.location.origin}/step_complete.mp3`));
  const lessonCompleteAudioRef = useRef(new Audio(`${window.location.origin}/lesson_complete.mp3`));
  const referenceVideoRef = useRef<HTMLVideoElement>(null);
  const [videoReady, setVideoReady] = useState(false);
  const [webcamReady, setWebcamReady] = useState(false);
  const [lessonStatus, setLessonStatus] = useState(LessonStatus.IDLE);
  const [score, setScore] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const restartFn = useRef(() => {});
  const devCompleteNext = useRef(false);
  // LessonType.VIDEO_STAGGERED states
  const [prevTimestampIdx, setPrevTimestampIdx] = useState(-1);
  const [reference, setReference] = useState<{ pose: Pose, passed: () => void } | null>(null);
  const [movementGuide, setMovementGuide] = useState<"left" | "right" | null>(null);
  // LessonType.VIDEO states
  const referenceMotionRef = useRef<Parameters<typeof computeDynamicPoseDiff>[0]>([]);
  const userMotionRef = useRef<Parameters<typeof computeDynamicPoseDiff>[0]>([]);

  // Toggle header on mount and unmount
  useEffect(() => {
    dispatch(disableHeader());

    return () => {
      dispatch(enableHeader());
    };
  }, [dispatch]);

  // Default to zeroth lesson if invalid lessonIdx
  useEffect(() => {
    if (course && props.lessonIdx >= course?.lessons.length) {
      navigate("../0");
    }
  }, [course, navigate, props.lessonIdx]);

  // Redirect to home on error
  useEffect(() => {
    if (courseStatus === "error" || lessonMetadataStatus === "error" || lessonMediaStatus === "error") {
      alert("An error occurred. Please try again later.");
      navigate("/home");
    }
  }, [courseStatus, lessonMediaStatus, lessonMetadataStatus, navigate]);

  // Transform reference motion data
  useEffect(() => {
    if (lessonData?.type === LessonType.VIDEO) {
      referenceMotionRef.current = lessonData.poseData.poses.map((pose, idx) => ({
        keypoints: pose.keypoints,
        timestamp: (1 / lessonData.poseData.frameRate) * idx
      }));
    }
  }, [lessonData]);

  // Update user progress records
  useEffect(() => {
    if (!signinCheckResult.hasRequiredClaims && lessonStatus === LessonStatus.ENDED) {
      updateDoc(doc(db, `users/${signinCheckResult.user!.uid}`), { [`progress.${props.courseId}.${course!.lessons[props.lessonIdx].id}`]: increment(1) })
        .then(() => console.log("Progress saved"))
        .catch(err => console.error(err));
    }
  }, [course, db, lessonStatus, props.courseId, props.lessonIdx, signinCheckResult.hasRequiredClaims, signinCheckResult.user]);

  const resetLesson = useCallback((fullReset = true) => {
    console.log("Resetting lesson");

    if (fullReset) {
      setVideoReady(false);
      referenceMotionRef.current = [];
    }

    referenceVideoRef.current!.currentTime = 0;
    setScore(0);
    setLessonStatus(LessonStatus.IDLE);
    setPrevTimestampIdx(-1);
    setReference(null);
    setMovementGuide(null);
    userMotionRef.current = [];
  }, []);

  const countdownCompleteHandler = useCallback(() => {
    setLessonStatus(LessonStatus.TRANSITION);
    restartFn.current();
    referenceVideoRef.current?.play().catch(err => console.warn(err));
  }, []);

  const comparePoses = useCallback(async (pose: Pose) => {
    if (lessonData?.type !== LessonType.VIDEO_STAGGERED) {
      throw new Error("comparePoses can only be called with a VIDEO_STAGGERED lesson");
    }
    if (reference) {
      const deltaX = getTorsoCenter(pose.keypoints).x - getTorsoCenter(reference.pose.keypoints).x;
      const score = Math.mapRange(
        1 - computeStaticPoseDiff(reference.pose.keypoints, pose.keypoints, lessonData.comparisonParams),
        lessonData?.range?.min ?? 0,
        lessonData?.range?.max ?? 1,
        0,
        1,
        true
      );
      setScore(score);

      const movementGuide = deltaX > 0.1 ? "left" : deltaX < -0.1 ? "right" : null;
      setMovementGuide(movementGuide);

      if (devCompleteNext.current || (score >= lessonData.scoreThreshold && !movementGuide)) {
        console.log(devCompleteNext.current ? "Passed (dev)" : `Passed with score of ${score} and deltaX of ${deltaX}`);
        devCompleteNext.current = false;
        setReference(null);
        setLessonStatus(LessonStatus.TRANSITION);
        stepCompleteAudioRef.current.pause();
        stepCompleteAudioRef.current.currentTime = 0;
        stepCompleteAudioRef.current.play();
        reference.passed();
      }
    }
  }, [lessonData, reference]);

  const compareMotions = useCallback(async () => {
    if (lessonData?.type !== LessonType.VIDEO) {
      throw new Error("compareMotions can only be called with a VIDEO lesson");
    }

    const score = Math.mapRange(
      1 - computeDynamicPoseDiff(referenceMotionRef.current, userMotionRef.current, lessonData.comparisonParams),
      lessonData?.range?.min ?? 0,
      lessonData?.range?.max ?? 1,
      0,
      1,
    );
    setScore(score);

    if (devCompleteNext.current || score >= lessonData.scoreThreshold) {
      console.log(devCompleteNext.current ? "Passed (dev)" : `Passed with score of ${score}`);
      devCompleteNext.current = false;
      setLessonStatus(LessonStatus.ENDED);
      lessonCompleteAudioRef.current.pause();
      lessonCompleteAudioRef.current.currentTime = 0;
      lessonCompleteAudioRef.current.play();
    } else {
      await delay(5000);
      setLessonStatus(LessonStatus.COUNTDOWN);
      userMotionRef.current = [];
    }
  }, [lessonData]);

  return (
    <div id={styles.main} className={props.className}>
      {!props.testingMode && (
        <Link to="/home">
          <Home />
        </Link>
      )}
      {process.env.NODE_ENV === "development" && (
        <div id={styles.dev}>
          <button className="no-style" onClick={() => (devCompleteNext.current = true)}>
            Complete next
          </button>
        </div>
      )}
      <CSSTransition classNames="fade" in={!(videoReady && webcamReady)} timeout={500} mountOnEnter unmountOnExit>
        <BouncyText id={styles.loading}>Loading...</BouncyText>
      </CSSTransition>
      {detector.status === "loaded" &&
        lessonMetadataStatus === "success" &&
        lessonDataStatus === "success" &&
        lessonMediaStatus === "success" && (
          <>
            {movementGuide && <Arrow direction={movementGuide} />}
            <WebcamPoseDetection
              id={styles.webcam}
              detector={detector.detector}
              enabled={lessonStatus === LessonStatus.TRANSITION || lessonStatus === LessonStatus.STAGGERED}
              onComponentReady={async () => setWebcamReady(true)}
              onPoseCallback={pose => {
                switch (lessonData.type) {
                  case LessonType.VIDEO_STAGGERED:
                    comparePoses(pose);
                    break;
                  case LessonType.VIDEO:
                    if (lessonStatus === LessonStatus.TRANSITION) {
                      userMotionRef.current.push({ keypoints: pose.keypoints, timestamp: Date.now() / 1000 });
                    }

                    break;
                }
              }}
              fillType="width"
            />
            <div id={styles.sidebar}>
              <ReferenceView
                detector={detector.detector}
                lessonData={lessonData}
                lessonMedia={lessonMedia}
                videoRef={referenceVideoRef}
                prevTimestampIdx={prevTimestampIdx}
                onComponentReady={async () => setVideoReady(true)}
                onVideoStaggered={async (pose, idx) => {
                  let passed!: () => void;
                  const p = new Promise<void>(r => (passed = r));
                  setReference({ pose, passed });
                  setLessonStatus(LessonStatus.STAGGERED);
                  await p;
                  setPrevTimestampIdx(idx);
                }}
                onVideoEnded={restart => {
                  restartFn.current = restart;

                  switch (lessonData.type) {
                    case LessonType.VIDEO_STAGGERED:
                      setLessonStatus(LessonStatus.ENDED);
                      lessonCompleteAudioRef.current.pause();
                      lessonCompleteAudioRef.current.currentTime = 0;
                      lessonCompleteAudioRef.current.play();
                      break;
                    case LessonType.VIDEO:
                      setLessonStatus(LessonStatus.STAGGERED);
                      compareMotions();
                      break;
                  }
                }}
              />
              <StatusView
                status={lessonStatus}
                transitionText={
                  <h2>
                    {lessonData.type === LessonType.VIDEO ? (
                      "Attempt this motion."
                    ) : lessonData.type === LessonType.VIDEO_STAGGERED ? (
                      prevTimestampIdx ? (
                        <>
                          Good job!
                          <br />
                          Let's try the next one.
                        </>
                      ) : (
                        "Attempt this exercise."
                      )
                    ) : (
                      "Not implemented"
                    )}
                  </h2>
                }
                score={score}
                onStartClicked={() => setLessonStatus(LessonStatus.COUNTDOWN)}
                onCountdownCompleted={countdownCompleteHandler}
                onRestartClicked={() => resetLesson(false)}
              />
              <div id={styles.title}>
                {lessonMetadata!.title}{" "}
                {getQuantityText(
                  signinCheckResult.hasRequiredClaims,
                  course!.lessons[props.lessonIdx].quantity,
                  lessonProgress
                )}
                <div>
                  {course!.lessons[props.lessonIdx - 1] ? (
                    <Link to={props.testingMode ? "" : `../${props.lessonIdx - 1}`}>
                      <button
                        className="no-style"
                        onClick={() => {
                          resetLesson();

                          if (props.testingMode) {
                            props.onLessonIdxChange(props.lessonIdx - 1);
                          }
                        }}
                      >
                        <Arrow direction="left" />
                        Prev
                      </button>
                    </Link>
                  ) : (
                    <button className="no-style" disabled>
                      <Arrow direction="left" />
                      Prev
                    </button>
                  )}
                  {course!.lessons[props.lessonIdx + 1] ? (
                    <Link to={props.testingMode ? "" : `../${props.lessonIdx + 1}`}>
                      <button
                        className="no-style"
                        onClick={() => {
                          resetLesson();

                          if (props.testingMode) {
                            props.onLessonIdxChange(props.lessonIdx + 1);
                          }
                        }}
                      >
                        Next
                        <Arrow direction="right" />
                      </button>
                    </Link>
                  ) : (
                    <button className="no-style" disabled>
                      Next
                      <Arrow direction="right" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
}