import React from "react";
import classNames from "classnames";
import _ from "lodash";
import styles from "../scss/CardListItem.module.scss";

interface CardListItemProps extends React.LiHTMLAttributes<HTMLLIElement> {
  children?: string
  indicatorType?: "normal" | "button";
  buttonProps?: React.HTMLAttributes<HTMLButtonElement>;
  hideArrow?: boolean;
  disableHoverEffect?: boolean;
}

export const CardListItem: React.FC<CardListItemProps> = props => {
  const { children, hideArrow, disableHoverEffect, indicatorType, buttonProps, ...rest } = props;
  return (
    <li
      {...rest}
      className={classNames(styles.main, { [styles.placeholder]: !props.children }, props.className)}
      data-arrow-hidden={props.hideArrow || props.indicatorType === "button"}
      data-no-hover-effect={props.disableHoverEffect || !props.children}
    >
      <div>{props.children ?? <div/>}</div>
      {props.indicatorType === "button" && (
        <button {..._.omit(buttonProps, "children")} className={classNames("no-style", buttonProps?.className)}>
          {buttonProps?.children}
        </button>
      )}
    </li>
  );
};

CardListItem.defaultProps = { hideArrow: false, indicatorType: "normal", disableHoverEffect: false };

export default CardListItem;