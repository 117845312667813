import styles from "./index.module.scss";

interface BaseIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

interface ArrowProps extends BaseIconProps {
  direction?: "up" | "down" | "left" | "right";
}

export const Arrow: React.FC<ArrowProps> = (props) => {
  const { color, direction, ...rest } = props;

  return (
    <svg
      {...rest}
      className={[rest.className, styles["line-based"], styles.inline].join(" ").trim()}
      width="43"
      height="39"
      viewBox="0 0 43 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${
          props.direction === "up"
            ? "90deg"
            : props.direction === "down"
            ? "-90deg"
            : props.direction === "right"
            ? "180deg"
            : "0deg"
        })`,
      }}
    >
      <path
        d="M20.4922 30.4688L8.39844 19.5M8.39844 19.5L20.4922 8.53125M8.39844 19.5H32.9219"
        stroke={props.color}
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Arrow.defaultProps = { direction: "left" };

export const Home: React.FC<BaseIconProps> = (props) => {
  return (
    <svg
      className={styles["line-based"]}
      enableBackground="new 0 0 32 32"
      id="Editable-line"
      version="1.1"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M27,29H5V17H3.235c-1.138,0-1.669-1.419-0.812-2.168L14.131,3.745c1.048-0.993,2.689-0.993,3.737,0l11.707,11.087  C30.433,15.58,29.902,17,28.763,17H27V29z"
        fill="none"
        id="XMLID_1_"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M20,29h-8v-6c0-2.209,1.791-4,4-4h0c2.209,0,4,1.791,4,4V29z"
        fill="none"
        id="XMLID_2_"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};

export const LessonCompletedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="320" height="128" viewBox="0 0 320 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M144.1 32.2524C142.412 32.2926 140.955 32.5731 139.729 33.0941C138.412 33.6585 137.4 34.4831 136.691 35.5677C135.994 36.6413 135.646 37.7978 135.646 39.0374C135.646 40.9632 136.393 42.5957 137.887 43.9349C138.949 44.8867 140.798 45.6891 143.432 46.3421C143.664 46.3998 143.886 46.4556 144.1 46.5093V41.4292C142.562 41.0005 141.553 40.5851 141.074 40.1829C140.587 39.7734 140.344 39.2809 140.344 38.7054C140.344 38.0745 140.604 37.571 141.124 37.1947C141.859 36.6609 142.851 36.3699 144.1 36.3219V32.2524Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M145.6 36.3855V32.2766C148.125 32.4187 150.064 33.0785 151.417 34.2562C152.966 35.5954 153.78 37.3828 153.857 39.6185L148.943 39.8343C148.733 38.5837 148.279 37.6872 147.582 37.1449C147.096 36.753 146.435 36.4998 145.6 36.3855Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M145.6 41.8122V46.8957C146.441 47.1184 147.029 47.288 147.366 47.4046C148.207 47.7034 148.794 48.0576 149.126 48.4671C149.469 48.8656 149.641 49.3525 149.641 49.9281C149.641 50.8245 149.237 51.6103 148.429 52.2855C147.761 52.8417 146.818 53.1649 145.6 53.2553V57.4177C147.367 57.3526 148.869 57.064 150.105 56.5521C151.522 55.9544 152.618 55.0469 153.393 53.8294C154.167 52.612 154.555 51.306 154.555 49.9115C154.555 48.373 154.228 47.0837 153.575 46.0433C152.933 44.9919 152.037 44.1673 150.886 43.5697C149.747 42.9618 147.985 42.3759 145.6 41.8122Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M144.1 57.4189V53.2467C142.956 53.1376 142.02 52.773 141.29 52.1527C140.416 51.4001 139.835 50.2214 139.547 48.6165L134.766 49.0814C135.087 51.804 136.072 53.8792 137.721 55.307C139.241 56.6126 141.367 57.3166 144.1 57.4189Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path d="M118.1 57.0003V32.6624H113.333V57.0003H118.1Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M119.1 57.0003H131.844V52.8997H119.1V57.0003Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M119.1 46.2757H130.466V42.1751H119.1V46.2757Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M119.1 36.7796H131.379V32.6624H119.1V36.7796Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M99 57.0003H109.813V52.8997H99V57.0003Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M97.5996 52.8997H97.5947V32.8616H92.6807V57.0003H97.5996V52.8997Z" fill="#40432E" fillOpacity="0.8" />
      <path
        d="M168.5 57.4083C170.171 57.3278 171.599 57.0424 172.783 56.5521C174.2 55.9544 175.296 55.0469 176.07 53.8294C176.845 52.612 177.232 51.306 177.232 49.9115C177.232 48.373 176.906 47.0837 176.253 46.0433C175.611 44.9919 174.715 44.1673 173.563 43.5697C172.461 42.9808 170.773 42.4127 168.5 41.8653V46.955C169.223 47.1487 169.738 47.2986 170.044 47.4046C170.885 47.7034 171.472 48.0576 171.804 48.4671C172.147 48.8656 172.318 49.3525 172.318 49.9281C172.318 50.8245 171.914 51.6103 171.106 52.2855C170.48 52.8073 169.611 53.1241 168.5 53.2359V57.4083Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M167 46.5656V41.4903C165.337 41.04 164.254 40.6042 163.752 40.1829C163.265 39.7734 163.021 39.2809 163.021 38.7054C163.021 38.0745 163.282 37.571 163.802 37.1947C164.58 36.6295 165.646 36.3366 167 36.3159V32.2485C165.217 32.2685 163.687 32.5504 162.407 33.0941C161.09 33.6585 160.077 34.4831 159.369 35.5677C158.672 36.6413 158.323 37.7978 158.323 39.0374C158.323 40.9632 159.07 42.5957 160.564 43.9349C161.627 44.8867 163.475 45.6891 166.109 46.3421C166.424 46.4202 166.72 46.4947 167 46.5656Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M168.5 32.2906V36.4199C169.231 36.5468 169.818 36.7884 170.26 37.1449C170.957 37.6872 171.411 38.5837 171.621 39.8343L176.535 39.6185C176.458 37.3828 175.644 35.5954 174.095 34.2562C172.782 33.1134 170.917 32.4582 168.5 32.2906Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M167 53.2645V57.4259C164.16 57.3542 161.959 56.6479 160.398 55.307C158.749 53.8792 157.764 51.804 157.443 49.0814L162.225 48.6165C162.512 50.2214 163.093 51.4001 163.968 52.1527C164.744 52.8128 165.755 53.1834 167 53.2645Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M191.5 32.2589C189.78 32.3213 188.245 32.6384 186.895 33.2103C185.755 33.6862 184.703 34.4167 183.74 35.4017C182.788 36.3867 182.036 37.499 181.482 38.7386C180.741 40.4209 180.37 42.5016 180.37 44.9808C180.37 48.8545 181.438 51.8981 183.574 54.1117C185.569 56.1687 188.211 57.2652 191.5 57.401V53.1846C189.849 53.035 188.463 52.3368 187.343 51.0902C186.07 49.6624 185.434 47.5706 185.434 44.8148C185.434 42.0146 186.053 39.9228 187.293 38.5394C188.388 37.307 189.791 36.6191 191.5 36.4759V32.2589Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M193 36.4862V32.2694C196.187 32.4354 198.767 33.5404 200.74 35.5843C202.898 37.8089 203.978 40.9023 203.978 44.8646C203.978 48.7936 202.91 51.8704 200.773 54.095C198.798 56.1424 196.207 57.2429 193 57.3966V53.173C194.596 53.0024 195.931 52.3137 197.005 51.1068C198.267 49.6901 198.897 47.5706 198.897 44.7484C198.897 41.9593 198.283 39.8786 197.055 38.5062C196.004 37.3218 194.652 36.6485 193 36.4862Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M222.6 57.0003H227.187V32.6624H222.621V48.9154L222.6 48.881V57.0003Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M221 46.2703L212.66 32.6624H207.879V34.0003H208L218 50.0003H217.928L221 54.9688V46.2703Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path d="M212.444 42.7113V57.0003H207.879V35.4066L212.444 42.7113Z" fill="#40432E" fillOpacity="0.8" />
      <path
        d="M92 96.3922C95.1665 96.2229 97.7287 95.1239 99.6865 93.095C101.823 90.8704 102.891 87.7936 102.891 83.8646C102.891 79.9023 101.812 76.8089 99.6533 74.5843C97.6985 72.559 95.1474 71.4556 92 71.2742V75.4952C93.613 75.6723 94.9356 76.3426 95.9678 77.5062C97.1963 78.8786 97.8105 80.9593 97.8105 83.7484C97.8105 86.5706 97.1797 88.6901 95.918 90.1068C94.8637 91.2917 93.5577 91.9772 92 92.1632V96.3922Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M90.5 75.4691C88.7514 75.596 87.32 76.2861 86.2061 77.5394C84.9665 78.9228 84.3467 81.0146 84.3467 83.8148C84.3467 86.5706 84.9831 88.6624 86.2559 90.0902C87.3955 91.3586 88.8102 92.0592 90.5 92.1919V96.4043C87.1708 96.2844 84.4999 95.1868 82.4873 93.1116C80.3512 90.8981 79.2832 87.8545 79.2832 83.9808C79.2832 81.5016 79.654 79.4209 80.3955 77.7386C80.9489 76.499 81.7015 75.3867 82.6533 74.4017C83.6162 73.4167 84.6676 72.6862 85.8076 72.2103C87.1811 71.6288 88.7452 71.3107 90.5 71.2559V75.4691Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M65 71.2989C62.1554 71.5424 59.8165 72.6486 57.9834 74.6175C55.8916 76.8532 54.8457 79.9964 54.8457 84.0472C54.8457 87.8766 55.8861 90.8981 57.9668 93.1116C59.8432 95.0978 62.1876 96.1884 65 96.3835V92.157C63.629 91.9708 62.4854 91.3372 61.5693 90.2562C60.4626 88.9502 59.9092 86.7588 59.9092 83.682C59.9092 80.7822 60.4681 78.6849 61.5859 77.39C62.5018 76.3394 63.6398 75.715 65 75.5168V71.2989Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M66.5 75.4673V71.2498C69.3557 71.3008 71.6855 72.1799 73.4893 73.887C74.596 74.9274 75.4261 76.4215 75.9795 78.3695L71.1152 79.5316C70.8275 78.2699 70.2243 77.2738 69.3057 76.5433C68.5198 75.9108 67.5846 75.5521 66.5 75.4673Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M66.5 92.1831V96.4051C68.8592 96.3183 70.8238 95.6798 72.3936 94.4896C74.098 93.1947 75.3154 91.2191 76.0459 88.5628L71.2812 87.0521C70.8717 88.834 70.1966 90.14 69.2559 90.97C68.466 91.667 67.5474 92.0714 66.5 92.1831Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M113.337 85.1832L116.056 96.0003H120.787L125.618 76.8421V76.8748L126.931 71.6624H122.812L118.446 88.264L114.03 71.6624H110.009L113.337 85.1832Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path d="M109.009 71.6624H106.676V96.0003H111.241V80.7302L109.009 71.6624Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M127.931 71.6624L125.618 80.8442V96.0003H130.184V71.6624H127.931Z" fill="#40432E" fillOpacity="0.8" />
      <path
        d="M141.5 86.8197H143.183C145.407 86.8197 147.106 86.7034 148.279 86.471C149.143 86.2829 149.989 85.901 150.819 85.3255C151.66 84.7389 152.352 83.9365 152.895 82.9183C153.437 81.9001 153.708 80.6439 153.708 79.1497C153.708 77.2129 153.238 75.6357 152.297 74.4183C151.356 73.1898 150.188 72.3929 148.794 72.0277C147.886 71.7842 145.938 71.6624 142.95 71.6624H141.5V75.7796H142.353C144.123 75.7796 145.302 75.835 145.889 75.9456C146.686 76.0895 147.344 76.4492 147.864 77.0247C148.384 77.6003 148.645 78.3307 148.645 79.2161C148.645 79.9355 148.456 80.5664 148.08 81.1087C147.715 81.651 147.206 82.0495 146.553 82.304C145.9 82.5586 144.605 82.6859 142.668 82.6859H141.5V86.8197Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M140 75.7796H139.979V82.6859H140V86.8197H139.979V96.0003H135.064V71.6624H140V75.7796Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path d="M162.8 91.8997H162.789V71.8616H157.875V96.0003H162.8V91.8997Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M164.2 96.0003V91.8997H175.008V96.0003H164.2Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M183.4 96.0003V71.6624H178.527V96.0003H183.4Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M184.5 96.0003H197.038V91.8997H184.5V96.0003Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M184.5 85.2757H195.66V81.1751H184.5V85.2757Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M184.5 75.7796H196.573V71.6624H184.5V75.7796Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M206.684 76.6003V96.0003H211.598V76.6003H206.684Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M199.462 75.7796V71.6624H218.803V75.7796H199.462Z" fill="#40432E" fillOpacity="0.8" />
      <path
        d="M226.91 75.7796V71.6624H221.99V96.0003H226.91V91.8997H226.904V85.2757H226.91V81.1751H226.904V75.7796H226.91Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path d="M227.8 81.1751V85.2757H239.123V81.1751H227.8Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M227.8 91.8997V96.0003H240.501V91.8997H227.8Z" fill="#40432E" fillOpacity="0.8" />
      <path d="M227.8 75.7796V71.6624H240.036V75.7796H227.8Z" fill="#40432E" fillOpacity="0.8" />
      <path
        d="M249.58 91.8997V96.0003H244.651V71.6624H249.58V75.7796H249.565V91.8997H249.58Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <path
        d="M250.5 91.8997V96.0003H253.898C255.714 96.0003 257.163 95.8288 258.248 95.4857C259.698 95.0208 260.849 94.3734 261.701 93.5433C262.83 92.4476 263.699 91.0143 264.308 89.2435C264.806 87.7936 265.055 86.0671 265.055 84.0638C265.055 81.7838 264.789 79.8691 264.258 78.3197C263.727 76.7591 262.952 75.4421 261.934 74.3685C260.915 73.2949 259.692 72.5478 258.265 72.1273C257.202 71.8174 255.658 71.6624 253.633 71.6624H250.5V75.7796H251.773C253.777 75.7796 255.121 75.8571 255.808 76.012C256.726 76.2113 257.484 76.5931 258.082 77.1575C258.68 77.722 259.145 78.5078 259.477 79.515C259.809 80.5221 259.975 81.9665 259.975 83.848C259.975 85.7295 259.809 87.2181 259.477 88.3138C259.145 89.3984 258.713 90.1787 258.182 90.6546C257.661 91.1305 257.003 91.4681 256.206 91.6673C255.597 91.8223 254.607 91.8997 253.234 91.8997H250.5Z"
        fill="#40432E"
        fillOpacity="0.8"
      />
      <rect x="4" y="4.00049" width="312" height="120" rx="60" stroke="#40432E" strokeOpacity="0.8" strokeWidth="8" />
    </svg>
  );
};
