import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useMutation } from "react-query";
import { BiPlus, BiDownload, BiUpload } from "react-icons/bi";
import { useImmer } from "use-immer";

import { useFirestore, useSigninCheck, useStorage } from "reactfire";
import { useAppSelector } from "../redux/hooks";
import type { CoursesState } from "../redux/coursesSlice";

import CardListItem from "../components/CardListItem";
import Prompt from "../hooks/UseFullscreenPrompt";
import useStateWithCallback from "../hooks/UseStateWithCallback";
import { deleteLessons, exportCourse, importCourse } from "../types/ApiMethods";

import _ from "lodash";
import pluralize from "pluralize";
import { getQuantityText } from "../utils/utils";
import styles from "../scss/Home.module.scss";

enum PromptType {
  DELETION = 1,
  IMPORT
}

export default function Home() {
  // @ts-expect-error
  const { data: signInCheckResult } = useSigninCheck({ requiredClaims: { admin: true } });
  const db = useFirestore();
  const storage = useStorage();

  const { data: userData } = useAppSelector(state => state.user);
  const [promptType, setPromptType] = useState<PromptType | null>(null);
  const courses = useAppSelector(state => state.courses);
  const lessons = useAppSelector(state => state.lessons);
  const [unassignedLessons, setUnassignedLessons] = useState<CoursesState["data"][number]>({
    id: "unassigned",
    title: "Unassigned lessons",
    description: "These lessons are not assigned to any course yet",
    lessons: []
  });

  const [selectedCourseIdx, setSelectedCourseIdx] = useStateWithCallback(Number.NaN);
  const [selectedCourse, setSelectedCourse] = useState<CoursesState["data"][number]>();
  const [isManaging, setIsManaging] = useState(false);
  const [deletionList, setDeletionList] = useImmer<{ [courseIdx: number]: Array<number> | true }>({});
  const [deleteUnassigned, setDeleteUnassigned] = useState(false);
  const stagedDeletionCount = useMemo(() => {
    const [courses, coursesPartial] = _.partition(
      Object.values(deletionList),
      deletion => deletion === true
    ) as [true[], number[][]];
    const lessons = _.sumBy(coursesPartial, deletion => deletion.length);

    return { courses: courses.length, coursesPartial: coursesPartial.length, lessons };
  }, [deletionList]);

  const deleteLessonsQuery = useMutation(
    ["delete-lessons", deletionList, courses.data, deleteUnassigned],
    async () => {
      await new Promise<void>(res => setSelectedCourseIdx(-1, () => res()));
      const allCourses = [...courses.data];
      allCourses[-1] = unassignedLessons;
      deleteLessons(db, storage, deletionList, allCourses, deleteUnassigned);
    }, {
      onSuccess() {
        setPromptType(null);
        setDeletionList({});
        setIsManaging(false);
      }
    }
  );

  const [importCourseProgress, setImportCourseProgress] = useState({ progress: 0, status: "", done: false });

  useEffect(
    () =>
      setSelectedCourse(
        isNaN(selectedCourseIdx)
          ? undefined
          : selectedCourseIdx === -1
          ? unassignedLessons
          : courses.data[selectedCourseIdx]
      ),
    [selectedCourseIdx, courses.data, unassignedLessons]
  );

  useEffect(() => {
    if (courses.status === "success" && lessons.status === "success") {
      setUnassignedLessons(unassignedLessons => ({
        ...unassignedLessons,
        lessons: _.difference(
          Object.keys(lessons.data),
          _.uniq(courses.data.map(course => course.lessons.map(lesson => lesson.id)).flat())
        ).map(id => ({ id, quantity: 1 }))
      }));
    }
  }, [courses, lessons]);

  const handleManageButtonClick = useCallback(() => {
    setIsManaging(prev => {
      if (prev && !_.isEmpty(deletionList)) {
        setPromptType(PromptType.DELETION);
        deleteLessonsQuery.reset();
        return prev;
      } else {
        return !prev;
      }
    });
  }, [deleteLessonsQuery, deletionList]);

  const handleCancelButtonClick = useCallback(() => {
    setIsManaging(false);
    setDeletionList({});
  }, [setDeletionList]);

  const toggleStageDeletion = useCallback((courseIdx: number, lessonIdx?: number) => {
    setDeletionList(draft => {
      if (lessonIdx === undefined) {
        if (draft[courseIdx] && draft[courseIdx] === true) {
          delete draft[courseIdx];
        } else {
          draft[courseIdx] = true;
        }
      } else if (draft[courseIdx]) {
        let val: typeof draft[typeof courseIdx] | undefined = draft[courseIdx];

        if (val === true) {
          val = _.range(0, (courseIdx === -1 ? unassignedLessons.lessons : courses.data[courseIdx].lessons).length);
          val.splice(lessonIdx, 1);
        } else {
          const i = val.indexOf(lessonIdx);
  
          if (i === -1) {
            val.push(lessonIdx);
          } else {
            val.splice(i, 1);
  
            if (!val.length) {
              val = undefined;
            }
          }
        }

        val === undefined ? delete draft[courseIdx] : draft[courseIdx] = val;
      } else {
        draft[courseIdx] =
          (courseIdx === -1 ? unassignedLessons.lessons : courses.data[courseIdx].lessons).length === 1
            ? true
            : [lessonIdx];
      }
    });
  }, [courses.data, setDeletionList, unassignedLessons.lessons]);

  const handlePromptResolve = useCallback(async (value: boolean) => {
    if (promptType === PromptType.DELETION && value) {
      await deleteLessonsQuery.mutateAsync();
    } else {
      setPromptType(null);
    }

    setDeleteUnassigned(false);
  }, [deleteLessonsQuery, promptType]);

  return (
    <>
      {promptType && (
        <Prompt
          onClickOutside={() => {
            if (promptType !== PromptType.IMPORT) {
              handlePromptResolve(false);
            }
          }}
        >
          {promptType === PromptType.DELETION ? (
            <>
              <h2>
                Are you sure you want to remove{" "}
                {_.compact([
                  stagedDeletionCount.courses &&
                    `${stagedDeletionCount.courses} ${pluralize("course", stagedDeletionCount.courses)}`,
                  stagedDeletionCount.coursesPartial &&
                    `${stagedDeletionCount.lessons} ${pluralize("lesson", stagedDeletionCount.lessons)} from ${
                      stagedDeletionCount.coursesPartial
                    } ${pluralize("course", stagedDeletionCount.coursesPartial)}`
                ]).join(" and ")}
                ?
              </h2>
              {deleteLessonsQuery.status === "loading" ? (
                <div>Processing...</div>
              ) : (
                <>
                  <div>
                    <input
                      type="checkbox"
                      onChange={e => setDeleteUnassigned(e.target.checked)}
                      checked={deleteUnassigned}
                    />
                    <label>
                      Also <b>permanently delete</b> any lessons unassigned to any courses
                    </label>
                  </div>
                  <div>
                    <button onClick={() => handlePromptResolve(true)}>Yes</button>
                    <button onClick={() => handlePromptResolve(false)}>No</button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div>{importCourseProgress.status}</div>
              {importCourseProgress.done && <button onClick={() => handlePromptResolve(false)}>Okay</button>}
            </>
          )}
        </Prompt>
      )}
      <div id={styles.main}>
        <div id={styles["available-lessons"]}>
          <div>
            <h1>Available courses</h1>
            {signInCheckResult?.hasRequiredClaims && (
              <div>
                {!_.isEmpty(deletionList) && <button onClick={handleCancelButtonClick}>Cancel</button>}
                <button onClick={handleManageButtonClick}>{isManaging ? "Done" : "Manage courses"}</button>
                <CSSTransition
                  in={isManaging}
                  timeout={250}
                  classNames={{
                    enter: styles["add-lesson-enter"],
                    enterActive: styles["add-lesson-enter-active"],
                    enterDone: styles["add-lesson-enter-done"],
                    exit: styles["add-lesson-exit"],
                    exitActive: styles["add-lesson-exit-active"],
                    exitDone: styles["add-lesson-exit-done"]
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <Link to="/editor/create">
                      <BiPlus />
                    </Link>
                    <label htmlFor="upload" title="Import">
                      <BiUpload />
                    </label>
                    <input
                      id="upload"
                      type="file"
                      accept=".zip"
                      onChange={async e => {
                        if (e.target.files) {
                          setPromptType(PromptType.IMPORT);
                          await importCourse(db, storage, e.target.files, (progress, status, done) =>
                            setImportCourseProgress({ progress, status, done })
                          );
                          e.target.value = "";
                        }
                      }}
                      hidden
                    />
                  </div>
                </CSSTransition>
              </div>
            )}
          </div>
          {courses.status === "success" && courses.data ? (
            courses.data.length + unassignedLessons.lessons.length ? (
              <>
                {courses.data.concat(unassignedLessons).map((course, idx) => {
                  if (idx === courses.data.length) {
                    idx = -1;
                  }

                  const deletionStaged = deletionList[idx] === true;
                  const completedPercentage =
                    !userData?.progress?.[course.id] || signInCheckResult.hasRequiredClaims
                      ? undefined
                      : (_.sum(Object.values(userData.progress[course.id]!)) / _.sumBy(course.lessons, "quantity")) *
                        100;

                  return idx === -1 && !course.lessons.length ? null : (
                    <CardListItem
                      key={course.id}
                      style={
                        completedPercentage === undefined
                          ? undefined
                          : {
                              background:
                                `linear-gradient(90deg, #b6ccfd 0%, #b6ccfd ${completedPercentage}%, ` +
                                `white ${completedPercentage}%, white 100%)`
                            }
                      }
                      onClick={() => setSelectedCourseIdx(idx)}
                      aria-selected={selectedCourseIdx === idx}
                      indicatorType={isManaging ? "button" : "normal"}
                      buttonProps={{ children: deletionStaged ? "⟲" : "✖", onClick: () => toggleStageDeletion(idx) }}
                      aria-disabled={deletionStaged}
                    >
                      {`${course.title}${(completedPercentage ?? 0) >= 100 ? " ✅" : ""}`}
                    </CardListItem>
                  );
                })}
              </>
            ) : (
              <div>
                No courses available.
                {signInCheckResult.hasRequiredClaims ? " Create a new course with the 'Manage Course' button." : ""}
              </div>
            )
          ) : courses.status === "error" ? (
            <p className="error">An error occurred. Please try again.</p>
          ) : (
            Array(6)
              .fill(0)
              .map((_, idx) => <CardListItem key={idx} />)
          )}
        </div>
        <div id={styles.preview}>
          {selectedCourse ? (
            <>
              {signInCheckResult?.hasRequiredClaims && (
                <button
                  className="no-style"
                  title="Export"
                  onClick={() => exportCourse(db, storage, selectedCourse.id)}
                >
                  <BiDownload />
                </button>
              )}
              <div>
                <h2>{selectedCourse.title}</h2>
                <p>{selectedCourse.description.length ? selectedCourse.description : "No description provided."}</p>
              </div>
              <div id={styles["lesson-list"]}>
                {lessons.status === "success" && deleteLessonsQuery.status !== "loading" ? (
                  selectedCourse.lessons.map((lesson, idx) => {
                    const searchTarget = deletionList[selectedCourseIdx];
                    const deletionStaged =
                      (Array.isArray(searchTarget) && searchTarget.includes(idx)) || searchTarget === true;
                    const completedPercentage = signInCheckResult.hasRequiredClaims
                      ? undefined
                      : ((userData?.progress?.[selectedCourse.id]?.[selectedCourse.lessons[idx].id] ?? 0) /
                          selectedCourse.lessons[idx].quantity) *
                        100;

                    return (
                      <CardListItem
                        key={idx}
                        style={
                          completedPercentage === undefined
                            ? undefined
                            : {
                                background:
                                  `linear-gradient(90deg, #b6ccfd 0%, #b6ccfd ${completedPercentage}%,` +
                                  ` white ${completedPercentage}%, white 100%)`
                              }
                        }
                        indicatorType={isManaging ? "button" : "normal"}
                        buttonProps={{
                          children: deletionStaged ? "⟲" : "✖",
                          onClick: () => toggleStageDeletion(selectedCourseIdx, idx)
                        }}
                        aria-disabled={deletionStaged}
                        disableHoverEffect
                      >
                        {`${lessons.data[lesson.id]?.title} ${getQuantityText(
                          signInCheckResult.hasRequiredClaims,
                          selectedCourse.lessons[idx].quantity,
                          userData?.progress?.[selectedCourse.id]?.[selectedCourse.lessons[idx].id]
                        )}`}
                      </CardListItem>
                    );
                  })
                ) : lessons.status === "loading" ? (
                  Array(6)
                    .fill(0)
                    .map((_, idx) => <CardListItem key={idx} />)
                ) : (
                  <div className="error">An error occurred.</div>
                )}
              </div>
              <Link className="no-style" to={`/lesson/${selectedCourse.id}`}>
                <button disabled={selectedCourseIdx === -1}>Begin lesson</button>
              </Link>
            </>
          ) : (
            <div>Select a course on the left</div>
          )}
        </div>
      </div>
    </>
  );
}
