import { useEffect, useState } from "react";
import { CircularGuage } from "../../components/CircularGuage";
import { LessonCompletedIcon } from "../../components/icons";
import styles from "./StatusView.module.scss";

export enum LessonStatus {
  IDLE,
  COUNTDOWN,
  TRANSITION,
  STAGGERED,
  ENDED
}

interface StatusViewProps {
  status: LessonStatus;
  transitionText: React.ReactNode;
  score: number;
  onStartClicked: () => void;
  onCountdownCompleted: () => void;
  onRestartClicked: () => void;
}

export default function StatusView(props: StatusViewProps) {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (props.status === LessonStatus.COUNTDOWN) {
      setCountdown(5);

      const interval = setInterval(() => {
        setCountdown(prev => {
          const newVal = prev - 1;

          if (newVal === 0) {
            clearInterval(interval);
          }

          return newVal;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status, props.onCountdownCompleted]);

  useEffect(() => {
    if (countdown === 0) {
      props.onCountdownCompleted();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  return (
    <div id={styles.main}>
      {props.status === LessonStatus.IDLE ? (
        <button onClick={props.onStartClicked}>Start</button>
      ) : props.status === LessonStatus.COUNTDOWN ? (
        <h2>Ready<br/>{countdown}</h2>
      ) : props.status === LessonStatus.TRANSITION ? (
        props.transitionText
      ) : props.status === LessonStatus.STAGGERED ? (
        <>
          <div id={styles.guage}>Score:</div>
          <CircularGuage value={props.score} width="calc(100% - 64px)">
            {Math.round(props.score * 100)}%
          </CircularGuage>
        </>
      ) : (
      <>
        <LessonCompletedIcon id={styles["completed-icon"]}/>
        <button onClick={props.onRestartClicked}>Restart</button>
      </>
      )}
    </div>
  );
}