import { useEffect } from "react";

import IndeterminateCheckbox from "./IndeterminateCheckbox";

import classNames from "classnames";
import type { Updater } from "use-immer";
import type { Booleanish, KeyofType } from "../utils/utils";
import styles from "../scss/SelectionGrid.module.scss";

interface AssignmentGridProps<T> {
  data: T[];
  keyField: KeyofType<T, string>;
  textField: KeyofType<T, string>;
  originalAssignment: Map<string, Booleanish>;
  newAssignment: Map<string, boolean>;
  setNewAssignment: Updater<Map<string, boolean>>;
  column?: number;
  className?: string;
}

export default function AssignmentGrid<T extends Record<string, any>>(props: AssignmentGridProps<T>) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.setNewAssignment(draft => draft.clear()), [props.setNewAssignment, props.data]);

  return (
    <div
      className={classNames(styles.main, props.className)}
      style={{ gridTemplateColumns: `repeat(${props.column ?? Math.min(3, props.data.length)}, minmax(0, 1fr))` }}
    >
      {props.data.map(value => {
        const key = value[props.keyField];
        const original = props.originalAssignment.get(key);
        const updated = props.newAssignment.get(key);

        return (
          <button
            key={key}
            className="no-style"
            onClick={() => props.setNewAssignment(draft => {
              updated === undefined
                ? draft.set(key, !original)
                : original === "?"
                ? draft.set(key, !updated)
                : draft.delete(key);
            })}
          >
            <span>{value[props.textField]}</span>
            <IndeterminateCheckbox
              checked={updated ?? original === true}
              indeterminate={updated === undefined && original === "?"}
              readOnly
            />
          </button>
        );
      })}
    </div>
  );
}