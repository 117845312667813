import React, { useCallback, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import useDetector from "../../hooks/UseDetector";
import { EditableLessonData } from "./EditableData";
import { preventAndStop } from "../../utils/utils";
import styles from "./FileProcessing.module.scss";

function inputBuilder(input: string, type: string) {
  return input.split(" ").map(s => `${type}/${s}`).join(", ");
}

const ACCEPTED_INPUT = Object.freeze(
  [
    inputBuilder("mp4 mpeg mov m4v webm", "video")
    // inputBuilder("png jpg jpeg webp", "image")
  ].join(", ")
);

const ACCEPTED_INPUT_DISPLAY = Object.freeze(ACCEPTED_INPUT.split(", ").map(s => `.${s.split("/")[1]}`).join(", "));

export const ACCEPTED_INPUT_ARRAY = Object.freeze(ACCEPTED_INPUT.split(", "));

interface FileProcessingProps {
  onFilesProcessed: (lessons: EditableLessonData[], failedFiles?: string[]) => void;
  onUseExistingLessons: () => void;
}

export const FileProcessing: React.FC<FileProcessingProps> = props => {
  const detector = useDetector();
  const dragCounter = useRef(0);
  const [draggedOver, setDraggedOver] = useState(false);

  const [fileProcessingProgress, setFileProcessProgress] = useState<{ value: number, status: string }>({
    value: -1,
    status: ""
  });
  const [invalidFileCount, setInvalidFileCount] = useState(0);

  const handleFileProcessing = useCallback(async (upload: FileList | null) => {
    if (upload) {
      const files = Array.from(upload);
      const validFiles = files.filter(f => ACCEPTED_INPUT_ARRAY.includes(f.type));
      const failedFiles = Array<string>();

      if (validFiles.length) {
        const lessons = Array<EditableLessonData>(), video = document.createElement("video");

        for (let i = 0; i < validFiles.length; i++) {
          const file = validFiles[i];

          try {
            setFileProcessProgress({ value: i / validFiles.length, status: `Processing ${file.name}...` });
            const lesson = new EditableLessonData(file);
            await lesson.generatePoseData(video, detector.detector!, undefined, undefined, progress =>
              setFileProcessProgress({
                value: i / validFiles.length + progress * (1 / validFiles.length),
                status: `(${i + 1}/${validFiles.length}) Processing ${file.name}: ${Math.round(progress * 100)}%`
              })
            );
            lessons.push(lesson);
          } catch (err) {
            console.warn(err);
            failedFiles.push(file.name);
          }
        }

        video.remove();

        if (lessons.length) {
          setInvalidFileCount(files.length - lessons.length);
          props.onFilesProcessed(lessons, failedFiles.length ? failedFiles : undefined);
        } else {
          setInvalidFileCount(files.length);
          setFileProcessProgress({ value: -1, status: "No valid files found" });
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detector.detector, detector.status, props.onFilesProcessed]);

  return fileProcessingProgress.value >= 0 ? (
    <div id={styles.loading}>
      <label htmlFor="file">Your files are being processed!</label>
      <progress id="file" value={fileProcessingProgress.value} />
      <span className={fileProcessingProgress.status.includes("error") ? "error" : ""}>
        {fileProcessingProgress.status}
      </span>
    </div>
  ) : (
    <CSSTransition
      in={!!invalidFileCount}
      classNames={{ enterActive: styles["invalid-enter-active"] }}
      timeout={{ enter: 500, exit: 0 }}
    >
      <form
        id={styles.main}
        onDragOver={preventAndStop}
        onDragEnter={e => {
          preventAndStop(e);
          dragCounter.current++;

          if (e.dataTransfer.items.length) {
            setDraggedOver(true);
          }
        }}
        onDragLeave={e => {
          preventAndStop(e);
          if (!--dragCounter.current) {
            setDraggedOver(false);
          }
        }}
        onDrop={async e => {
          preventAndStop(e);
          dragCounter.current = 0;
          setDraggedOver(false);
          handleFileProcessing(e.dataTransfer.files);
        }}
      >
        {detector.status === "loaded" ? (
          <>
            {draggedOver ? (
              <div id={styles["drag-over"]}>Drop file(s) here</div>
            ) : (
              <>
                <label htmlFor="upload">
                  <u>
                    <strong>Choose your file(s)</strong>
                  </u>{" "}
                  or drag them here.
                </label>
                <span style={{ fontWeight: invalidFileCount ? "bold" : undefined }}>
                  Accepts: {ACCEPTED_INPUT_DISPLAY}
                </span>
                <br/>
                <span>or</span>
                <br/>
                <span onClick={props.onUseExistingLessons}><u><strong>Use existing lessons</strong></u></span>
              </>
            )}
            <input
              id="upload"
              type="file"
              accept={ACCEPTED_INPUT}
              onChange={e => {
                handleFileProcessing(e.target.files);
                e.target.value = "";
              }}
              multiple
            />
          </>
        ) : (
          <div>Loading...</div>
        )}
      </form>
    </CSSTransition>
  );
};

export default FileProcessing;
