import classNames from "classnames";
import { assertType } from "../utils/utils";
import styles from "../scss/ButtonOptions.module.scss";

interface ButtonOptionsStringArrProps<T extends string[] = string[]> {
  options: T,
  value: T[number],
  onChange: (value: T[number]) => void,
}

interface ButtonOptionsAliasedProps<
  T extends [string, string | number | symbol][] = [string, string | number | symbol][]
> {
  options: T;
  value: T[number][1];
  onChange: (value: T[number][1]) => void;
}

export type ButtonOptionsProps<T extends string[] | [string, string | number | symbol][]> =
  Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> &
    (T extends string[]
      ? ButtonOptionsStringArrProps<T>
      : T extends [string, string | number | symbol][]
      ? ButtonOptionsAliasedProps<T>
      : never);

export function ButtonOptions<T extends string[]>(
  props: Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> & ButtonOptionsStringArrProps<T>
): JSX.Element;
export function ButtonOptions<T extends [string, string | number | symbol][]>(
  props: Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> & ButtonOptionsAliasedProps<T>
): JSX.Element;
export function ButtonOptions<T extends string[] | ([string, string | number | symbol])[]>(
  props: ButtonOptionsProps<T>
) {
  const { options, value, onChange, ...rest } = props;
  return (
    <div {...rest} className={classNames(styles.main, rest.className)}>
      {props.options.map(option => 
        Array.isArray(option) && assertType<ButtonOptionsAliasedProps>(props) ? (
          <button
            key={option[0]}
            onClick={() => props.onChange(option[1])}
            role="option"
            aria-selected={props.value === option[1]}
          >
            {option[0]}
          </button>
        ) : !Array.isArray(option) && assertType<ButtonOptionsStringArrProps>(props) ? (
          <button
            key={option}
            onClick={() => props.onChange(option)}
            role="option"
            aria-selected={props.value === option}
          >
            {option}
          </button>
        ) : null
      )}
    </div>
  );
}

export default ButtonOptions;