import { useMemo, useState } from "react";
import { createSearchParams, Link } from "react-router-dom";
import { useImmer } from "use-immer";
import { useAppSelector } from "../../redux/hooks";

import SearchWithFilter, { SearchFilters } from "../../components/SearchWithFilter";
import SelectableTable from "../../components/SelectableTable";

import pluralize from "pluralize";
import styles from "./Manage.module.scss";

export default function ManageLessons() {
  const courses = useAppSelector(state => state.courses);
  const lessons = useAppSelector(state => state.lessons);
  const searchFilters = useMemo(
    () =>
      new SearchFilters<typeof tableData>().add(
        ["in", "course"],
        (lesson, option) => lesson.assignment.includes(option),
        courses.data.map(course => course.title)
      ),
    [courses.data]
  );
  const tableData = useMemo(
    () =>
      Object.values(lessons.data).map(lesson => ({
        id: lesson.id,
        title: lesson.title,
        assignment: courses.data
          .filter(course => course.lessons.some(l => l.id === lesson.id))
          .map(course => course.title)
      })),
    [courses.data, lessons.data]
  );
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [selection, setSelection] = useImmer(new Set<number>());

  return (
    <div id={styles.main}>
      <div id={styles.search}>
        <SearchWithFilter
          data={tableData}
          textSearchKey="title"
          filters={searchFilters}
          onDataChange={data => setFilteredTableData(data)}
        />
        {!!selection.size && (
          <Link
            to={{
              pathname: "/editor/edit",
              search: createSearchParams({
                ids: [...selection.values()].map(idx => filteredTableData[idx].id)
              }).toString()
            }}
          >
            <button>Edit selected {pluralize("lesson", selection.size)}</button>
          </Link>
        )}
      </div>
      {lessons.status === "loading" ? (
        <div>Loading...</div>
      ) : lessons.status === "success" ? (
        <div>
          <SelectableTable
            data={filteredTableData}
            columns={[
              ["Title", row => row.title],
              ["Assignment", row => row.assignment.join(", ")]
            ]}
            dataKeyField="id"
            selection={selection}
            setSelection={setSelection}
          />
        </div>
      ) : (
        <div className="error">An error occured.</div>
      )}
    </div>
  );
}
