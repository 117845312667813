import { QueryDocumentSnapshot } from "firebase/firestore";
import _ from "lodash";

export function assertType<T>(_: any): _ is T {
  return true;
}

export function delay(ms: number) {
  return new Promise<void>(res => setTimeout(res, ms));
}

export async function waitForCondition(predicate: () => boolean) {
  const loop = () => {
    if (predicate()) {
      requestAnimationFrame(loop);
    } else {
      return;
    }
  };

  loop();
}

export function preventAndStop(e: React.BaseSyntheticEvent) {
  e.preventDefault();
  e.stopPropagation();
}

export function hex2rgbArray(hex: string): [number, number, number] {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
  } else {
    throw new Error(`Syntax Error: Invalid hex color. (${hex})`);
  }
}

export function rgbArray2hex(rgb: [number, number, number]) {
  return `#${rgb[0].toString(16).padStart(2, "0")}${rgb[1].toString(16).padStart(2, "0")}${rgb[2].toString(16).padStart(2, "0")}`;
}

/**
 * Takes two hex color and interpolant and returns an interpolated hex color between them.
 */
export function lerpColor(a: string, b: string, interpolant: number) {
  interpolant = Math.clamp(interpolant);
  const colorA = hex2rgbArray(a);
  const colorB = hex2rgbArray(b);
  return rgbArray2hex(colorA.map((color, idx) =>
    Math.round(Math.mapRange(interpolant, 0, 1, color, colorB[idx]))
  ) as [number, number, number]);
}

export function constructMapFromDocs<T>(docs: QueryDocumentSnapshot<T>[]) {
  return new Map(docs.map(doc => [doc.id, doc.data()]));
}

/** This function returns a new object instead of mutating the original. */
export function removeEmptyObjects<T extends Record<string, any>>(obj: T): DeepPartial<T> {
  return _(obj)
    .pickBy(_.isObject)
    .mapValues(removeEmptyObjects)
    .omitBy(_.isEmpty)
    .assign(_.omitBy(obj, _.isObject))
    .value();
}

export function getQuantityText(isAdmin: boolean, quantity: number, progress = 0) {
  return isAdmin ? `(x${quantity})` : progress < quantity ? `(${progress}/${quantity})` : "✅";
}

export const Tuple = <T extends readonly [unknown, ...unknown[]]>(v: T) => v as DeepWriteable<T>;

export type Booleanish = boolean | "?";

/**
 * Get keys from T that have types assignable to U
 * @example
 * type A = { a: number, b: boolean, c: string }
 * type B = KeyofType<A, number> // "a"
 * type C = KeyofType<A, number | boolean> // "a" | "b"
 */
export type KeyofType<T, U> = { [K in keyof T]: T[K] extends U ? K : never }[keyof T]

/**
 * Get entries from T that have types assignable to U
 * @example
 * type A = { a: number, b: boolean, c: string, d: number }
 * type B = PickByType<A, number> // { a: number, d: number }
 * type C = PickByType<A, number | boolean> // { a: number, b: boolean, d: number }
 */
export type PickByType<T, U> = Pick<T, KeyofType<T, U>>

/**
 * Create a new type that has everything from T except entries that are assignable to U
 * @example
 * type A = { a: number, b: string };
 * type B = ExcludeByEntries<A, { b: string }> // { a: number }
 * type C = ExcludeByEntries<A, { b: number }> // { a: number, b: string }
 */
export type ExcludeByEntries<T, U> = { [K in keyof T]: K extends keyof U ? Exclude<T[K], U[K]> : T[K] } extends infer R ?
  Omit<R, KeyofType<R, never>> : never;

/**
 * Create a new type that has everything from both T and U, but keys of T that are present in keys of U have their
 * types replaced with the types of U.
 */
export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type JoinUnions<T, U> = { [K in keyof T]: K extends keyof U ? T[K] | U[K] : T[K] | undefined; }
  & { [K in keyof U]: K extends keyof T ? U[K] | T[K] : U[K] | undefined; };

/**
 * Make all properties in T nullable
 */
export type Nullable<T> = { [K in keyof T]: T[K] | null };

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer I>
    ? Array<DeepPartial<I>>
    : DeepPartial<T[P]>
};

export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export type DeepReadonly<T> = { readonly [P in keyof T]: DeepReadonly<T[P]> };