import React, { useCallback, useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { immerable } from "immer";

import _ from "lodash";
import escapeStringRegexp from "escape-string-regexp";
import type { DeepReadonly, KeyofType } from "../utils/utils";
import styles from "../scss/SearchWithFilter.module.scss";

function getTextCursorPosition(parent: Node, selection: Selection) {
  const loop = (parent: Node, selection: Selection, traversed: number, done: boolean): { traversed: number, done: boolean } => {
    if (parent.childNodes.length) {
      for (let i = 0; i < parent.childNodes.length; i++) {
        const currentNode = parent.childNodes[i];

        if (currentNode.nodeName === "#comment") {
          continue;
        }

        if (selection.focusNode === currentNode) {
          return { traversed: traversed + selection.focusOffset, done: true };
        } else {
          const result = loop(currentNode, selection, traversed, done);

          if (result.done) {
            return result;
          } else {
            traversed = result.traversed;
          }
        }
      }

      return { traversed, done: false };
    } else {
      return parent === selection.focusNode
        ? { traversed: traversed + selection.focusOffset, done: true }
        : { traversed: traversed + (parent.textContent?.length ?? 0), done: false };
    }
  };

  return loop(parent, selection, 0, false).traversed;
}

function setTextCursorPosition(parent: Node, selection: Selection, offset: number, traversed = 0): true | number {
  if (parent.childNodes.length) {
    for (let i = 0; i < parent.childNodes.length; i++) {
      const currentNode = parent.childNodes[i];

      if (currentNode.nodeName === "#comment") {
        continue;
      }

      const result = setTextCursorPosition(currentNode, selection, offset, traversed);

      if (result === true) {
        return result;
      } else {
        traversed = result;
      }
    }

    return traversed;
  } else {
    const textLength = parent.textContent?.length ?? 0;

    if (traversed + textLength >= offset) {
      selection.collapse(parent, offset - traversed);
      return true;
    } else {
      return traversed + textLength;
    }
  }
}

export class SearchFilters<T extends any[]> {
  [immerable] = true;

  private _filters = new Map<
    string,
    {
      fn: (data: T[number], query: string) => boolean;
      descriptor?: string;
      option?: {
        /** Key is the value displayed, whereas value is the value passed to filter functions. */
        values: Map<string, string>;
        descriptors: Map<string, string>
      };
    }
  >();

  get filters() {
    return this._filters;
  }

  add<O extends DeepReadonly<(string | [value: string, optional: { label?: string; descriptor?: string }])[]>>(
    name: string | [string, string],
    fn: (
      data: T[number],
      query: { [K in keyof O]: O[K] extends string ? O[K] : O[K][0] }[number]
    ) => boolean,
    options: O
  ): SearchFilters<T>;
  add(name: string | [string, string], fn: (data: T[number], query: string) => boolean): SearchFilters<T>;
  add<O extends DeepReadonly<(string | [value: string, optional: { label?: string; descriptor?: string }])[]>>(
    name: string | [string, string],
    fn: (
      data: T[number],
      query: { [K in keyof O]: O[K] extends string ? O[K] : O[K][0] }[number]
    ) => boolean,
    options?: O
  ) {
    const filter = typeof name === "string" ? { name } : { name: name[0], descriptor: name[1] };
    this._filters.set(filter.name, {
      fn,
      descriptor: filter.descriptor,
      option: options?.reduce(
        (acc, curr) => {
          if (typeof curr === "string") {
            acc.values.set(curr, curr);
          } else {
            acc.values.set(curr[1].label ?? curr[0], curr[0]);
            curr[1].descriptor && acc.descriptors.set(curr[0], curr[1].descriptor);
          }

          return acc;
        },
        { values: new Map<string, string>(), descriptors: new Map<string, string>() }
      )
    });
    return this;
  }
}

export interface SearchWithFilterProps<T> {
  data: T[];
  textSearchKey: KeyofType<T, string>;
  filters: SearchFilters<T[]>;
  onDataChange?: (data: T[]) => void;
}

enum TermType {
  BASIC = 1,
  FILTER_NAME,
  FILTER_QUERY
}

export default function SearchWithFilter<T>(props: SearchWithFilterProps<T>) {
  const searchRef = useRef<HTMLDivElement>(null);
  const [rawText, setRawText] = useState("");
  const [terms, setTerms] = useState(Array<{ type: TermType; value: string, idx: number }>());
  const [baseSearches, setBaseSearches] = useState(Array<string>());
  const [activeFilters, setActiveFilters] = useState(Array<{ name: string; query: string }>());

  const suggestionsRef = useRef<HTMLDivElement>(null);
  const [suggestions, setSuggestions] = useState(Array<{ name: string, descriptor?: string }>());
  const [suggestionsEnabled, setSuggestionsEnabled] = useState(false);
  const [suggestionSelected, setSuggestionSelected] = useState(-1);
  const suggestionsApplied = useRef(false);

  useEffect(
    () =>
      props.onDataChange?.(
        props.data.filter(
          d =>
            activeFilters.every(f => props.filters.filters.get(f.name)?.fn(d, f.query)) &&
            baseSearches.every(s =>
              (d[props.textSearchKey] as unknown as string).toLowerCase().includes(s.toLowerCase())
            )
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFilters, baseSearches]
  );

  useEffect(() => {
    const caretPos = getTextCursorPosition(searchRef.current!, window.getSelection()!);
    let charsParsed = 0;
    let charsAdded = 0;
    const nodes = Array<React.ReactNode>();
    const baseSearches = Array<string>();
    const filters: typeof activeFilters = [];
    const terms = Array<{ type: TermType; value: string, idx: number }>();
    const matches = [...props.filters.filters.keys()]
      .map(name => ({
        name,
        idx: [...rawText.matchAll(new RegExp(`${escapeStringRegexp(name)}:`, "g"))].map(match => match.index!)
      }))
      .filter(({ idx }) => idx.length)
      .reduce(
        (acc, { name, idx }) => acc.concat(idx.map(i => ({ name, idx: i }))),
        Array<{ name: string; idx: number }>()
      )
      .sort((a, b) => a.idx - b.idx);

    const addWhitespaces = (whitespaces: string) => {
      nodes.push(whitespaces);
      charsAdded += whitespaces.length;
    };

    const addBasicText = (text: string) => {
      if (text.length) {
        baseSearches.push(text.trim());
        nodes.push(text);
        terms.push({ type: TermType.BASIC, value: text.trim(), idx: charsAdded });
        charsAdded += text.length;
      }
    };

    const addFilter = (name: string, query?: string, value?: string) => {
      nodes.push(<span key={nodes.length} className={styles["filter-name"]}>{name}:</span>);
      terms.push({ type: TermType.FILTER_NAME, value: name, idx: charsAdded });
      charsAdded += name.length + 1;

      if (query) {
        const startWhitespaces = query.match(/^\s+/g)?.[0], endWhitespaces = query.match(/\s+$/g)?.[0];

        if (startWhitespaces) {
          addWhitespaces(startWhitespaces);
        }

        nodes.push(<span key={nodes.length + 1} className={styles["filter-query"]}>{query.trim()}</span>);
        filters.push({ name, query: value ?? query.trim() });
        terms.push({ type: TermType.FILTER_QUERY, value: query.trim(), idx: charsAdded });
        charsAdded += query.trim().length;

        if (endWhitespaces) {
          addWhitespaces(endWhitespaces);
        }
      }
    };

    for (let i = 0; i < matches.length; i++) {
      const match = matches[i];

      // Catch cases where filter names overlap at the end, e.g. "is-x" and "not-x" (both ending with "-x")
      if (match.idx < charsParsed) {
        continue;
      }

      const nextMatch = matches.at(i + 1);
      const baseSearch = rawText.substring(charsParsed, match.idx);
      const filter = props.filters.filters.get(match.name)!;
      
      if (baseSearch.length) {
        addBasicText(baseSearch);
      }

      const filterQuery = rawText.substring(match.idx + match.name.length + 1, nextMatch?.idx);
      charsParsed = match.idx + match.name.length + 1 + filterQuery.length;

      if (filter.option && filterQuery.length) {
        const pickedOption = _.maxBy(
          [...filter.option.values.keys()].filter(option => filterQuery.trimStart().startsWith(option)),
          "length"
        );

        const pickedOptionWithWhitespace =
          pickedOption && filterQuery.match(new RegExp(`^\\s*${escapeStringRegexp(pickedOption)}\\s*`, "g"))?.[0];

        addFilter(match.name, pickedOptionWithWhitespace, filter.option.values.get(pickedOption ?? ""));
        addBasicText(
          pickedOptionWithWhitespace ? filterQuery.substring(pickedOptionWithWhitespace.length) : filterQuery
        );
      } else {
        addFilter(match.name, filterQuery);
      }
    }

    if (charsParsed < rawText.length) {
      addBasicText(rawText.substring(charsParsed));
    }

    terms.push({ type: TermType.BASIC, value: "", idx: charsAdded });
    setTerms(terms);
    setBaseSearches(baseSearches);
    setActiveFilters(filters);
    searchRef.current!.innerHTML = renderToString(<>{nodes}</>);
    setTextCursorPosition(searchRef.current!, document.getSelection()!, caretPos);

    const previousTerm = terms.at(-2), currentTerm = terms.at(-1);

    const getOptionSuggestions = (filterName: string) => {
      const option = props.filters.filters.get(filterName)?.option;
      return option
        ? [...option.values.keys()].map(name => ({
            name,
            descriptor: option.descriptors.get(name)
          }))
        : [];
    };

    setSuggestions(() => {
      switch (currentTerm?.type) {
        case undefined:
          return [...props.filters.filters.entries()].map(([name, filter]) => ({
            name: `${name}:`,
            descriptor: filter.descriptor
          }));

        case TermType.BASIC:
          switch (previousTerm?.type) {
            case undefined:
            case TermType.FILTER_QUERY:
              return [...props.filters.filters.entries()]
                .filter(([name]) => name.includes(currentTerm.value))
                .map(([name, filter]) => ({ name: `${name}:`, descriptor: filter.descriptor }));

            case TermType.FILTER_NAME:
              return getOptionSuggestions(previousTerm.value);

            default:
              return [];
          }

        case TermType.FILTER_NAME:
          return getOptionSuggestions(currentTerm.value);

        case TermType.FILTER_QUERY:
          return [];
      }
    });
  }, [props.filters.filters, rawText]);

  useEffect(() => {
    if (suggestionsApplied.current) {
      setTextCursorPosition(searchRef.current!, document.getSelection()!, rawText.length);
      suggestionsApplied.current = false;
    }
  }, [rawText]);

  const applySuggestion = useCallback(
    (idx: number) => {
      if (idx > -1) {
        suggestionsApplied.current = true;
        const lastTerm = terms.at(-1);
        setRawText(draft =>
          (lastTerm
            ? draft.slice(0, lastTerm.idx) + suggestions[idx].name
            : suggestions[idx].name)
        );
      }
    },
    [suggestions, terms]
  );

  return (
    <div className={styles.main}>
      <div
        ref={searchRef}
        className="no-style"
        onKeyDown={e => {
          switch (e.key) {
            case "Backspace": {
              const selection = document.getSelection()!;
              const caretFocus = selection.focusNode?.parentNode as HTMLElement;
              if (selection.isCollapsed && caretFocus?.className === styles["filter-name"]) {
                e.preventDefault();
                const caretPos = getTextCursorPosition(e.currentTarget, selection!) - caretFocus.textContent!.length;
                caretFocus.remove();
                setTextCursorPosition(e.currentTarget, selection, caretPos);
                setRawText(
                  rawText.slice(0, caretPos) +
                    rawText.slice(caretPos + caretFocus.textContent!.length)
                );
              }
              break;
            }

            case "Enter":
              e.preventDefault();
              applySuggestion(suggestionSelected);
              break;

            case "ArrowUp":
              e.preventDefault();
              setSuggestionSelected(suggestionSelected - 1 < 0 ? suggestions.length - 1 : suggestionSelected - 1);
              break;

            case "ArrowDown":
              e.preventDefault();
              setSuggestionSelected(suggestionSelected + 1 >= suggestions.length ? 0 : suggestionSelected + 1);
              break;
          }
        }}
        onInput={e => setRawText(e.currentTarget.textContent ?? "")}
        onFocus={() => setSuggestionsEnabled(true)}
        onBlur={e => {
          if (!suggestionsRef.current?.contains(e.relatedTarget)) {
            setSuggestionsEnabled(false);
          }
        }}
        data-placeholder="Search"
        contentEditable
        suppressContentEditableWarning
      />
      {suggestionsEnabled && (
        <div ref={suggestionsRef} className={styles.suggestion}>
          {suggestions.map(({ name, descriptor }, idx) => (
            <button
              key={idx}
              className="no-style"
              onClick={() => applySuggestion(idx)}
              role="option"
              aria-selected={idx === suggestionSelected}
            >
              {name}
              {!!descriptor && <span>{descriptor}</span>}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}