import { createSlice } from "@reduxjs/toolkit";

export type GlobalAppState = {
  hideHeader: boolean;
}

const initialState: GlobalAppState = {
  hideHeader: false
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    enableHeader: state => {
      state.hideHeader = false;
    },
    disableHeader: state => {
      state.hideHeader = true;
    },
    reset: () => initialState
  }
});

export const { enableHeader, disableHeader } = appSlice.actions;

export const appReducer = appSlice.reducer;